import React, { useState, useEffect, useRef } from 'react';
import { Dialog } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { LuLoader2 } from 'react-icons/lu';

const CustomPaymentModal = ({ item, open, onClose, onSubmit, loadingLink }) => {
  const [customAmount, setCustomAmount] = useState(0);
  const [minAmount, setMinAmount] = useState(0);
  const [maxAmount, setMaxAmount] = useState(0);
  const [realAmount, setRealAmount] = useState(0);
  const [isWithinLimit, setIsWithinLimit] = useState(false);
  const [isFirstPayment, setIsFirstPayment] = useState(false);
  const [timeRemaining, setTimeRemaining] = useState(null);

  const { t } = useTranslation("global");

  useEffect(() => {
    if (item) {
      const payments = JSON.parse(item.payments || '[]');
      const totalPaid = payments.reduce((sum, p) => sum + (p.price || 0), 0);
      const firstPayment = payments.length > 0 ? new Date(payments[0].created_at) : null;
      
      setIsFirstPayment(payments.length > 0 ? false : true);

      const createdAt = new Date(item.created_at);
      const isWithin24Hours = firstPayment && (firstPayment - createdAt) <= 24 * 60 * 60 * 1000;
      const expirationTime = createdAt.getTime() + 24 * 60 * 60 * 1000;

      const now = new Date().getTime();
      const distance = expirationTime - now;

      if (distance < 0) {
        setTimeRemaining(0);
      }  else {
        const hours = Math.floor((distance % (24 * 60 * 60 * 1000)) / (60 * 60 * 1000));
        const minutes = Math.floor((distance % (60 * 60 * 1000)) / (60 * 1000));
        const seconds = Math.floor((distance % (60 * 1000)) / 1000);
        setTimeRemaining(`${hours}h ${minutes}m ${seconds}s`);
      }

      setIsWithinLimit(true);

      const remaining = isWithin24Hours ? item.amount_pay - totalPaid : item.total_pay - totalPaid;
      const flights = item.flights ? JSON.parse(item.flights) : [];
      const hotels = item.hotels ? JSON.parse(item.hotels) : [];
      const minPay = payments.length > 0 ? 1 : 2000000 + (flights[0]?.price || 0) + (hotels[0]?.price || 0);

      setMinAmount(minPay);
      setMaxAmount(remaining);
      setCustomAmount(minPay);
      setRealAmount(item.total_pay);
    }
  }, [item]);
  

  const handleChange = (e) => {
    const value = e.target.value;

    if (value === '') {
      setCustomAmount('');  // Permite vacío mientras se edita
      return;
    }

    const numValue = Number(value);

    if (numValue > maxAmount) {
      setCustomAmount(maxAmount);
    } else {
      setCustomAmount(numValue);
    }
  };

  const handleBlur = (e) => {
    const value = Number(e.target.value);

    if (isNaN(value) || value < minAmount) {
      setCustomAmount(minAmount);  // Si está vacío o es inválido, se setea al mínimo al salir del input
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    onSubmit(item, customAmount);
  };

  const formatPriceCOP = (price) => {
    return `${new Intl.NumberFormat("es-CO", {
      style: "currency",
      currency: "COP",
      minimumFractionDigits: 0,
      maximumFractionDigits: 0,
    }).format(price)} COP`;
  };

  return (
    <Dialog open={open} onClose={onClose} PaperProps={{ sx: { width: '100%', maxWidth: '500px', padding: '20px' } }}>
      <div className="p-6">
        <h3 className="text-xl font-bold mb-4">{t("payment_hub.custom_payment_title")}</h3>
        <form onSubmit={handleSubmit}>
          <label className="block text-gray-700 font-bold mb-2">{t("payment_hub.enter_amount")}</label>
          <input className={`w-full border border-gray-300 rounded-lg py-2 px-3 ${customAmount < minAmount ? "border-red-400" : ""}`} 
           type="number" value={customAmount} onChange={handleChange} onBlur={handleBlur} min={minAmount} max={maxAmount} required />
          <p className="text-sm mt-2">
            <h3 className="text-customBlue">
              {t("payment_hub.current_amount")}
            </h3>
            {
              formatPriceCOP(customAmount)
            }
          </p>
          <p>{timeRemaining !== 0 ? t("payment_hub.within_limit") : t("payment_hub.over_limit")}</p>
          <p className="text-sm text-gray-500 mt-2">
            {t("payment_hub.amount_limits")}<br />
            Min: {formatPriceCOP(minAmount)} <br />
            <div>
              {
                isFirstPayment && timeRemaining !== 0 ? (
                  <>
                    Max:
                    <span className="line-through text-red-500">
                      {formatPriceCOP(realAmount)}  {/* Monto original tachado */}
                    </span>
                    <br />
                    <span className="pl-4 text-green-600 font-bold">
                      {formatPriceCOP(item.amount_pay)}  {/* Monto con descuento */}
                    </span>
                  </>
                ) : (timeRemaining !== 0) ? (
                  <>
                    Max:
                    <span className="text-sm text-gray-500 mt-2">
                      {formatPriceCOP(maxAmount)}  {/* Monto con descuento */}
                    </span>
                  </>
                ) : (
                  <>
                    Max:
                    <span className="text-sm text-gray-500 mt-2">
                      {formatPriceCOP(realAmount)}
                    </span>
                  </>
                ) 
              }
            </div>
          </p>

          <div className="flex justify-end">
            <button
              type="submit"
              className="bg-customBlue hover:bg-customHoverBlue text-white px-4 py-2 rounded-lg"
              disabled={loadingLink}
            >
              {
                loadingLink ? (
                  <div className="flex justify-center">
                    <LuLoader2 size={20} className="flex justify-center" />
                  </div>
                ) : (
                  <div>
                    {t("payment_hub.proceed_payment")}
                  </div>
                )
              }
            </button>
          </div>
        </form>
      </div>
    </Dialog>
  );
};

export default CustomPaymentModal;
