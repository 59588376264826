import React, { useEffect, useState } from "react";
import styles from "./styles.module.css";
import { AiOutlineExclamationCircle } from "react-icons/ai";
import { Link } from "react-router-dom";
import Paginado from "@/ui/views/Hotel/HotelResult/Paginado/Paginado";
import { MdSafetyCheck, MdCreditCard, MdPendingActions } from "react-icons/md";
import { useTranslation } from "react-i18next";
import { Dialog } from "@mui/material";
import ModalPaymentCenter from "./ModalPaymentCenter";
import useUserProfile from "@/ui/viewModels/profile/user.profile.viewModel";
import { v4 as uuidv4 } from "uuid";
import axios from "axios";
import { FaCheck, FaCheckCircle } from "react-icons/fa";
import {
  MdCheck,
  MdDelete,
  MdWarning,
  MdBlock
} from "react-icons/md";
import Loading from "@/ui/views/share/components/loading";
import CustomPaymentModal from "./CustomPaymentModal";

const PaymentCenter = () => {
  const [currentPage, setCurrentPage] = useState(1);
  const [currentPageReserves, setCurrentPageReserves] = useState(1);
  const [filteredItems, setFilteredItems] = useState([]);
  const [filteredReserves, setFilteredReserves] = useState([]);
  const [filter, setFilter] = useState(null);
  
  const itemsPerPage = 10;
  const [currentItems, setCurrentItems] = useState([]);
  const [currentReserves, setCurrentReserves] = useState([]);
  const {t, i18n} = useTranslation("global");

  const [openViewItem, setOpenCreateContact] = useState(false);
  const [selectedItem, setSelectedItem] = useState(null);
  const [selectedReserve, setSelectedReserve] = useState(null); // Modal data
  const [limitDate, setLimitDate] = useState(null); // Limit date to pay reserve
  const [limitDates, setLimitDates] = useState({});
  const [isReserveModalOpen, setIsReserveModalOpen] = useState(false); // Modal visibility
  const [isCustomAmountModalOpen, setIsCustomAmountModalOpen] = useState(false);
  const { getUserPaymentsHub, getUserReserves, getPackDataById } = useUserProfile();
  const [loadingLink, setLoadingLink] = useState(false);
  const [customAmount, setCustomAmount] = useState(0);
  const [isFetchingItems, setIsFetchingItems] = useState(false);
  const [isFetchingDates, setIsFetchingDates] = useState(false);
  const [isLoadingMinimumPayment, setIsLoadingMinimumPayment] = useState(false);
  const [isLoadingCustomPayment, setIsLoadingCustomPayment] = useState(false);
  
  useEffect(() => {
    const fetchItems = async () => {
      try {
        setIsFetchingItems(true);

        const items = await getUserPaymentsHub();
        const reserves = await getUserReserves();

        setCurrentItems(items);
        setFilteredItems(items);

        setCurrentReserves(reserves);
        setFilteredReserves(reserves);

        setIsFetchingItems(false);
      } catch (error) {
        console.error("Error fetching items:", error);
      }
    };

    fetchItems();
  }, []);

  useEffect(() => {
    const fetchLimitDates = async () => {
      try {
        setIsFetchingDates(true);

        const dates = {};
        for (const item of filteredReserves) {
          const date = await getLimitDateFromPackage(item.type === "medical" ? item.medical_package_id : item.pack_id, item.type);
          dates[item.id] = date;
        }

        setLimitDates(dates);

        setIsFetchingDates(false)
      } catch (error) {
        console.error("Error fetching limit dates:", error);
      }
    };
    fetchLimitDates();
  }, [filteredReserves]);  

  const handleFilterChange = (status) => {
    setFilter(status);
    if (status) {
      setFilteredItems(currentItems.filter((item) => item.status === status));
      
      setFilteredReserves(currentReserves.filter((item) => {
        if (status === "completed") {
          // Filtra reservas pagadas: 'paid' para turismo y 'true' para médicas
          return (item.type === "tourism" && item.status === "paid") || 
                 (item.type === "medical" && item.is_paid);
        } else {
          // Filtra reservas pendientes: no pagadas
          return (item.type === "tourism" && item.status !== "paid") || 
                 (item.type === "medical" && !item.is_paid);
        }
      }));
  
    } else {
      setFilteredItems(currentItems);
      setFilteredReserves(currentReserves);
    }
  };
  

  const handleOpenDialogEdit = (item) => {
    setSelectedItem(item);
    setOpenCreateContact(true);
  };

  const calculateRemaining = (reserve) => {
    if (!reserve || !reserve.payments) return 0;

    let totalPaid = 0;

    try {
      const paymentsArray = JSON.parse(reserve.payments);
      if (Array.isArray(paymentsArray)) {
        const filteredPayments = reserve.type === "medical" ? paymentsArray.slice(1) : paymentsArray;  // Excluir el primer pago en reservas médicas

        totalPaid = filteredPayments.reduce((sum, payment) => {
          return sum + (payment.price ? Math.round(Number(payment.price)) : 0);
        }, 0);
      }
    } catch (error) {
      console.error("Error parsing payments JSON:", error);
    }

    let remaining = reserve.total_value - totalPaid;

    return Math.round(remaining / 1000) * 1000;
  };


  const getValidRemainingAmount = (reserve) => {
      if (!reserve) return 0;

      let totalPaid = 0;

      try {
          const paymentsArray = JSON.parse(reserve.payments);
          if (Array.isArray(paymentsArray)) {
            const filteredPayments = reserve.type === "medical" ? paymentsArray.slice(1) : paymentsArray;

            totalPaid = filteredPayments.reduce((sum, payment) => {
                return sum + (payment.price ? Math.round(Number(payment.price)) : 0);
            }, 0);
          }
      } catch (error) {
          console.error("Error parsing payments JSON:", error);
      }

      let remaining = reserve.total_value - totalPaid;

      // 🔹 Asegurar que el remaining sea múltiplo exacto de 1,000
      return Math.round(remaining / 1000) * 1000;
  };

  const getLimitDateFromPackage = async (packId, type) => {
    const pack = await getPackDataById(packId, type);
    const startDate = type === "medical" ? pack.pack.trip_from : pack.pack.start_date;
    const date = new Date(startDate);
    date.setDate(date.getDate() - 45);
    return date;
  };

  const calculateLimitDate = async (startDate) => {
    const date = new Date(startDate);
    date.setDate(date.getDate() - 45);
    setLimitDate(date);
  };
  
  const handleOpenReserveModal = async (reserve) => {
    setSelectedReserve(reserve);
    if (reserve.type === "medical") {
      const pack = await getPackDataById(reserve.medical_package_id, "medical");
      await calculateLimitDate(pack.pack.trip_from);
    } else {
      const pack = await getPackDataById(reserve.pack_id, "tourism");
      await calculateLimitDate(pack.pack.start_date);
    }

    setIsReserveModalOpen(true);
  };

  const handleCloseReserveModal = () => {
    setIsReserveModalOpen(false);
  };

  const handleOpenCustomAmountModal = () => {
    setCustomAmount(Math.round(calculateRemaining(selectedReserve)));
    setIsCustomAmountModalOpen(true);
  };

  const handleCloseCustomAmountModal = () => {
    setIsCustomAmountModalOpen(false);
  };

  const createLinkPay = async (item, amount) => {
    try {
      setLoadingLink(true);

      const authUserSession = JSON.parse(localStorage.getItem("@authUserSession") || "{}");
      const uuidSliced = uuidv4().slice(0, 14);

      const dataNew = {
        name: authUserSession?.user?.name || "",
        email: authUserSession?.user?.email || "",
        cel: authUserSession?.user?.prex_phone_public + authUserSession?.user?.phone_public || "",
        localizator: uuidSliced,
        type: "PaymentHub",
        price: process.env.REACT_APP_URL_ENV === "staging" ? "200000" : amount,
        currency: "COP",
        url: `${window.location.origin}/payment-hub-pay-status`,
      };

      const url = process.env.REACT_APP_URL_CREATE_LINK;

      const payment = await axios.post(url, dataNew);

      localStorage.setItem("request-id-payment-hub", payment.data?.requestId);
      localStorage.setItem("payment_hub_item_id", item.id);
      localStorage.setItem("payment_hub_item_data", JSON.stringify(item));

      window.location.href = payment.data?.processUrl;
    } catch (error) {
      console.error(error);
    } finally {
      setLoadingLink(false);
    }
  };

  const handleCustomAmountPayment = async (e) => {
    e.preventDefault(); // Prevenir el comportamiento por defecto del formulario

    if (!selectedReserve) return;

    let remaining = calculateRemaining(selectedReserve);
    let validRemaining = Math.floor(remaining / 1000) * 1000;

    if (customAmount < 1 || customAmount > validRemaining) {
        console.error(`🚨 ERROR: Monto no válido. Min: $1.000 COP, Max: ${validRemaining}`);
        return;
    }

    try {
        await handleGoToPayReserve(selectedReserve, customAmount);
    } catch (error) {
        console.error("❌ Error processing custom amount payment:", error);
    }
  };

  
  const handleGoToPayReserve = async (reserve, amount) => {
    if ((reserve.status === "paid") || (reserve.is_paid)) return;

    // Guardar en localStorage si es una reserva médica o turística
    localStorage.setItem("payment_hub_reserve_id", reserve.id);
    localStorage.setItem("payment_hub_reserve_type", reserve.type);

    await createLinkPay(reserve, amount);
  };

  const handleGoToPay = async (item, amount) => {
    if (item.status === "completed") return;
    await createLinkPay(item, amount);
  };

  const handleCloseViewItem = () => {
    setOpenCreateContact(false);
  };

  const nextHandler = () => {
    setCurrentPage((prevPage) => Math.min(prevPage + 1, Math.ceil(filteredItems?.length / itemsPerPage)));
  };

  const prevHandler = () => {
    setCurrentPage((prevPage) => Math.max(prevPage - 1, 1));
  };

  const nextHandlerReserves = () => {
    setCurrentPageReserves((prevPage) => Math.min(prevPage + 1, Math.ceil(filteredReserves?.length / itemsPerPage)));
  }

  const prevHandlerReserves = () => {
    setCurrentPageReserves((prevPage) => Math.max(prevPage - 1, 1));
  }

  const formatPriceCOP = (price) => {
    return `${new Intl.NumberFormat("es-CO", {
      style: "currency",
      currency: "COP",
      minimumFractionDigits: 0,
      maximumFractionDigits: 0,
    }).format(price)} COP`;
  };

  const getTimeRemaining = (item) => {
    const createdAt = new Date(item.created_at);
    const expiryTime = new Date(createdAt.getTime() + 24 * 60 * 60 * 1000); // 24 horas después
    const now = new Date();
    const timeDiff = expiryTime - now;
  
    if (timeDiff <= 0) return 0; 
  
    const hours = Math.floor(timeDiff / (1000 * 60 * 60));
    const minutes = Math.floor((timeDiff % (1000 * 60 * 60)) / (1000 * 60));
    const seconds = Math.floor((timeDiff % (1000 * 60)) / 1000);
  
    return `${hours}h ${minutes}m ${seconds}s`;
  };

  const handlePayment = async (item, minAmount) => {
    try {
      setIsLoadingMinimumPayment(true);
      await handleGoToPay(item, minAmount)
    } catch (error) {
      console.error(error);
    } finally {
      setIsLoadingMinimumPayment(false);
    }
  }

  const handleSubmitCustomPayment = async (item, amount) => {
    try {
      setIsLoadingCustomPayment(true);
      localStorage.setItem("payment_hub_quote_id", item.id);
      await handleGoToPay(item, amount)
    } catch (error) {
      console.error(error);
    } finally {
      setIsLoadingCustomPayment(false);
    }
  }

  return (
    <div className={styles.cartSection}>
      {
        (isFetchingItems || isFetchingDates) ?
          <Loading />
        :
        (
          <div className={styles.cartSection}>

            <div className={styles.cart}>
              <h3 className="text-xl font-bold mb-4">{t("payment_center.title")}</h3>

              <div className={styles.cartItems}>
                <h4 className="text-lg font-semibold mt-6">
                  {t("payment_hub.quotes")}
                  <span className="text-customBlue mx-1">-</span>
                  {filteredItems?.length}
                </h4>
                <div className={`${styles.cartItems} h-fit max-h-[300px] overflow-y-auto customScroll`}>
                  {filteredItems?.length > 0 ? (
                    filteredItems.map((item) => (
                      <div key={item.id} className="flex justify-between items-center bg-white p-4 rounded-lg shadow-md border border-gray-200">
                        {/* Información del Pago */}
                        <div className="flex items-center space-x-4 w-4/5">
                          <MdSafetyCheck className="text-2xl text-customBlue" />
                          <div>
                            <h4 className="font-bold text-lg">{item.title}</h4>
                            <p className="text-sm text-gray-600 capitalize mt-2">
                              {item.status === "completed" ? t("payment_hub.completed") : t("payment_hub.pending")} - {new Date(item.created_at).toLocaleString()}
                            </p>

                            <div className="my-2">
                              {item.status === "pending" && getTimeRemaining(item) ? (
                                <div>
                                  <p className="line-through text-gray-500 text-sm">
                                    {formatPriceCOP(item.total_pay)} {/* Precio tachado */}
                                  </p>
                                  <p className="text-gray-800 font-semibold text-lg">
                                    {formatPriceCOP(item.amount_pay)} {/* Precio con descuento */}
                                  </p>
                                  <p className="text-yellow-600 text-sm mt-1">
                                    ⏳ {t("payment_hub.remaining_time")}: {getTimeRemaining(item)}
                                  </p>
                                </div>
                              ) : (
                                <p className="text-gray-800 font-semibold text-lg">
                                  {formatPriceCOP(item.total_pay)} {/* Precio normal si ya expiró */}
                                </p>
                              )}
                            </div>

                            <Link onClick={() => handleOpenDialogEdit(item)} className="text-customBlue hover:text-customHoverBlue underline">
                              {t("payment_center.see_details")}
                            </Link>
                          </div>
                        </div>

                        {/* Separador e Indicador de Estado */}
                        <div className="flex items-center justify-center w-1/5 border-l border-gray-300 pl-4">
                          {item.status === "completed" ? (
                            <div className="flex flex-col items-center text-center">
                              <FaCheckCircle className="text-green-600 text-2xl" />
                              <span className="text-green-600 font-bold text-sm mt-2">{t("payment_hub.success_payment")}</span>
                            </div>
                          ) : (
                            <div className="flex flex-col items-center text-center">
                              <MdCreditCard className="text-customBlue text-2xl" />
                              <span className="text-customBlue font-bold text-sm mt-2">{t("payment_hub.pending_payment")}</span>
                              <button
                                onClick={() => handleOpenDialogEdit(item)}
                                className="bg-customBlue text-white px-4 py-2 rounded-lg text-sm font-semibold hover:bg-customHoverBlue mt-2"
                              >
                                {t("payment_center.go_to_pay")}
                              </button>
                            </div>
                          )}
                        </div>
                      </div>
                    ))
                  ) : (
                    <div className="text-center">
                      <p className="text-gray-600">{t("payment_hub.no_results")}</p>
                      <AiOutlineExclamationCircle size={20} className="bg-white mx-auto mt-2" />
                    </div>
                  )}
                </div>

                {filteredItems?.length > 0 && (
                  <Paginado
                    nextHandler={nextHandler}
                    prevHandler={prevHandler}
                    currentPage={currentPage}
                    setCurrentPage={setCurrentPage}
                    data={filteredItems}
                    itemsPerPage={itemsPerPage}
                    pageRangeDisplayed={5}
                  />
                )}
              </div>

              {/* Reservations Section */}
              <h4 className="text-lg font-semibold mt-6">
                {t("payment_hub.reserves")} 
                <span className="text-customBlue mx-1">-</span> 
                {filteredReserves?.length}
              </h4>
              <div className={`${styles.cartItems} h-fit max-h-[300px] overflow-y-auto customScroll`}>
                {filteredReserves?.length > 0 ? (
                  filteredReserves.map((item) => (
                    <div
                      key={item.id}
                      className="flex justify-between items-center bg-white p-4 rounded-lg shadow-md border border-gray-200"
                    >
                      {/* Información del Pago */}
                      <div className="flex items-center space-x-4 w-4/5">
                        <MdSafetyCheck className="text-2xl text-customBlue" />
                        <div>
                          <h4 className="font-bold text-lg">
                              {item.type === "tourism" ? t("payment_hub.tourism_reserve") : t("payment_hub.medical_reserve")}
                          </h4>
                          <p className="text-sm text-gray-600 capitalize mt-2">
                            {((item.type === "tourism" && item.status === "paid") || (item.type === "medical" && item.is_paid)) 
                              ? t("payment_hub.completed") 
                              : t("payment_hub.pending")} 
                            <span className="mx-1 text-customBlue">-</span> 
                            {((item.type === "tourism" && item.status === "paid") || (item.type === "medical" && item.is_paid)) 
                              ? new Date(item.updated_at).toLocaleString() 
                              : new Date(item.created_at).toLocaleString()}
                          </p>
                          <p className="text-gray-800 font-semibold my-2">
                            {formatPriceCOP(item.total_value)}
                          </p>

                          <Link onClick={() => handleOpenReserveModal(item)} className="text-customBlue hover:text-customHoverBlue underline">
                            {t("payment_center.see_details")}
                          </Link>
                        </div>
                      </div>

                      {/* Separador e Indicador de Estado de Reservas */}
                      <div className="flex items-center justify-center w-1/5 border-l border-gray-300 pl-4">
                        {(item.type === "tourism" && item.status === "paid") || (item.type === "medical" && item.is_paid) ? (
                          <div className="flex flex-col items-center text-center">
                            <FaCheckCircle className="text-green-600 text-2xl" />
                            <span className="text-green-600 font-bold text-sm mt-2">{t("payment_hub.success_payment")}</span>
                          </div>
                        ) : (
                          new Date() > limitDates[item.id] ? (
                            <div className="flex flex-col items-center text-center">
                              <MdBlock className="text-red-600 text-2xl" />
                              <span className="text-red-600 font-bold text-sm mt-2">{t("payment_hub.blocked_payment")}</span>
                            </div>
                          ) : (
                            <div className="flex flex-col items-center text-center">
                              <MdCreditCard className="text-customBlue text-2xl" />
                              <span className="text-customBlue font-bold text-sm mt-2">{t("payment_hub.pending_payment")}</span>
                              <button
                                onClick={() => handleOpenReserveModal(item)}
                                className="bg-customBlue text-white px-4 py-2 rounded-lg text-sm font-semibold hover:bg-customHoverBlue mt-2"
                              >
                                {t("payment_center.go_to_pay")}
                              </button>
                            </div>
                          )
                        )}
                      </div>
                    </div>
                  ))
                ) : (
                  <div className="text-center">
                    <p className="text-gray-600">{t("payment_hub.no_reserve_results")}</p>
                    <AiOutlineExclamationCircle
                      size={20}
                      className="bg-white mx-auto mt-2"
                    />
                  </div>
                )}
              </div>

              {filteredReserves?.length > 0 && (
                <Paginado
                  nextHandler={nextHandlerReserves}
                  prevHandler={prevHandlerReserves}
                  currentPage={currentPageReserves}
                  setCurrentPage={setCurrentPageReserves}
                  data={filteredReserves}
                  itemsPerPage={itemsPerPage}
                  pageRangeDisplayed={5}
                />
              )}
            </div>

            {/* Filtros */}
            <div className="w-[25%]">
              <h3 className="text-xl font-bold mb-4">{t("payment_hub.filters.title")}</h3>
              <div className="space-y-2">
                <button
                  onClick={() => handleFilterChange("pending")}
                  className={`flex items-center gap-2 w-full px-4 py-2 rounded-lg font-semibold border hover:bg-gray-300 ${
                    filter === "pending"
                      ? "bg-customBlue text-white border-customBlue"
                      : "bg-white text-gray-800 border-gray-300"
                  }`}
                >
                  <MdPendingActions size={30} className={`${filter === "pending" ? "text-white" : "text-customBlue"}`} /> 
                  <span className="text-sm">{t("payment_hub.filters.pending_payments")}</span> 
                </button>
                <button
                  onClick={() => handleFilterChange("completed")}
                  className={`flex items-center gap-2 w-full px-5 py-2 rounded-lg font-semibold border hover:bg-gray-300 ${
                    filter === "completed"
                      ? "bg-green-600 text-white border-green-600"
                      : "bg-white text-gray-800 border-gray-300"
                  }`}
                >
                  <FaCheck size={20} className={`${filter === "completed" ? "text-white" : "text-green-600"}`} /> 
                  <span className="text-sm flex">{t("payment_hub.filters.completed_payments")}</span>
                </button>

                <div className="flex justify-center w-full">
                  <button
                    onClick={() => handleFilterChange(null)}
                    className="flex items-center gap-2 w-fit px-4 py-2 rounded-lg font-semibold border bg-white text-gray-800 border-gray-400 hover:bg-gray-300"
                  >
                    <MdDelete size={20} color="var(--bluePrimarys)" /> <span className="text-sm flex">{t("payment_hub.filters.clean")}</span>
                  </button>
                </div>
              </div>
            </div>

            <Dialog
              PaperProps={{ sx: { width: "100%", maxWidth: "850px!important", padding: "26px", borderRadius: "8px" } }}
              open={openViewItem}
              onClose={handleCloseViewItem}
              BackdropProps={{
                sx: {
                  backgroundColor: "#021624b0",
                },
              }}
            >
              <ModalPaymentCenter item={selectedItem} handleClose={handleCloseViewItem} handlePayment={handlePayment} isLoadingMinimumPayment={isLoadingMinimumPayment} handleOnSubmit={handleSubmitCustomPayment} />
            </Dialog>

            {/* Modal for reserve details */}
            {isReserveModalOpen && selectedReserve && (
              <Dialog
                PaperProps={{ sx: { width: "100%", maxWidth: "850px!important", padding: "26px",borderRadius: "8px" } }}
                open={isReserveModalOpen} onClose={handleCloseReserveModal}>
                <div className="p-6">
                  <h3 className="flex justify-between text-xl text-customBlue font-bold mb-4">
                    <small>{t("payment_hub.reserve_details")}</small>
                    <small>{t("payment_hub.reserve_id")}: {selectedReserve.id}</small>
                  </h3>
                  
                  {((selectedReserve.type === "tourism" && selectedReserve.status === "pending") 
                    || (selectedReserve.type === "medical" && !selectedReserve.is_paid)) && (
                    <div className="flex gap-2 items-center bg-yellow-100 text-yellow-800 p-2 rounded-md mb-4">
                      <MdWarning size={20} color="text-yellow-800" /> 
                      {new Date() > limitDate ? t("payment_hub.limit_date_past") : t("payment_hub.limit_date")} 
                      <p className="text-customBlue">
                        {limitDate.toLocaleDateString(i18n.language === "en" ? "en-US" : "es-ES")}.
                      </p>
                    </div>
                  )}

                  <div className="w-full flex justify-center mb-4">
                    <a
                      href={selectedReserve.type === "tourism" ? `https://wellezy.com/packages/${selectedReserve.pack_id}` : `https://wellezy.com/TourismHealth/DetailProcedure/${selectedReserve.medical_package_id}`}
                      target="_blank"
                      rel="noreferrer"
                      className="w-fit bg-customBlue hover:bg-customHoverBlue text-white px-4 py-2 rounded-lg"
                    >
                      {t("payment_hub.view_package")}
                    </a>
                  </div>

                  <h3 className="text-base font-bold my-4">{t("payment_hub.credit_made")}</h3>
                  
                  <div className="h-fit max-h-[300px] overflow-y-auto customScroll">
                    <table className="w-full border-collapse border border-gray-300">
                      <thead>
                        <tr>
                          <th className="border border-gray-300 p-2">{t("payment_hub.date")}</th>
                          <th className="border border-gray-300 p-2">{t("payment_hub.amount")}</th>
                          <th className="border border-gray-300 p-2">{t("payment_hub.status")}</th>
                        </tr>
                      </thead>
                      <tbody>
                        {
                          (
                            selectedReserve.type === "medical" ? 
                            (JSON.parse(selectedReserve.payments).length > 1) : 
                            (JSON.parse(selectedReserve.payments).length > 0)
                          ) ? JSON.parse(selectedReserve.payments)
                          .slice(selectedReserve.type === "medical" ? 1 : 0)
                          .map((payment, index) => (
                            <tr key={index}>
                              <td className="border border-gray-300 p-2">{new Date(payment.created_at).toLocaleString()}</td>
                              <td className="border border-gray-300 p-2">{formatPriceCOP(payment.price)}</td>
                              <td className="border border-gray-300 p-2">{payment.status}</td>
                            </tr>
                          ))
                          :
                          (
                            <tr>
                              <td className="border border-gray-300 p-2">N/A</td>
                              <td className="border border-gray-300 p-2">N/A</td>
                              <td className="border border-gray-300 p-2">N/A</td>
                            </tr>
                          )
                        }
                      </tbody>
                    </table>
                  </div>

                  <div className="mt-4">
                    {((selectedReserve.type === "tourism" && selectedReserve.status === "pending") || (selectedReserve.type === "medical" && !selectedReserve.is_paid)) && (
                      <> 
                        <p className="text-lg">
                          {t("payment_hub.total")}:{" "}
                          {formatPriceCOP(selectedReserve.total_value)}
                        </p>
                        <p className="text-lg">
                          {t("payment_hub.remaining")}:{" "}
                          {formatPriceCOP(calculateRemaining(selectedReserve))}
                        </p>
                      </>
                      )
                    }
                  </div>

                  <div className="mt-4 flex justify-center gap-5">
                  {((selectedReserve.type === "tourism" && selectedReserve.status === "pending") 
                    || (selectedReserve.type === "medical" && !selectedReserve.is_paid)) ? (
                    <>
                      {new Date() > limitDate ? (
                        <div className="flex gap-2 items-center !bg-red-100 text-red-800 p-2 rounded-md mb-4">
                          <MdBlock size={20} color="text-red-800" /> {t("payment_hub.payment_blocked")}
                        </div>
                      ) : (
                        <button
                          onClick={() => handleOpenCustomAmountModal()}
                          className="bg-customBlue hover:bg-customHoverBlue text-white px-4 py-2 rounded-lg"
                        >
                          {t("payment_hub.proceed_custom_payment")}
                        </button>
                      )}
                    </>
                  ): (
                    <div className="flex gap-2 items-center bg-green-100 text-green-800 p-2 rounded-md mb-4">
                      <MdCheck size={20} color="text-green-800" /> {t("payment_hub.payment_completed_in_time")}
                    </div>
                  )}

                  </div>
                </div>
              </Dialog>
            )}

            {isCustomAmountModalOpen && selectedReserve && (
              <Dialog
                PaperProps={{
                  sx: { width: "100%", maxWidth: "500px", padding: "20px", borderRadius: "8px" },
                }}
                open={isCustomAmountModalOpen}
                onClose={handleCloseCustomAmountModal}
              >
                <div className="p-6">
                  <h3 className="text-xl font-bold mb-4">{t("payment_hub.custom_payment_title")}</h3>
                  <form onSubmit={handleCustomAmountPayment}>
                    <div className="mb-4">
                      <label htmlFor="customAmount" className="block text-gray-700 font-bold mb-2">
                        {t("payment_hub.enter_amount")}
                      </label>
                      <input
                        type="number"
                        id="customAmount"
                        value={customAmount || ''}  // Permite que el input se muestre vacío cuando customAmount es null o undefined
                        onChange={(e) => {
                          const value = e.target.value;

                          if (value === '') {
                            setCustomAmount('');  // Permite vacío mientras se edita
                            return;
                          }

                          const numValue = Number(value);
                          const maxValue = getValidRemainingAmount(selectedReserve);

                          if (numValue > maxValue) {
                            setCustomAmount(maxValue);
                          } else {
                            setCustomAmount(numValue);
                          }
                        }}
                        onBlur={(e) => {
                          const value = Number(e.target.value);
                          const minValue = 1;

                          if (isNaN(value) || value < minValue) {
                            setCustomAmount(minValue);  // Si está vacío o es inválido, se setea al mínimo al salir del input
                          }
                        }}
                        min={1}
                        max={getValidRemainingAmount(selectedReserve)}
                        className={`w-full border border-gray-300 rounded-lg py-2 px-3 ${customAmount < 1 ? "border-red-400" : ""}`}
                        required
                      />

                      <p className="text-sm mt-2">
                        <h3 className="text-customBlue">
                          {t("payment_hub.current_amount")}
                        </h3>
                        {
                          formatPriceCOP(customAmount)
                        }
                      </p>
                      <p className="text-sm text-gray-500 mt-2">
                        {t("payment_hub.amount_limits")}
                        <br />
                        {
                          "Max: " + formatPriceCOP(calculateRemaining(selectedReserve))
                        }
                      </p>
                    </div>
                    <div className="flex justify-end">
                      <button
                        type="submit"
                        className="bg-customBlue hover:bg-customHoverBlue text-white px-4 py-2 rounded-lg"
                      >
                        {t("payment_hub.proceed_payment")}
                      </button>
                    </div>
                  </form>
                </div>
              </Dialog>
            )}
          </div>
        )
      }
    </div>
  );
};

export default PaymentCenter;