import { useState, useEffect } from "react";
import { useLocation, Link, useNavigate, useSearchParams } from "react-router-dom";
import CarouselSevices, {
  servicesTravel,
} from "../../../share/components/CarouselServices/CarouselSevices";
import "./styles.css";
import GalleryImg from "./GalleryImg/GalleryImg";
import ServicesInclude from "./ServicesInclude/ServicesInclude";
import AboutHotel from "./AboutHotel/AboutHotel";
import HabDetail from "./HabDetail/HabDetail";
import CartReserve from "./CartReserve/CartReserve";
import { useTranslation } from "react-i18next";
import SvgIcon, { iconList } from "../../../share/components/iconSVG";
import { MdCameraAlt } from "react-icons/md";
import toast, { Toaster } from "react-hot-toast";
import TextComponent from "../../../share/components/fonts/TextComponent";
import ReturnPage from "../../../share/components/return/ReturnPage";
import AnyQuestions from "../../../home/components/anyQuestion";
import useHotelBuildPackage from "@/Hooks/BuildPackage/useHotelBuildPackage/UseHotelBuildPackage";
import SessionModal from "../../../session/components/SessionModal/SessionModal";
import ModalContainer from "../../../share/components/modalContainer";
import styles from "./styles.module.css"
import style from "react-syntax-highlighter/dist/esm/styles/hljs/a11y-dark";
import { useDispatch, useSelector } from "react-redux";
import { setDataCart } from "@/store/slices/dataCartBuy/dataCartBuySlice";
import { uuid } from "@/ui/utils";
import axios from "axios";
import Loading from "@/ui/views/share/components/loading";

const DetailAfiliate = () => {

  const { pathname } = useLocation()
  const [searchParam] = useSearchParams()
  const [loading, setLoading] = useState(false)
  const [slideShow, setSlideShow] = useState(false);
  const [dataHotel, setDataHotel] = useState({})
    const { t } = useTranslation("global");
  const dispatch = useDispatch()
  let [mountRoom, setMountRoom] = useState([]);
  const [rooms, setRooms] = useState([]);
  const [listReserve, setListReserve] = useState([]);
  const [buttonBooking, setButtonBooking] = useState(false);
  const [selectedRoom, setSelectedRoom] = useState("");


  const navigate = useNavigate();
  let suma = 0;

  const {
    nextStep,
    activeSessionModal,
    setActiveSessionModal,
    modalQuestion,
    setModalQuestion,
    setUrlSpecialist
  } = useHotelBuildPackage();


  const fetchHotel = async () => {

    try {

      const data = await axios.post(`${process.env.REACT_APP_URL_API_AIOP}api/get/hotel`, {
        id: searchParam.get("id"),
        arrival: searchParam.get("arrival"),
        departure: searchParam.get("departure")
      });
      setLoading(false)
      setDataHotel(data.data)
    } catch (error) {
      setLoading(false)
      console.error("Error al obtener el hotel:", error);
    }
  };

  useEffect(() => {
    setLoading(true)
    fetchHotel();
  }, [searchParam]);


  function formatterText(frase) {
    // Dividir la frase en palabras individuales
    let palabras = frase?.toLowerCase()?.split(" ");

    // Capitalizar la primera letra de cada palabra
    for (let i = 0; i < palabras?.length; i++) {
      palabras[i] = palabras[i]?.charAt(0)?.toUpperCase() + palabras[i]?.slice(1);
    }

    // Unir las palabras en una sola frase
    let resultado = palabras?.join(" ");

    return resultado;
  }

  useEffect(() => {
    // Función local para crear los nuevos objetos

    setRooms(listReserve);
  }, [listReserve]);

  const location = useLocation();

  const routeStartsWithBuildPackageRoom = location.pathname.startsWith(
    "/build-package/room"
  );

  useEffect(() => {
    setTimeout(() => {
      window.scrollTo(0, 0);
    }, 0);
  }, [location]);

  useEffect(() => {
    const body = document.querySelector("body");
    slideShow ? (body.style.overflow = "hidden") : (body.style.overflow = "");
  }, [slideShow]);

  const navigateReservation = () => {
    listReserve.forEach((item, key) => {
      suma += parseInt(item?.priceWithDays);
    });

    const stateProps = {
      price: suma,
      hab:listReserve,
      hotel: dataHotel,
      person: searchParam.get("persons"),
    }


      navigate("/hotels/reservation/affiliate", {
        state: stateProps,
      });
    
      

  };

  const stars = [];
  const star = dataHotel?.category;

  for (let i = 0; i < Number(star); i++) {
    stars.push(
      <SvgIcon key={i} name={iconList.IconStar} color={"#ffdd44"} size={30} />
    );
  }


  const [loadingImage, setLoadingImage] = useState(true);

  useEffect(() => {
    setTimeout(() => {
      setLoadingImage(false);
    }, 3000);
  }, []);

  const notify = (title, icon) =>
    toast(title, {
      icon: icon,
    });

    const joinReserve = (item) => {
      setListReserve((prevList) => {
        const isDuplicate = prevList?.some((reserveItem) => reserveItem?.id === item?.id);
    
        if (!isDuplicate) {
          const message = t("hotels.selected_room.reserve_added");
          notify(message, "✅");
          return [...prevList, item];
        }
    
        return prevList; // Si ya existe, no hace nada.
      });
    };
    
    

  const deleteReserve = (item) => {
    const message = t("hotels.selected_room.reserve_eliminated");
    notify(message, "❌");
    const newCart = listReserve.filter((el) => el?.id !== item);

    console.log("newCart", newCart);
    setListReserve(
      listReserve.filter((el) => el?.id !== item)
    );
    setButtonBooking(false);

  };

  function Image({ url }) {
    const onLoad = () => {
      setLoadingImage(false);
    };
    const onError = () => {
      setLoadingImage(true);
    };
    return (
      <img
        className="image-hotel-detail"
        src={`${process.env.REACT_APP_URL_API_AIOP}storage/${url}`}
        onLoad={onLoad}
        onError={onError}
        alt="Hotel"
      />
    );
  }

  return (
    <>
      {
        loading ?
          <Loading />
          :
          <>

            <GalleryImg
              Images={dataHotel.images}
              slideShow={slideShow}
              setSlideShow={setSlideShow}
            />


            

      <SessionModal
        activeSessionModal={activeSessionModal}
        setActiveSessionModal={setActiveSessionModal}
        url={setUrlSpecialist.specialist}
      />
      <Toaster position="top-center" reverseOrder={false} />
      {
        !routeStartsWithBuildPackageRoom && (
          <>
            <CarouselSevices current={servicesTravel[1]?.title} />
            <ReturnPage />
          </>
        )
      }
      <div className="container-detail-hotel">
        <div className="detail-hotel">
          <div
            className="contain-img-principal-hotel"
            onClick={() => {
              setSlideShow(!slideShow);
            }}
          >
            <div className="section-img-big">
              <div className="section-img-1">
                {loadingImage ? (
                  <div className="img-hotel-skeleton">
                    <MdCameraAlt size={40} color="#c8c8c8" />
                  </div>
                ) : (
                  <Image url={`${dataHotel?.images?.[0]?.url}`} />
                )}
              </div>
              <div className="section-img-2">
                {loadingImage ? (
                  <div className="img-hotel-skeleton">
                    <MdCameraAlt size={40} color="#c8c8c8" />
                  </div>
                ) : (
                  <Image url={`${dataHotel?.images?.[1]?.url}`} />
                )}
              </div>
            </div>
            <div className="section-img-layout-for">
              <div className="section-img-layout-1">
                {loadingImage ? (
                  <div className="img-hotel-skeleton">
                    <MdCameraAlt size={40} color="#c8c8c8" />
                  </div>
                ) : (
                  <Image url={`${dataHotel?.images?.[2]?.url}`} />
                )}
              </div>
              <div className="section-img-layout-2">
                {loadingImage ? (
                  <div className="img-hotel-skeleton">
                    <MdCameraAlt size={40} color="#c8c8c8" />
                  </div>
                ) : (
                  <Image url={`${dataHotel?.images?.[3]?.url}`} />
                )}
              </div>
            </div>
          </div>

          <div className="contain-title-hotel">
            <div>
              <TextComponent size={"lg"} fontWeight="bold" tag="h1">
                {dataHotel?.name}
              </TextComponent>

                <div className="flex items-center gap-2 mt-2">
                  <span className="flex">{stars}</span>
                  <div className="stars-description mt-1">
                    <span>{Number(star).toFixed(1)}</span>
                    <TextComponent size={"md"} fontWeight="bold">
                      {Number(star) === 5 ? t("hotel.excelent") : t("hotel.good")}
                    </TextComponent>
                  </div>
                </div>
            </div>
            <div className="contain-title-hotel-column">
              <TextComponent size={"md"}>
                {dataHotel?.city?.name} {formatterText(dataHotel?.direction)}
              </TextComponent>
              
              
            </div>
          </div>

          <ServicesInclude
            information={dataHotel?.description} //Informacion del hotel
            name={dataHotel?.nombre_h} // Nombre del hotel
            ubication={dataHotel?.direction}
            serviciosHot={dataHotel?.establishment_services}
            serviciosHab={selectedRoom?.article_and_services ? selectedRoom?.article_and_services : dataHotel?.rooms?.[0]?.article_and_services}
            checkin={dataHotel?.check_in} // Hora de entrada
            checkout={dataHotel?.check_out} // Hora de salida
            star={dataHotel?.category} // Estrellas del hotel
          />
          <div className="contain-detail-hotel-reserve">
            <div className="contain-detail-hotell">
              {dataHotel?.rooms?.map((item, key) => {
                return (
                  <HabDetail
                    Hab={item}
                    key={key}
                    id={key}
                    people={dataHotel}
                    rooms={rooms}
                    typeAcomodation={""}
                    listReserve={listReserve}
                    setListReserve={setListReserve}
                    buttonBooking={buttonBooking}
                    setButtonBooking={setButtonBooking}
                    joinReserve={joinReserve}
                    deleteReserve={deleteReserve}
                    selectedRoom={selectedRoom}
                    setSelectedRoom={setSelectedRoom}
                    arrival={searchParam.get("arrival")}
                    departure={searchParam.get("departure")}
                    bestOffer={""}
                  />
                );
              })}
            </div>

            <CartReserve
              list={listReserve}
              setList={setListReserve}
              navigateMakeReservation={navigateReservation}
              buttonBooking={buttonBooking}
              setButtonBooking={setButtonBooking}
              deleteReserve={deleteReserve}
            />
          </div>
        </div>
      </div>
      </>

      }

    </>
  )
}

export default DetailAfiliate