import { useState } from 'react';
import axios from 'axios';
import "./styles.css";
import { IoClose } from 'react-icons/io5';
import toast, { Toaster } from "react-hot-toast";
import { Grid } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import useHeaderSession from '@/ui/viewModels/session/header.session.viewModel';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { formatToISO, formatYYYYMMDDToDDMMYYYY } from '@/common/services/dateUtils';
import InputNacionalityStd from '../../BuildPackage/pages/std/components/InputNacionality/InputNacionalityStd';
import DateInputWithPicker from '../../BuildPackage/pages/std/components/SelectedDate/DateInputWithPicker';
import { LuCalendarPlus } from 'react-icons/lu';
import { useRef } from 'react';
import { BiSolidRightArrow } from 'react-icons/bi';
import AllCampsForms from '@/ui/views/BuildPackage/pages/std/AllCampsForm';
import { BsCheckCircleFill } from 'react-icons/bs';
import PhotoUploadDialog from './stdComponents/PhotoUploadDialog';
import Loading from '@/ui/views/share/components/loading'

function getBaseUrl(url) {
    const parsedUrl = new URL(url);
    const baseUrl = `${parsedUrl.protocol}//${parsedUrl.host}`;
    return baseUrl;
}

const ReservationForm = ({ handleClose, packageMedicData, procedureName }) => {

    //console.log("DATOS DEL PAQUETE", packageMedicData);

    //console.log("Nombre del proc",procedureName )

    const {
        formData,
        setFormData,
        obtenerFormulariosPorTexto,
        obtenerEstadoInicial,
        FormErrors,
        setFormErrors
    } = AllCampsForms()

    const [loading, setLoading] = useState(false)

    const [openCreatePhotos, setOpenCreatePhotos] = useState(false);

    const [dataDoc, setDataDoc] = useState({})
    const [uploadedImages, setUploadedImages] = useState({});
    const [terms, setTerms] = useState(false)

    const [openCollapsible, setOpenCollapsibe] = useState(false);
    const [initialized, setInitialized] = useState(false);
    const formsFilter = obtenerFormulariosPorTexto(procedureName)

    const [nacionalityDropdown, setNacionalityDropdown] = useState(false);
    const [dataNacionality, setDataNacionality] = useState([]);
    const [nacionality, setNacionality] = useState("");
    const [validation, setValidation] = useState(false)
    const [paceholders, setPaceholders] = useState("");
    const [loadinNa, setLoadinNa] = useState(false)

    const [operationDate, setOperationDate] = useState("")
    const [selectedOperationDate, setSelectedOperationDate] = useState(null);
    const calendarRef = useRef();

    const [breastSurgeryDate, setBreastSurgeryDate] = useState("");
    const [selectedBreastSurgeryDate, setSelectedBreastSurgeryDate] = useState(null);
    const breastSurgeryCalendarRef = useRef();

    //estados datos salud
    const [height, setHeight] = useState("")
    const [weight, setWeight] = useState("")

    //estados hábitos
    const [drinkAlcohol, setDrinkAlcohol] = useState(false)
    const [smoke, setSmoke] = useState(false)

    //estados hijos
    const [sons, setSons] = useState(false)
    const [quantityChild, setQuantityChild] = useState("")
    const [ages, setAges] = useState([])
    const [showAges, setShowAges] = useState(true);

    //estados cirugía
    const [haveSurgery, setHaveSurgery] = useState(false)
    const [whichSurgery, setWhichSurgery] = useState("")

    //estados enfermedad
    const [haveIllness, setHaveIllness] = useState(false)
    const [whichIllness, setWhichIllness] = useState("")

    //estados drogas/estupefacientes
    const [drugsConsume, setDrugsConsume] = useState(false)
    const [whichDrugs, setWhichDrugs] = useState("")

    //estados medicamentos
    const [consumeMedicaments, setConsumeMedicaments] = useState(false);
    const [whichMedicaments, setWhichMedicaments] = useState("");

    //estados alergias 
    const [youAlergicKnowed, setYouAlergicKnowed] = useState(false)
    const [whichAlergicKnowed, setWhichAlergicKnowed] = useState("")


    //estados alergias medicamentos
    const [youAlergic, setYouAlergic] = useState(false)
    const [whichAlergic, setWhichAlergic] = useState("")

    const { t, i18n } = useTranslation("global");
    const [name, setName] = useState('');
    const [lastname, setLastname] = useState('');
    const [document, setDocument] = useState('');
    const [cel, setCel] = useState('');
    const [email, setEmail] = useState('');
    const [city, setCity] = useState('');
    const [comments, setComments] = useState('');

    const { userDetail } = useHeaderSession()

    const navigate = useNavigate();

    useEffect(() => {

        const url = `${process.env.REACT_APP_URL_API_FINANMED}/v2/get/doctor/${packageMedicData.specialist_slug}`

        //  console.log("Url a buscar: ", url)
        axios.get(url)
            .then((res) => {
                setDataDoc(res.data)
            })
            .catch((error) => {
                console.log(error);
            });
    }, [])

    //  console.log("Detalle especialista: ", dataDoc)


    useEffect(() => {
        if (userDetail?.email) {
            setEmail(userDetail.email);
        }
        if (userDetail?.phone_public) {
            setCel(userDetail.phone_public);
        }
        if (userDetail?.name) {
            const nameParts = userDetail.name.split(' ');
            setName(nameParts[0]);
            setLastname(nameParts.slice(1).join(' '));
        }
    }, [userDetail]);


    const handleInputChange = (setter) => (e) => {
        setter(e.target.value);
    };

    const handleNumberInputChange = (setter) => (e) => {
        const value = e.target.value.replace(/\D/g, '');
        setter(value);
    };

    const notify = (title, icon) =>
        toast(title, {
            icon: icon,
        });

    const validateForm = () => {
        if (!comments || !city || !name || !document || !cel || !email) {
            const message = t("packs.health.detailed_pack.reserve_modal.must_fill_all");
            notify(message, "❌");
            return false;
        }

        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        if (!emailRegex.test(email)) {
            const message = t("packs.health.detailed_pack.reserve_modal.must_valid_email");
            notify(message, "❌");
            return false;
        }

        return true;
    };

    const createLinkPay = async () => {
        try {
            const authUserSession = await JSON.parse(localStorage.getItem("@authUserSession") || "{}");

            if (!authUserSession?.user?.id) {
                navigate("/login");
                return;
            }


            const data = {
                "name": name,
                "lastname": lastname || " ",
                "document": document,
                "email": email,
                "cel": cel,
                "localizator": document,
                "type": "medicalPackage",
                "currency": "COP",
                "price": 200000,
                "url": `${getBaseUrl(window.location.href)}/TourismHealth/medicalPackagePay`,
            };

            localStorage.setItem('data-pay-medicalpackage', JSON.stringify(data));
            const url = process.env.REACT_APP_URL_CREATE_LINK;
            const medicalPaymentLinkCreation = await axios.post(url, data)

            if (medicalPaymentLinkCreation.data?.status?.status === "FAILED") {
                const message = t("packs.health.detailed_pack.reserve_modal.error_payment_link");
                notify(message, "❌");
            } else {
                localStorage.setItem("request-id-medicalpackage", medicalPaymentLinkCreation.data?.requestId);

                // Guardar datos en localStorage
                const requestData = {
                    ...data,
                    city: city,
                    comments: comments,
                    packageId: packageMedicData.id,
                    packagePrice: packageMedicData.price,
                    packageName: packageMedicData.name,
                    packageImage: packageMedicData.image,
                    package: packageMedicData
                };

                // Eliminar datos anteriores en localStorage
                localStorage.removeItem('request-medical-datapackage');

                const existingRequests = JSON.parse(localStorage.getItem('request-medical-datapackage')) || [];
                existingRequests.push(requestData);
                localStorage.setItem('request-medical-datapackage', JSON.stringify(existingRequests));

                window.location.href = medicalPaymentLinkCreation.data?.processUrl;
                setLoading(false)
            }
        } catch (error) {
            const message = t("packs.health.detailed_pack.reserve_modal.error_payment_link");
            notify(message, "❌");
            console.log(error);
        }
    };

    const fields = [
        { label: t("packs.health.detailed_pack.reserve_modal.document"), placeholder: t("packs.health.detailed_pack.reserve_modal.document_placeholder"), value: document, setter: setDocument, type: 'number', show: true },
        { label: t("packs.health.detailed_pack.reserve_modal.city"), placeholder: t("packs.health.detailed_pack.reserve_modal.city_placeholder"), value: city, setter: setCity, type: 'text', show: true },
        { label: t("packs.health.detailed_pack.reserve_modal.additional_comments"), placeholder: t("packs.health.detailed_pack.reserve_modal.additional_comments_placeholder"), value: comments, setter: setComments, type: 'text', show: false },
    ];


    const selectedNacionality = (code, name) => {
        setNacionality(name);
    };

    const findCountryClient = (country) => {
        setLoadinNa(true);
        const url = `${process.env.REACT_APP_URL_API_FLY}restel/country`;
        axios
            .post(url, {
                code: country,
            })
            .then((res) => {
                setDataNacionality(res.data);
                setLoadinNa(false);
            })
            .catch((err) => {
                console.error(err);
                setLoadinNa(false);
            });
    };




    const handleOpenPhotos = () => {
        setOpenCreatePhotos(true);
    };

    const handleClosePhotos = () => {
        setOpenCreatePhotos(false);
    };




    useEffect(() => {
        //console.log('Formulario:', formData);
    }, [formData]);

    const firstCheckboxField = formsFilter?.[0]?.formulario?.find(
        (field) => field.type === 'checkbox'
    );

    const secondCheckboxField = formsFilter?.[0]?.formulario?.find(
        (field) => field.key === 'biggest_concern_doubt'
    );


    const performedBreastSurgeryField = formsFilter?.[0]?.formulario?.find(
        (field) => field.key === 'performed_breast_surgery'
    );

    const knowImplantPlaceField = formsFilter?.[0]?.formulario?.find(
        (field) => field.key === 'know_implant_place'
    );

    const performedBreastSurgeryDescriptionField = formsFilter?.[0]?.formulario?.find(
        (field) => field.key === 'performed_breast_surgery_description'
    );

    const somethingImportantField = formsFilter?.[0]?.formulario?.find(
        (field) => field.key === 'something_you_consider_important'
    );

    const someoneRecommendedUsField = formsFilter?.[0]?.formulario?.find(
        (field) => field.key === 'someone_recommended_us'
    );

    const recommendDescriptionField = formsFilter?.[0]?.formulario?.find(
        (field) => field.key === 'recommend_description'
    );

    useEffect(() => {
        // Evita re-inicializar si formsFilter no existe o si ya se inicializó
        if (!formsFilter?.[0] || initialized) return;

        setFormData((prev) => {
            const updated = { ...prev };
            const fields = formsFilter[0].formulario || [];

            // Recorre todos los campos de ese formulario
            fields.forEach((field) => {
                // Solo si no está inicializado aún
                if (updated[field.key] === undefined) {
                    // Inicializa campos de texto como string vacío
                    if (field.type === 'text') {
                        updated[field.key] = '';
                    }
                    // Inicializa campos checkbox simples como false
                    else if (field.type === 'checkbox' && !field.options) {
                        updated[field.key] = false;
                    }
                    // Inicializa campos select como string vacío
                    else if (field.type === 'select') {
                        updated[field.key] = '';
                    }
                }

                // Para checkboxes múltiples con opciones
                if (field.type === 'checkbox' && field.options) {
                    // Si no existe un objeto para almacenar valores individuales
                    if (!updated[field.key] || typeof updated[field.key] !== 'object') {
                        updated[field.key] = {};
                    }
                    // Inicializa cada opción como false si no está definida
                    field.options.forEach((option) => {
                        if (updated[field.key][option.key] === undefined) {
                            updated[field.key][option.key] = false;
                        }
                    });
                }
            });

            return updated;
        });

        // Marca que se inicializó
        setInitialized(true);
    }, [formsFilter, initialized, setFormData]);

    const flattenFormData = (data, procedureName) => {
        const flattened = {
            [procedureName]: {}
        };

        Object.keys(data).forEach((key) => {
            if (typeof data[key] === 'object' && !Array.isArray(data[key])) {
                Object.keys(data[key]).forEach((subKey) => {
                    flattened[procedureName][subKey] = data[key][subKey];
                });
            } else {
                flattened[procedureName][key] = data[key];
            }
        });

        return flattened;
    };

    const validateFields = () => {
        const textFields = formsFilter?.[0]?.formulario
            ?.filter((field) => field.type === 'text')
            ?.slice(0, 2);

        const allFieldsFilled = textFields?.every((field) => formData[field.key]?.trim() !== '');

        const performedBreastSurgeryDescriptionFilled = formData['performed_breast_surgery'] === true
            ? formData['performed_breast_surgery_description']?.trim() !== ''
            : true;

        const knowImplantPlaceFilled = formData['performed_breast_surgery'] === true
            ? formData['know_implant_place']?.trim() !== ''
            : true;

        const somethingImportantFilled = formData['something_you_consider_important']?.trim() !== '';

        const biggestConcernDoubtFilled = firstCheckboxField?.options?.some(
            (option) => formData[firstCheckboxField.key]?.[option.key] === true
        );

        const allShowFieldsFilled = fields
            .filter((field) => field.show)
            .every((field) => field.value?.trim() !== '');

        const requiredImagesCount = formsFilter[0]?.require_images?.length || 0;
        const uploadedImagesCount = uploadedImages[procedureName] ? Object.keys(uploadedImages[procedureName]).length : 0;
        const allImagesUploaded = uploadedImagesCount >= requiredImagesCount;

        const whichOthersFilled = formData[firstCheckboxField.key]?.others
            ? formData['which_others']?.trim() !== ''
            : true;

        if (!height || !weight || !nacionality || !operationDate || !allFieldsFilled || !performedBreastSurgeryDescriptionFilled || !knowImplantPlaceFilled || !somethingImportantFilled || !biggestConcernDoubtFilled || !allShowFieldsFilled || !allImagesUploaded || !whichOthersFilled) {
            if (!biggestConcernDoubtFilled) {
                notify(t("packs.health.detailed_pack.reserve_modal.stdForm.mark_as_option"), "❌");
            }
            if (!allImagesUploaded) {
                notify(`${t("packs.health.detailed_pack.reserve_modal.stdForm.should_be")} ${requiredImagesCount - uploadedImagesCount} ${t("packs.health.detailed_pack.reserve_modal.stdForm.images")}`, "❌");
            }
            setValidation(true);
            return false;
        }
        setValidation(false);
        return true;
    };

    const sendStd = async () => {

        if (!validateFields()) {
            return;
        }

        try {
            setLoading(true)

            const performedBreastSurgeryDescription = formData[performedBreastSurgeryDescriptionField.key] || '';
            const breastSurgeryDate = formData.performed_breast_surgery_date || '';
            const concatenatedDescription = performedBreastSurgeryDescription;

            const flattenedFormData = flattenFormData({
                ...formData,
                [performedBreastSurgeryDescriptionField.key]: concatenatedDescription,
                performed_breast_surgery_date: breastSurgeryDate // Asegúrate de incluir el campo aquí
            }, 'mamoplastia');  // Asegúrate de pasar el nombre del procedimiento aquí

            const body = {
                eps: " ",
                height: height,
                weight,
                number_children: sons ? ages : [],
                previous_surgery: haveSurgery ? whichSurgery : "No",
                major_disease: haveIllness ? whichIllness : "No",
                drugs: drugsConsume ? whichDrugs : "No",
                drink_medication: consumeMedicaments ? whichMedicaments : "No",
                allergic_medication: youAlergic ? whichAlergic : "No",
                type_procedure: " ",
                smoke: smoke ? "Si" : "No",
                alcohol: drinkAlcohol ? "Si" : "No",
                name: userDetail.name,
                email: userDetail.email,
                nacionality: nacionality,
                date_operation: formatToISO(operationDate),
                birth_date: userDetail?.birth_date,
                form: flattenedFormData,
                specialtie_id: dataDoc.id,
                clinic_id: packageMedicData.clinic_id,
                //procedure_id: procedureData?.id || null,
                user_id: userDetail?.id || null,
                specialist: {
                    name_specialist: dataDoc.name,
                    email_specialist: dataDoc.email,
                    category: dataDoc.categories[0].name,
                    specialties: dataDoc.specialties.map(({ specialty }) => ({
                        specialty: specialty || ''
                    }))
                },
                //    clinic: {
                //       clinic_name: dataPackage.clinic.name,
                //     clinic_address: dataPackage.clinic.address,
                //   clinic_description: dataPackage.clinic.description,
                // },


            }

            // TODO: Eliminar URLs del código y pasarlas al archivo .env como una variable
            const urlPackage = `https://crmedic.pdtclientsolutions.com/api/wellezy/store/package`

            const url = `${process.env.REACT_APP_URL_API_FINANMED}/store/std`
            await axios.post(url, body)
            createLinkPay();
            await axios.post(urlPackage)

            setSons(false)
            setAges([])

        } catch (error) {
            console.error(error)
        }
    }


    return (
        <div className="formMedicalPackage">
            <Toaster position="top-center" reverseOrder={false} />
            {
                loading &&
                <Loading />
            }
            <div className='formTitle'>
                <div>
                    <legend>{t("packs.health.detailed_pack.reserve_modal.stdForm.title")} </legend>
                    <p>{t("packs.health.detailed_pack.reserve_modal.stdForm.subtitle")}</p>
                </div>
                <div>
                    <IoClose onClick={handleClose} />
                </div>
            </div>


            <div className={"formSeparationInfo"}>
                <div className="formSeparationItem">
                    <div className="formTitleItem">
                        <h4>1. {t("fligth.form_passenger.step.person_data")}</h4>
                        <p>{t("packs.health.detailed_pack.reserve_modal.stdForm.complete_form")}</p>
                    </div>

                    <div className={"formItemsData"}>
                        <div className='camp-two-check-column'>
                            <p>
                                <span>{t("packs.health.detailed_pack.reserve_modal.stdForm.sr/sra")}: </span>
                                {userDetail?.name}
                            </p>
                            <p>
                                <span>{t("fligth.table_passengers.email")}: </span>
                                {userDetail?.email}
                            </p>
                            <p>
                                <span>{t("fligth.table_passengers.date")}: </span>
                                {userDetail?.birth_date && formatYYYYMMDDToDDMMYYYY(userDetail?.birth_date)}
                            </p>
                            <p>
                                <span>{t("fligth.table_passengers.phone")}: +</span>{userDetail?.prex_phone_public} {userDetail?.phone_public}
                            </p>
                        </div>

                        <div className='formTitleItemAnyDescription'>
                            <p>{t("tourism.detailed_tour.additional_information")}</p>
                        </div>

                        <div className="camp-one">
                            <InputNacionalityStd
                                nacionalityDropdown={nacionalityDropdown}
                                setNacionalityDropdown={setNacionalityDropdown}
                                nacionality={nacionality}
                                validationForm={validation}
                                paceholders={paceholders}
                                findCountryClient={findCountryClient}
                                loading={loadinNa}
                                dataNacionality={dataNacionality}
                                selectedNacionality={selectedNacionality}
                            />
                        </div>


                        {fields.map((field, index) => (
                            field.show && (
                                <div key={index} className="formItemText">
                                    <label>{field.label} <span style={{ color: 'red' }}>*</span></label>
                                    <input
                                        type={field.type === 'number' ? 'text' : field.type}
                                        placeholder={field.placeholder}
                                        className={!field.value && validation ? 'obligatory' : ""}
                                        value={field.value}
                                        onChange={field.type === 'number' ? handleNumberInputChange(field.setter) : handleInputChange(field.setter)}
                                    />
                                </div>
                            )
                        ))}

                        <div className="camp-two" ref={calendarRef}>
                            <div className="right">
                                <label htmlFor="operationDate">{t("packs.health.detailed_pack.reserve_modal.stdForm.date_operation")}<span style={{ color: 'red' }}> *</span></label>
                                <DateInputWithPicker
                                    inputId="operationDate"
                                    selectedDate={selectedOperationDate}
                                    setSelectedDate={setSelectedOperationDate}
                                    setOperationDate={setOperationDate}
                                    isBirthday={false}
                                    placeholder="dd/mm/yyyy"
                                    icon={() => <LuCalendarPlus color="var(--blueLightTransparent)" size={17} />}
                                    hasButton={false}
                                    validationForm={validation}
                                />
                            </div>
                        </div>





                        <div className="camp-three">
                            <div className="center">
                                <label htmlFor="">{t("packs.health.detailed_pack.reserve_modal.stdForm.height")}<span style={{ color: 'red' }}> *</span></label>
                                <input
                                    type="number"
                                    placeholder='PJ 1.60'
                                    className={!height && validation === true ? 'obligatory' : ""}
                                    value={height}
                                    onChange={(e) => setHeight(e.target.value)}
                                />
                            </div>
                            <div className="right">
                                <label htmlFor="">{t("packs.health.detailed_pack.reserve_modal.stdForm.weight")}<span style={{ color: 'red' }}> *</span></label>
                                <input
                                    type="number"
                                    placeholder='PJ 60Kg'
                                    className={!weight && validation === true ? 'obligatory' : ""}
                                    value={weight}
                                    onChange={(e) => setWeight(e.target.value)}
                                />
                            </div>
                        </div>
                    </div>
                </div>

                <div className="formSeparationItem">
                    <div className="formTitleItemAnyDescription">
                        <p>{t("packs.health.detailed_pack.reserve_modal.stdForm.important_question")}</p>
                    </div>

                    <div className="formItemsCheckboxes">
                        <div className="camp-two-check-column">
                            <div className="divOptions">
                                <span>
                                    {t("packs.health.detailed_pack.yes")}
                                </span>
                                <span>
                                    {t("specialist.booking_flow.no")}
                                </span>
                            </div>
                            <div className="checkboxCont">
                                <div className="divcheck">
                                    <input
                                        type="radio"
                                        id="drinkYes"
                                        name="drinkAlcohol"
                                        checked={drinkAlcohol === true}
                                        onChange={() => setDrinkAlcohol(true)}
                                    />
                                </div>
                                <div className="divcheck">
                                    <input
                                        type="radio"
                                        id="drinkNo"
                                        name="drinkAlcohol"
                                        checked={drinkAlcohol === false}
                                        onChange={() => setDrinkAlcohol(false)}
                                    />
                                </div>
                                <label htmlFor="">{t("build_package.steps.std.form.important_questions.drink_alcohol")}</label>
                            </div>
                        </div>

                        <div className='camp-two-check-column'>
                            <div className="checkboxCont">
                                <div className="divcheck">
                                    <input
                                        type="radio"
                                        id="smokeYes"
                                        name="smoke"
                                        checked={smoke === true}
                                        onChange={() => {
                                            setSmoke(true);
                                        }}
                                    />
                                </div>
                                <div className="divcheck">
                                    <input
                                        type="radio"
                                        id="smokeNo"
                                        name="smoke"
                                        checked={smoke === false}
                                        onChange={() => {
                                            setSmoke(false);
                                        }}
                                    />
                                </div>
                                <label htmlFor="">{t("build_package.steps.std.form.important_questions.smoke")}</label>
                            </div>
                        </div>



                        <div className='camp-two-check-middle'>
                            <div className="checkboxCont">
                                <div className="divcheck">
                                    <input
                                        type="radio"
                                        id="sonsYes"
                                        name="sons"
                                        checked={sons === true}
                                        onChange={() => {
                                            setSons(true);
                                            setQuantityChild("");
                                            setAges([]);
                                        }}
                                    />
                                </div>
                                <div className="divcheck">
                                    <input
                                        type="radio"
                                        id="sonsNo"
                                        name="sons"
                                        checked={sons === false}
                                        onChange={() => {
                                            setSons(false);
                                            setQuantityChild("");
                                            setAges([]);
                                        }}
                                    />
                                </div>
                                <label htmlFor="sons">{t("build_package.steps.std.form.important_questions.have_sons")}</label>
                            </div>

                            {/* Renderiza el campo para cantidad de hijos si se selecciona "Sí" */}
                            {sons && (
                                <div className='camp-middle mb-4 mt-3'>
                                    <label className="titleQuantityChild">{t("build_package.steps.std.form.important_questions.how_many")} *</label>
                                    <input
                                        type="number"
                                        id="quantityChild"
                                        min="1"
                                        max="30"
                                        placeholder={t("build_package.steps.std.form.important_questions.have_sons_placeholder")}
                                        className={!quantityChild && validation === true ? "obligatory" : ""}
                                        value={quantityChild}
                                        onChange={(e) => {
                                            let count = parseInt(e.target.value, 10);

                                            if (count > 30) count = 30;
                                            if (count < 1) count = 1;

                                            setQuantityChild(count);

                                            setAges((prevAges) => {
                                                if (count > prevAges.length) {
                                                    return [...prevAges, ...Array(count - prevAges.length).fill("")];
                                                } else {
                                                    return prevAges.slice(0, count);
                                                }
                                            });
                                        }}
                                    />
                                </div>
                            )}

                            {sons && quantityChild > 0 && (
                                <div
                                    className={`relative max-h-52 overflow-y-scroll w-[48%] border border-gray-300 p-4 rounded-lg bg-gray-50 customScroll`}
                                >
                                    {/* Botón para expandir/colapsar */}
                                    <button
                                        type="button"
                                        className="absolute top-2 right-2 text-sm text-blue-500 underline focus:outline-none"
                                        onClick={() => setShowAges(!showAges)}
                                    >
                                        {showAges ?
                                            <button className={`${"buttonArrow"} ${"buttonArrowOpen"}`} type="button">
                                                <BiSolidRightArrow size={20} />
                                            </button>
                                            :
                                            <div className="flex gap-2">
                                                <span>
                                                    {t("build_package.steps.std.form.important_questions.show_ages")}
                                                </span>
                                                <button className="buttonArrow" type="button">
                                                    <BiSolidRightArrow size={20} />
                                                </button>
                                            </div>
                                        }
                                    </button>

                                    {showAges && (
                                        <>
                                            <label htmlFor="ages" className="block text-sm font-semibold mb-2">
                                                {t("build_package.steps.std.form.important_questions.enter_ages")} <span className="text-red-500">*</span>
                                            </label>
                                            {ages.map((age, index) => (
                                                <div key={index} className="mt-5 mb-4 inputSonsForm">
                                                    <label
                                                        htmlFor={`age-${index + 1}`}
                                                        className="block text-sm font-medium text-gray-700 mb-2 w-fit"
                                                    >
                                                        {t("build_package.steps.std.form.important_questions.children")} {index + 1}:
                                                    </label>
                                                    <div className="flex gap-2">
                                                        <input
                                                            type="number"
                                                            id={`age-${index + 1}`}
                                                            placeholder={`Edad del hijo ${index + 1}`}
                                                            value={age.split(" ")[0] || ""}
                                                            min="1"
                                                            max="100"
                                                            className={`mt-1 block w-full border rounded-md shadow-sm border-gray-300 focus:ring-blue-500 focus:border-blue-500 sm:text-sm ${!age && validation ? "obligatory" : !age.split(" ")[0] && validation ? "obligatory" : ""
                                                                }`}
                                                            onChange={(e) => {
                                                                const value = parseInt(e.target.value, 10);
                                                                const unit = age.toString().split(" ")[1] || "años";
                                                                setAges((prevAges) =>
                                                                    prevAges.map((item, i) =>
                                                                        i === index
                                                                            ? `${value > 100 ? 100 : value < 1 ? 1 : value} ${unit}` // Rango 1-100
                                                                            : item
                                                                    )
                                                                );

                                                            }}
                                                        />
                                                        {/* Select para unidad (años/meses) */}
                                                        <select
                                                            className="w-[100px] mt-1 block border border-gray-300 rounded-md shadow-sm focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
                                                            value={age.split(" ")[1] || "años"}
                                                            onChange={(e) => {
                                                                const unit = e.target.value;
                                                                const value = age.toString().split(" ")[0] || "";
                                                                setAges((prevAges) =>
                                                                    prevAges.map((item, i) =>
                                                                        i === index ? `${value} ${unit}` : item
                                                                    )
                                                                );
                                                            }}
                                                        >
                                                            <option value="años">{t("build_package.steps.std.form.important_questions.years")}</option>
                                                            <option value="meses">{t("build_package.steps.std.form.important_questions.months")}</option>
                                                        </select>
                                                    </div>
                                                    {(!age || !age.split(" ")[0]) && validation && (
                                                        <p className="text-red-500 text-[12px] font-[400]">
                                                            {t("build_package.steps.std.form.important_questions.ages_error")}
                                                        </p>
                                                    )}

                                                </div>
                                            ))}
                                        </>
                                    )}
                                </div>
                            )}
                        </div>

                        <div className='camp-two-check-middle'>
                            <div className="checkboxCont">
                                <div className="divcheck">
                                    <input
                                        type="radio"
                                        id="haveSurgeryYes"
                                        name="haveSurgery"
                                        checked={haveSurgery === true}
                                        onChange={() => {
                                            setHaveSurgery(true);
                                        }}
                                    />
                                </div>
                                <div className="divcheck">
                                    <input
                                        type="radio"
                                        id="haveSurgeryNo"
                                        name="haveSurgery"
                                        checked={haveSurgery === false}
                                        onChange={() => {
                                            setHaveSurgery(false);
                                        }}
                                    />
                                </div>
                                <label htmlFor="">{t("build_package.steps.std.form.important_questions.surgery")}</label>
                            </div>
                            {
                                haveSurgery &&
                                <div className='camp-middle'>
                                    <label htmlFor="" className="titleQuantityChild">{t("build_package.steps.std.form.important_questions.previous_surgery")} *</label>
                                    <input
                                        type="text"
                                        placeholder={i18n.language === "en" ? "example: Liposuction, Mastopexy" : "ejemplo: Liposucción, Mastopexia"}
                                        className={!whichSurgery && validation === true ? "obligatory" : ""}
                                        value={whichSurgery}
                                        onChange={(e) => setWhichSurgery(e.target.value)}
                                    />
                                </div>
                            }
                        </div>


                        <div className='camp-two-check-middle'>
                            <div className="checkboxCont">
                                <div className="divcheck">
                                    <input
                                        type="radio"
                                        id="haveIllnessYes"
                                        name="haveIllness"
                                        checked={haveIllness === true}
                                        onChange={() => {
                                            setHaveIllness(true);
                                        }}
                                    />
                                </div>
                                <div className="divcheck">
                                    <input
                                        type="radio"
                                        id="haveIllnessNo"
                                        name="haveIllness"
                                        checked={haveIllness === false}
                                        onChange={() => {
                                            setHaveIllness(false);
                                        }}
                                    />
                                </div>
                                <label htmlFor="">{t("build_package.steps.std.form.important_questions.important_illness")}</label>
                            </div>
                            {
                                haveIllness &&
                                <div className='camp-middle'>
                                    <label htmlFor="" className="titleQuantityChild">{t("build_package.steps.std.form.important_questions.what_illness")} *</label>
                                    <input
                                        type="text"
                                        id="whichIllness"
                                        className={!whichIllness && validation === true ? "obligatory" : ""}
                                        value={whichIllness}
                                        onChange={(e) => setWhichIllness(e.target.value)}
                                    />
                                </div>
                            }
                        </div>


                        <div className='camp-two-check-middle'>
                            <div className="checkboxCont">
                                <div className="divcheck">
                                    <input
                                        type="radio"
                                        id="drugsConsumeYes"
                                        name="drugsConsume"
                                        checked={drugsConsume === true}
                                        onChange={() => {
                                            setDrugsConsume(true);
                                        }}
                                    />
                                </div>
                                <div className="divcheck">
                                    <input
                                        type="radio"
                                        id="drugsConsumeNo"
                                        name="drugsConsume"
                                        checked={drugsConsume === false}
                                        onChange={() => {
                                            setDrugsConsume(false);
                                        }}
                                    />
                                </div>
                                <label htmlFor="">{t("build_package.steps.std.form.important_questions.any_narcotics")}</label>
                            </div>
                            {
                                drugsConsume &&
                                <div className='camp-middle'>
                                    <label htmlFor="" className="titleQuantityChild">{t("build_package.steps.std.form.important_questions.what_illness")} *</label>
                                    <input
                                        type="text"
                                        id="whichDrugs"
                                        className={!whichDrugs && validation === true ? "obligatory" : ""}
                                        value={whichDrugs}
                                        onChange={(e) => setWhichDrugs(e.target.value)}
                                    />
                                </div>
                            }
                        </div>



                        <div className='camp-two-check-middle'>
                            <div className="checkboxCont">
                                <div className="divcheck">
                                    <input
                                        type="radio"
                                        id="consumeMedicamentsYes"
                                        name="consumeMedicaments"
                                        checked={consumeMedicaments === true}
                                        onChange={() => {
                                            setConsumeMedicaments(true);
                                        }}
                                    />
                                </div>
                                <div className="divcheck">
                                    <input
                                        type="radio"
                                        id="consumeMedicamentsNo"
                                        name="consumeMedicaments"
                                        checked={consumeMedicaments === false}
                                        onChange={() => {
                                            setConsumeMedicaments(false);
                                        }}
                                    />
                                </div>
                                <label htmlFor="">{t("build_package.steps.std.form.important_questions.any_medication")}</label>
                            </div>
                            {
                                consumeMedicaments &&
                                <div className='camp-middle'>
                                    <label htmlFor="" className="titleQuantityChild">{t("build_package.steps.std.form.important_questions.what_medication")} *</label>
                                    <input
                                        type="text"
                                        id="whichMedicaments"
                                        className={!whichMedicaments && validation === true ? "obligatory" : ""}
                                        value={whichMedicaments}
                                        onChange={(e) => setWhichMedicaments(e.target.value)}
                                    />
                                </div>
                            }
                        </div>


                        <div className='camp-two-check-middle'>
                            <div className="checkboxCont">
                                <div className="divcheck">
                                    <input
                                        type="radio"
                                        id="youAlergicKnowedYes"
                                        name="youAlergicKnowed"
                                        checked={youAlergicKnowed === true}
                                        onChange={() => {
                                            setYouAlergicKnowed(true);
                                        }}
                                    />
                                </div>
                                <div className="divcheck">
                                    <input
                                        type="radio"
                                        id="youAlergicKnowedNo"
                                        name="youAlergicKnowed"
                                        checked={youAlergicKnowed === false}
                                        onChange={() => {
                                            setYouAlergicKnowed(false);
                                        }}
                                    />
                                </div>
                                <label htmlFor="">{t("build_package.steps.std.form.important_questions.any_alergy")}</label>
                            </div>
                            {
                                youAlergicKnowed &&
                                <div className='camp-middle'>
                                    <label htmlFor="" className="titleQuantityChild">{t("build_package.steps.std.form.important_questions.what_alergy")} *</label>
                                    <input
                                        type="text"
                                        id="whichAlergicKnowed"
                                        className={!whichAlergicKnowed && validation === true ? "obligatory" : ""}
                                        value={whichAlergicKnowed}
                                        onChange={(e) => setWhichAlergicKnowed(e.target.value)}
                                    />
                                </div>
                            }
                        </div>

                        <div className='camp-two-check-middle'>
                            <div className="checkboxCont">
                                <div className="divcheck">
                                    <input
                                        type="radio"
                                        id="youAlergicYes"
                                        name="youAlergic"
                                        checked={youAlergic === true}
                                        onChange={() => {
                                            setYouAlergic(true);
                                        }}
                                    />
                                </div>
                                <div className="divcheck">
                                    <input
                                        type="radio"
                                        id="youAlergicNo"
                                        name="youAlergic"
                                        checked={youAlergic === false}
                                        onChange={() => {
                                            setYouAlergic(false);
                                        }}
                                    />
                                </div>
                                <label htmlFor="" >{t("build_package.steps.std.form.important_questions.is_alergic")}</label>
                            </div>
                            {
                                youAlergic &&
                                <div className='camp-middle'>
                                    <label htmlFor="" className="titleQuantityChild">{t("build_package.steps.std.form.important_questions.what_illness")} *</label>
                                    <input
                                        type="text"
                                        id="whichAlergic"
                                        className={!whichAlergic && validation === true ? "obligatory" : ""}
                                        value={whichAlergic}
                                        onChange={(e) => setWhichAlergic(e.target.value)}
                                    />
                                </div>
                            }

                        </div>


                    </div>
                </div>
            </div>


            <div className="previewDate">
                <div className='topButtonOpenCollaps' onClick={() => setOpenCollapsibe(!openCollapsible)}>
                    <div>
                        <BiSolidRightArrow size={20} className={openCollapsible ? 'arrowDown' : ''} />
                    </div>
                    <div>
                        <h3>
                            2. {procedureName === "Mamoplastia" && i18n.language === "en" ? "Mammoplasty" : procedureName}
                        </h3>
                    </div>
                </div>

                {openCollapsible && (
                    <Grid container spacing={8}>
                        <Grid item xs={12} sm={12} md={6}>
                            {/*Primeros dos campos de preguntas en mamoplastia */}
                            {formsFilter?.[0]?.formulario
                                ?.filter((field) => field.type === 'text')
                                ?.slice(0, 2)
                                .map((field) => (
                                    <div key={field.key} className="formItemText">
                                        <label>{field.label} <span style={{ color: 'red' }}>*</span></label>
                                        <input
                                            type="text"
                                            className={!formData[field.key] && validation ? 'obligatory' : ""}
                                            value={formData[field.key] || ''}
                                            onChange={(e) =>
                                                setFormData((prev) => ({
                                                    ...prev,
                                                    [field.key]: e.target.value,
                                                }))
                                            }
                                        />
                                    </div>
                                ))
                            }

                            <div className='checksDivForm'>
                                {firstCheckboxField && (
                                    <div key={firstCheckboxField.key} className="checkboxContNew checkboxContColumn">
                                        <h4>{firstCheckboxField.label} <span style={{ color: 'red' }}>*</span></h4>
                                        {firstCheckboxField.options?.map((option) => {
                                            const checked = formData[firstCheckboxField.key]?.[option.key] || false;
                                            return (
                                                <label key={option.key}>
                                                    <input
                                                        type="checkbox"
                                                        checked={checked}
                                                        onChange={(e) => {
                                                            setFormData((prev) => ({
                                                                ...prev,
                                                                [firstCheckboxField.key]: {
                                                                    ...(prev[firstCheckboxField.key] || {}),
                                                                    [option.key]: e.target.checked,
                                                                },
                                                            }));
                                                        }}
                                                    />
                                                    <p>{option.label}</p>
                                                </label>
                                            );
                                        })}
                                    </div>
                                )}
                            </div>

                            {formData[firstCheckboxField.key]?.others && (
                                <div className="formItemText">
                                    <label>{t("build_package.steps.std.form.important_questions.mammoplasty.goal.others_placeholder")} <span style={{ color: 'red' }}>*</span></label>
                                    <input
                                        type="text"
                                        className={!formData['which_others'] && validation ? 'obligatory' : ""}
                                        value={formData['which_others'] || ''}
                                        onChange={(e) =>
                                            setFormData((prev) => ({
                                                ...prev,
                                                'which_others': e.target.value,
                                            }))
                                        }
                                    />
                                </div>
                            )}

                            {performedBreastSurgeryField && (
                                <div className="labelYesOrNoForm">
                                    <label>
                                        <p>Sí</p>
                                        <input
                                            type="radio"
                                            name={performedBreastSurgeryField.key}
                                            checked={formData[performedBreastSurgeryField.key] === true}
                                            onChange={() =>
                                                setFormData((prev) => ({
                                                    ...prev,
                                                    [performedBreastSurgeryField.key]: true,
                                                }))
                                            }
                                        />
                                    </label>
                                    <label>
                                        <p>No</p>
                                        <input
                                            type="radio"
                                            name={performedBreastSurgeryField.key}
                                            checked={formData[performedBreastSurgeryField.key] === false}
                                            onChange={() =>
                                                setFormData((prev) => ({
                                                    ...prev,
                                                    [performedBreastSurgeryField.key]: false,
                                                }))
                                            }
                                        />
                                    </label>
                                    <h4>{performedBreastSurgeryField.label} <span style={{ color: 'red' }}>*</span></h4>
                                </div>

                            )}
                            {formData[performedBreastSurgeryField?.key] === true && performedBreastSurgeryDescriptionField && (
                                <div className="formItemText formItemTextDate">
                                    <label>{performedBreastSurgeryDescriptionField.label} <span style={{ color: 'red' }}>*</span></label>
                                    <div className="formItemText formItemTextDate">
                                        <input
                                            type="text"
                                            className={!formData[performedBreastSurgeryDescriptionField.key] && validation ? 'obligatory' : ""}
                                            value={formData[performedBreastSurgeryDescriptionField.key] || ''}
                                            onChange={(e) => {
                                                const value = e.target.value;
                                                const date = breastSurgeryDate ? ` ${breastSurgeryDate}` : '';
                                                setFormData((prev) => ({
                                                    ...prev,
                                                    [performedBreastSurgeryDescriptionField.key]: value + date,
                                                }));
                                            }}
                                        />
                                    </div>

                                    <div className="formItemTextDateInput">
                                        <label style={{ marginBottom: '10px' }}>{t("build_package.steps.std.form.important_questions.mammoplasty.surgery_date")} <span style={{ color: 'red' }}>*</span></label>
                                        <DateInputWithPicker
                                            inputId="breastSurgeryDate"
                                            selectedDate={selectedBreastSurgeryDate}
                                            setSelectedDate={setSelectedBreastSurgeryDate}
                                            setOperationDate={(date) => {
                                                setBreastSurgeryDate(date);
                                                setFormData((prev) => ({
                                                    ...prev,
                                                    performed_breast_surgery_date: date,
                                                }));
                                            }}
                                            isBirthday={false}
                                            placeholder="dd/mm/yyyy"
                                            icon={() => <LuCalendarPlus color="var(--blueLightTransparent)" size={17} />}
                                            hasButton={false}
                                            validationForm={validation}
                                        />
                                    </div>
                                </div>
                            )}

                            {formData[performedBreastSurgeryField?.key] === true && knowImplantPlaceField && (
                                <div className="formItemSelect" style={{ marginTop: '10px' }}>
                                    <h4>{knowImplantPlaceField.label} <span>*</span> </h4>
                                    <select
                                        className={!formData[knowImplantPlaceField.key] && validation ? 'obligatory' : ""}
                                        value={formData[knowImplantPlaceField.key] || ''}
                                        onChange={(e) =>
                                            setFormData((prev) => ({
                                                ...prev,
                                                [knowImplantPlaceField.key]: e.target.value,
                                            }))
                                        }
                                    >
                                        <option value="">{t("packs.health.detailed_pack.reserve_modal.stdForm.select_option")}</option>
                                        {knowImplantPlaceField.options.map((opt) => (
                                            <option key={opt} value={opt}>
                                                {opt}
                                            </option>
                                        ))}
                                    </select>
                                </div>
                            )}

                            <div className='bottomStdHealt none900'>
                                <div className='fotosFormBottom'>
                                    <h3>{t("build_package.steps.std.form.important_questions.required_photos")} ({procedureName === "Mamoplastia" && i18n.language === "en" ? "Mammoplasty" : procedureName}) <span style={{ color: 'red' }}>*</span></h3>
                                    <p>{t("build_package.steps.std.form.important_questions.required_photos_indications")}</p>
                                    <button onClick={handleOpenPhotos}>
                                        {t("build_package.steps.std.form.important_questions.attach_required_photos")} * <BsCheckCircleFill size={17} />
                                    </button>
                                </div>

                                {(() => {
                                    const uploadedCount = uploadedImages[procedureName] ? Object.keys(uploadedImages[procedureName]).length : 0;
                                    const requiredImagesCount = formsFilter[0].require_images.length - uploadedCount;
                                    const requiredImagesText = requiredImagesCount > 1
                                        ? t("build_package.steps.std.form.important_questions.images_to_continue", { count: requiredImagesCount })
                                        : t("build_package.steps.std.form.important_questions.image_to_continue", { count: requiredImagesCount });

                                    if (requiredImagesCount === 0) {
                                        return (
                                            <p style={{ color: "green", marginBottom: '10px' }}>
                                                {t("build_package.steps.std.form.important_questions.selected_images_successfully")}
                                            </p>
                                        );
                                    } else {
                                        return (
                                            FormErrors[procedureName]?.require_images && (
                                                <p style={{ color: "var(--redPrimary)", marginBottom: '10px' }}>
                                                    {requiredImagesText}
                                                </p>
                                            )
                                        );
                                    }
                                })()}

                                <div className='bottomFormNote'>
                                    <h4>{t("build_package.steps.std.form.important_questions.note.text")}:</h4>
                                    <p>{t("build_package.steps.std.form.important_questions.note.first_description")} <br />
                                        {t("build_package.steps.std.form.important_questions.note.second_description")}</p>
                                </div>

                                <div className='checkTerms'>
                                    <input type="checkbox" id='confirm' className={!terms && validation === true ? 'obligatory' : ""}
                                        checked={terms}
                                        onChange={() => {
                                            setTerms(!terms)
                                        }}
                                    />
                                    <label htmlFor='confirm'>
                                        {t("build_package.steps.std.form.important_questions.note.first_part_terms")} <span>{t("build_package.steps.std.form.important_questions.note.second_part_terms")}</span>
                                    </label>
                                </div>

                                <button
                                    className='buttonCont'
                                    disabled={!terms}
                                    style={{ opacity: !terms ? "0.5" : "1" }}
                                    onClick={sendStd}
                                >
                                    {t("build_package.steps.std.form.important_questions.note.continue_button")}
                                </button>
                            </div>
                        </Grid>

                        <Grid item xs={12} sm={12} md={6} >


                            {secondCheckboxField && (
                                <div className="checkboxContNew checkboxContColumn">
                                    <h4>{secondCheckboxField.label} <span style={{ color: 'red' }}>*</span></h4>
                                    {secondCheckboxField.options?.map((option) => {
                                        const checked = formData[secondCheckboxField.key]?.[option.key] || false;
                                        return (
                                            <label key={option.key}>
                                                <input
                                                    type="checkbox"
                                                    checked={checked}
                                                    onChange={(e) => {
                                                        setFormData((prev) => ({
                                                            ...prev,
                                                            [secondCheckboxField.key]: {
                                                                ...(prev[secondCheckboxField.key] || {}),
                                                                [option.key]: e.target.checked,
                                                            },
                                                        }));
                                                    }}
                                                />
                                                <p>{option.label}</p>
                                            </label>
                                        );
                                    })}
                                </div>
                            )}

                            {somethingImportantField && (
                                <div className="formItemText">
                                    <label>{somethingImportantField.label} <span style={{ color: 'red' }}>*</span></label>
                                    <input
                                        type="text"
                                        className={!formData[somethingImportantField.key] && validation ? 'obligatory' : ""}
                                        value={formData[somethingImportantField.key] || ''}
                                        onChange={(e) =>
                                            setFormData((prev) => ({
                                                ...prev,
                                                [somethingImportantField.key]: e.target.value,
                                            }))
                                        }
                                    />
                                </div>
                            )}

                            {someoneRecommendedUsField && (
                                <div className="labelYesOrNoForm">
                                    <label>
                                        <p>Sí</p>
                                        <input
                                            type="radio"
                                            name={someoneRecommendedUsField.key}
                                            checked={formData[someoneRecommendedUsField.key] === true}
                                            onChange={() =>
                                                setFormData((prev) => ({
                                                    ...prev,
                                                    [someoneRecommendedUsField.key]: true,
                                                }))
                                            }
                                        />
                                    </label>
                                    <label>
                                        <p>No</p>
                                        <input
                                            type="radio"
                                            name={someoneRecommendedUsField.key}
                                            checked={formData[someoneRecommendedUsField.key] === false}
                                            onChange={() =>
                                                setFormData((prev) => ({
                                                    ...prev,
                                                    [someoneRecommendedUsField.key]: false,
                                                }))
                                            }
                                        />
                                    </label>
                                    <h4>{someoneRecommendedUsField.label} <span style={{ color: 'red' }}>*</span></h4>
                                </div>
                            )}

                            {formData[someoneRecommendedUsField?.key] === true && recommendDescriptionField && (
                                <div className="formItemText">
                                    <label>{recommendDescriptionField.label} <span style={{ color: 'red' }}>*</span></label>
                                    <input
                                        type="text"
                                        value={formData[recommendDescriptionField.key] || ''}
                                        onChange={(e) =>
                                            setFormData((prev) => ({
                                                ...prev,
                                                [recommendDescriptionField.key]: e.target.value,
                                            }))
                                        }
                                    />
                                </div>
                            )}

                            <div className='bottomStdHealt show900'>
                                <div className='fotosFormBottom'>
                                    <h3>{t("build_package.steps.std.form.important_questions.required_photos")} ({procedureName === "Mamoplastia" && i18n.language === "en" ? "Mammoplasty" : procedureName}) <span style={{ color: 'red' }}>*</span></h3>
                                    <p>{t("build_package.steps.std.form.important_questions.required_photos_indications")}</p>
                                    <button onClick={handleOpenPhotos}>
                                        {t("build_package.steps.std.form.important_questions.attach_required_photos")} * <BsCheckCircleFill size={17} />
                                    </button>
                                </div>

                                {(() => {
                                    const uploadedCount = uploadedImages[procedureName] ? Object.keys(uploadedImages[procedureName]).length : 0;
                                    const requiredImagesCount = formsFilter[0].require_images.length - uploadedCount;
                                    const requiredImagesText = requiredImagesCount > 1
                                        ? t("build_package.steps.std.form.important_questions.images_to_continue", { count: requiredImagesCount })
                                        : t("build_package.steps.std.form.important_questions.image_to_continue", { count: requiredImagesCount });

                                    if (requiredImagesCount === 0) {
                                        return (
                                            <p style={{ color: "green", marginBottom: '10px' }}>
                                                {t("build_package.steps.std.form.important_questions.selected_images_successfully")}
                                            </p>
                                        );
                                    } else {
                                        return (
                                            FormErrors[procedureName]?.require_images && (
                                                <p style={{ color: "var(--redPrimary)", marginBottom: '10px' }}>
                                                    {requiredImagesText}
                                                </p>
                                            )
                                        );
                                    }
                                })()}

                                <div className='bottomFormNote'>
                                    <h4>{t("build_package.steps.std.form.important_questions.note.text")}:</h4>
                                    <p>{t("build_package.steps.std.form.important_questions.note.first_description")} <br />
                                        {t("build_package.steps.std.form.important_questions.note.second_description")}</p>
                                </div>

                                <div className='checkTerms'>
                                    <input type="checkbox" id='confirm' className={!terms && validation === true ? 'obligatory' : ""}
                                        checked={terms}
                                        onChange={() => {
                                            setTerms(!terms)
                                        }}
                                    />
                                    <label htmlFor='confirm'>
                                        {t("build_package.steps.std.form.important_questions.note.first_part_terms")} <span>{t("build_package.steps.std.form.important_questions.note.second_part_terms")}</span>
                                    </label>
                                </div>

                                <button
                                    className='buttonCont'
                                    disabled={!terms}
                                    style={{ opacity: !terms ? "0.5" : "1" }}
                                    onClick={sendStd}
                                >
                                    {t("build_package.steps.std.form.important_questions.note.continue_button")}
                                </button>
                            </div>

                        </Grid>
                    </Grid>
                )}

            </div>

            <PhotoUploadDialog
                open={openCreatePhotos}
                handleClose={handleClosePhotos}
                procedureName={procedureName}
                formsFilter={formsFilter}
                uploadedImages={uploadedImages}
                setUploadedImages={setUploadedImages}
                setFormErrors={setFormErrors}
                FormErrors={FormErrors}
                setFormData={setFormData}
            />

        </div>
    );
};

export default ReservationForm;


