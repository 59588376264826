import React, { useState, useEffect } from "react";
import SvgIcon, { iconList } from "../../../share/components/iconSVG";
import { useTranslation } from "react-i18next";
import "./styles.css";
import { IoIosArrowDown } from "react-icons/io";
import { FaCircleCheck } from "react-icons/fa6";
import toast, { Toaster } from "react-hot-toast";

const PersonForm = ({
  index,
  type,
  setPaxes,
  paxes,
  setValidate,
  setSubmitData,
  submitData,
}) => {
  const [sex, setSex] = useState("");
  const [accordion, setAccordion] = useState(
    index === 1 && type === "adult" ? true : false
  );
  const [nom, setNom] = useState("");
  const [email, setEmail] = useState("");
  const [tel, setTel] = useState("");
  const [lastName, setLastName] = useState("");
  const [address, setAddress] = useState("");
  const [hotel, setHotel] = useState("");
  const [flightNumber, setFlightNumber] = useState("");
  const [age, setAge] = useState("");
  const { t } = useTranslation("global");
  const [addHErr, setAddHErr] = useState(false);
  const [checkSuccess, setCheckSuccess] = useState(false);
  const [typeDocument, setTypeDocument] = useState("CI");
  const [Document, setDocument] = useState("");
  const [modalTypeDoc, setModalTypeDoc] = useState(false);
  const listTypeDoc = ["Pas", "CI", "TI", "CE", "PPT"];

  // verificaciones
  const [nomVerify, setNomVerify] = useState(false);
  const [lastNameVerify, setLastNameVerify] = useState(false);
  const [flightVerify, setFlightVerify] = useState(false);
  const [emailVerify, setEmailVerify] = useState(false);
  const [telVerify, setTelVerify] = useState(false);
  const [addressVerify, setAddressVerify] = useState(false);
  const [documentVerify, setDocumentVerify] = useState(false);
  const [hotelVerify, setHotelVerify] = useState(false);

  const notify = (title, icon) =>
    toast(title, {
      icon: icon,
    });



  const addHuesped = () => {
    // Crear un objeto que mapea las variables de estado con sus funciones de verificación
    const campos = {
      nom: setNomVerify,
      lastName: setLastNameVerify,
      flightNumber: setFlightVerify,
      email: setEmailVerify,
      tel: setTelVerify,
      address: setAddressVerify,
      Document: setDocumentVerify,
      hotel: setHotelVerify,
    };

    let hayError = false;

    // Verificar cada campo y actualizar el estado de verificación si está vacío
    Object.entries(campos).forEach(([campo, fnVerificacion]) => {
      if (!campos[campo]) {
        fnVerificacion(true);
        hayError = true;
      }
    });


    // Si algún campo está vacío, establecer el estado de error y sale de la funcion
    if (hayError) {
      setAddHErr(true);
      notify(t("transfers.error"), "❌")
      return false;
    }

    function validateEmail(email) {
      const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
      return emailPattern.test(email);
    }

    if (!validateEmail(email)) {
      setEmailVerify(true)
      notify(t("transfers.invalid_email"), "❌")
      return false
    }

    // Si todos los campos están llenos, proceder a añadir el huésped
    setAddHErr(false);
    setPaxes((prevList) => [
      ...prevList,
      {
        name: nom,
        lastname: lastName,
        email: email,
        tel: tel,
        doc: Document,
        hotel: hotel,
        address: address,
        flightNumber: flightNumber,
      },
    ]);
    setAccordion(false);
    setCheckSuccess(true);
    return true;
  };

  useEffect(() => {
    if (submitData) {
      setSubmitData(false);

      // Ejecuta la función y guarda el resultado
      setValidate(addHuesped());
    }
  }, [submitData]);

  return (
    <div>
      <section
        className="accordion-detail-form-hotel"
        style={{
          backgroundColor: `${paxes.length > 0 ? "var(--greenPrimary)" : ""}`,
        }}
        onClick={() => {
          setAccordion(!accordion);
        }}
      >
        <div>
          <h2>
            {type === "child" ? t("fligth.form_passenger.step.children") : t("fligth.form_passenger.step.adult")} {index}
          </h2>
          {index === 1 && type === "adult" && (
            <p>{t("transfers.responsible")}</p>
          )}
        </div>
        <span className="circle-icon-accordion">
          {!accordion ? (
            <IoIosArrowDown color={"var(--white)"} size={20} />
          ) : (
            <IoIosArrowDown
              style={{ rotate: "180deg" }}
              color={"var(--white)"}
              size={20}
            />
          )}
        </span>
      </section>

      <div className="form-persons-pay-trans">
        <div className="camp-form-person-hotel">
          <label htmlFor="">{t("fligth.form_passenger.step.inputs.name.title")} *</label>
          <input
            type="text"
            style={{
              borderColor: nomVerify && "var(--red)",
            }}
            onChange={(e) => {
              setNomVerify(false);
              setNom(e.target.value);
            }}
            placeholder={
              index === 1 && type === "adult"
                ? t("hotels.reserve.fields.responsible.name.placeholder")
                : t("hotel.name")
            }
          />
          {nomVerify && (
            <p
              style={{
                color: "var(--red)",
                fontSize: "12px",
              }}
            >
              {t("hotels.reserve.required_field")}
            </p>
          )}
        </div>
        <div className="camp-form-person-hotel">
          <label htmlFor="">{t("hotels.reserve.fields.responsible.last_name.label")} *</label>
          <input
            style={{
              borderColor: lastNameVerify && "var(--red)",
            }}
            type="text"
            onChange={(e) => {
              setLastNameVerify(false);
              setLastName(e.target.value);
            }}
            placeholder={
              index === 1 && type === "adult"
                ? t("hotels.reserve.fields.responsible.last_name.placeholder")
                : t("hotel.last_name")
            }
          />
          {lastNameVerify && (
            <p
              style={{
                color: "var(--red)",
                fontSize: "12px",
              }}
            >
              {t("hotels.reserve.required_field")}
            </p>
          )}
        </div>
        {index === 1 && type === "adult" && (
          <div className="camp-form-person-hotel-doc">
            <label htmlFor="">{t("hotels.reserve.fields.responsible.document.label")} *</label>
            <div className="contain-inputs-selecteds">
              <div className="select-type-doc">
                <div
                  className="select-onclick-modal-type"
                  onClick={() => {
                    setModalTypeDoc(!modalTypeDoc);
                  }}
                >
                  <span style={{ fontSize: "12px" }}>{typeDocument}</span>
                  <SvgIcon
                    color={"var(--greySecundary)"}
                    name={iconList.IconArrowDown}
                    size={15}
                  />
                </div>
                {modalTypeDoc && (
                  <div className="modal-select-type-doc">
                    <ul>
                      {listTypeDoc.map((item, index) => {
                        return (
                          <li
                            key={index}
                            onClick={() => {
                              setTypeDocument(item);
                              setModalTypeDoc(false);
                            }}
                          >
                            {item}
                          </li>
                        );
                      })}
                    </ul>
                  </div>
                )}
              </div>
              <div className="c-input-selected">
                <input
                  type="number"
                  style={{
                    borderColor: documentVerify && "var(--red)",
                  }}
                  onChange={(e) => {
                    setDocumentVerify(false);
                    setDocument(e.target.value);
                  }}
                  placeholder={
                    index === 1 && type === "adult"
                      ? t("hotels.reserve.fields.responsible.document.placeholder")
                      : ""
                  }
                />
                {documentVerify && (
                  <p
                    style={{
                      color: "var(--red)",
                      fontSize: "12px",
                      marginTop: "5px",
                    }}
                  >
                    {t("hotels.reserve.required_field")}
                  </p>
                )}
              </div>
            </div>
          </div>
        )}

        {index === 1 && type === "adult" && (
          <>
            <div className="camp-form-person-hotel">
              <label htmlFor="">{t("hotels.reserve.fields.responsible.phone.label")} *</label>
              <input
                type="number"
                style={{
                  borderColor: telVerify && "var(--red)",
                }}
                onChange={(e) => {
                  setTelVerify(false);
                  setTel(e.target.value);
                }}
                placeholder={
                  index === 1 ? t("hotels.reserve.fields.responsible.phone.placeholder") : t("hotel.phone")
                }
              />
              {telVerify && (
                <p
                  style={{
                    color: "var(--red)",
                    fontSize: "12px",
                  }}
                >
                  {t("hotels.reserve.required_field")}
                </p>
              )}
            </div>
            <div className="camp-form-person-hotel">
              <label htmlFor="">Email *</label>
              <input
                style={{
                  borderColor: emailVerify && "var(--red)",
                }}
                type="email"
                value={email}
                onChange={(e) => {
                  setEmailVerify(false);
                  setEmail(e.target.value);
                }}
                placeholder={index === 1 ? t("hotels.reserve.fields.responsible.email.placeholder") : "Email"}
              />
              {emailVerify && (
                <p
                  style={{
                    color: "var(--red)",
                    fontSize: "12px",
                  }}
                >
                  {t("hotels.reserve.required_field")}
                </p>
              )}
            </div>
          </>
        )}
        <div className="camp-form-person-hotel">
          <label htmlFor="">{t("fligth.form_passenger.step.inputs.address.title")} *</label>
          <input
            type="text"
            style={{
              borderColor: addressVerify && "var(--red)",
            }}
            onChange={(e) => {
              setAddressVerify(false);
              setAddress(e.target.value);
            }}
            placeholder={t("fligth.form_passenger.step.inputs.address.place_holder")}
          />
          {addressVerify && (
            <p
              style={{
                color: "var(--red)",
                fontSize: "12px",
              }}
            >
              {t("hotels.reserve.required_field")}
            </p>
          )}
        </div>
        <div className="camp-form-person-hotel">
          <label htmlFor="">Hotel *</label>
          <input
            style={{
              borderColor: hotelVerify && "var(--red)",
            }}
            type="text"
            onChange={(e) => {
              setHotelVerify(false);
              setHotel(e.target.value);
            }}
            placeholder={"Hotel"}
          />
          {hotelVerify && (
            <p
              style={{
                color: "var(--red)",
                fontSize: "12px",
              }}
            >
              {t("hotels.reserve.required_field")}
            </p>
          )}
        </div>
        <div className="camp-form-person-hotel">
          <label htmlFor="">{t("transfers.flight_number")} *</label>
          <input
            style={{
              borderColor: flightVerify && "var(--red)",
            }}
            type="text"
            onChange={(e) => {
              setFlightVerify(false);
              setFlightNumber(e.target.value);
            }}
            placeholder={t("transfers.flight_number_placeholder")}
          />
          {flightVerify && (
            <p
              style={{
                color: "var(--red)",
                fontSize: "12px",
              }}
            >
              {t("hotels.reserve.required_field")}
            </p>
          )}
        </div>
      </div>
    </div>
  );
};

export default PersonForm;
