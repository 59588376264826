import React, { useEffect, useRef, useState } from "react";
import AdminPaymentCards from "../../ui/views/profileUser/components/adminPaymentCards";
import PaymentCards from "./PaymentCardsView/PaymentCardView";
import AppointmentRequests from "../../ui/views/profileUser/components/sections/appointmentRequests";
import DetailBuy from "../TerrawindVoucherDetail/DetailBuy/DetailBuy";
import axios from "axios";
import Find from "../../ui/views/share/components/Find/Find";
import CarouselSevices from "../../ui/views/share/components/CarouselServices/CarouselSevices";
import AnyQuestions from "../../ui/views/home/components/anyQuestion";
import ActivityIndicator from "../../ui/views/share/components/activityIndicator";
import InputDocument from "./InputDocument/InputDocument";

import { useTranslation } from "react-i18next";
import { useLocation, useNavigate } from "react-router-dom";
import "./Assets/styles.css";
import InputSimple from "../TerrawindVoucherDetail/FormDataPassenger/InputSimple/InputSimple";
import ReturnPage from "../../ui/views/share/components/return/ReturnPage";

function TerrawindPaymentCard() {
  const location = useLocation();
  const dataString = decodeURIComponent(location.pathname.split("/")[4]);
  const data = JSON.parse(dataString);
  const env = process.env.REACT_APP_URL_ENV;

  let urlWellezy = process.env.REACT_APP_URL_API_WELLEZY;
  let url = process.env.REACT_APP_URL_API_AIOP;

  const [selectedCard, setSelectedCard] = useState(null);
  const [profileuser, setProfileUser] = useState(null);
  const [validateCard, setValidateCard] = useState(false);
  const [modal, setModal] = useState(false);
  const [loading, setLoading] = useState(false);
  const [errorPay, setErrorPay] = useState(false);
  const [names, setNames] = useState("");
  const [lastNames, setLastNames] = useState("");
  const [document, setDocument] = useState("");
  const [documentTypeId, setDocumentTypeId] = useState("");
  const [phone, setPhone] = useState("");
  const [email, setEmail] = useState("");
  const [validate, setValidate] = useState("");

  const [t] = useTranslation("global");

  const navigate = useNavigate();


  const details = [
    { title: "Plan", value: data[1].data.plan },
    { title: "Total", value: data[1].data.price },
    { title: "Origen", value: data[1].data.origin },
    { title: "Destino", value: data[1].data.destination },
    { title: "Salida", value: data[1].data.dateOrigin },
    { title: "Regreso", value: data[1].data.dateDestination },
    { title: "Vieajero/s", value: data[1].data.passengers },
  ];

  const handleClickOutside = (event) => {
    event.stopPropagation();
    setModal(false);
  };

  const handleClickedInside = (event) => {
    event.stopPropagation();
  };

  // async function pay() {
  //   const amount = parseFloat(data[1].data.price.replace(/,/g, ""));
  //   const token =
  //     selectedCard === null || selectedCard === undefined
  //       ? ""
  //       : selectedCard.token;
  //   const useId = profileuser === undefined ? "" : profileuser.id.toString();
  //   const email = profileuser === undefined ? "" : profileuser.email;
  //   const cardId =
  //     selectedCard === null || selectedCard === undefined
  //       ? ""
  //       : selectedCard.id;

  //   if (token === "" || useId === "" || email === "") {
  //     setValidateCard(true);
  //     return;
  //   }

  //   setModal(true);
  //   setLoading(true);

  //   const dataToPay = {
  //     token: token,
  //     user_id: useId,
  //     email: email,
  //     amount: amount,
  //     description: data[1].data.plan,
  //     dev_reference: data[1].data.productId,
  //     vat: 0,
  //     card_id: cardId,
  //   };

  //   console.log("dataToPay", dataToPay);

  //   try {
  //     const url = "https://staging.aiop.com.co/api/pay/card";
  //     const response = await axios.post(url, dataToPay);
  //     console.log(response);
  //     if (response.data.pay.error) {
  //       console.log("error");
  //       setLoading(false);
  //       setErrorPay(true);
  //       return;
  //     }
  //     if (response.statusText === "OK") {
  //       const dataPay = response.data;
  //       const dataForm = data[0];
  //       const dataFormPay = [{ dataPay }, { dataForm }];
  //       const transactionData = encodeURIComponent(JSON.stringify(dataFormPay));
  //       navigate(`/travelSecure/terrawind/generateVoucher/${transactionData}`);
  //       window.scrollTo(0, 0);
  //     }
  //   } catch (error) {
  //     console.log("error", error);
  //   }
  // }

  async function payPSE() {
    const nameUser = data[0].formData[0].passenger_first_name;
    const lastName = data[0].formData[0].passenger_last_name;
    const document = data[0].formData[0].passenger_document_number;
    const email = data[0].formData[0].passenger_email;
    const phone = data[0].formData[0].passenger_phone;
    const description = data[1].data.plan;
    const amount = parseFloat(data[1].data.price.replace(/,/g, ""));
    const dataForm = data[0];
    const dataFormVoucher = encodeURIComponent(JSON.stringify(dataForm));

    if (document === "" || email === "") {
      setValidateCard(true);
      return;
    }

    if(!validateData()){
      return;
    } 

    setModal(true);
    setLoading(true);

    try{
      const response = await axios.post(`${url}api/terrawind/generate-voucher`, dataForm.formData)

      if(response.status === 200){
        const dataToPay = {
          "name": names,
          "lastname": lastNames,
          "document": document,
          "email": email,
          "cel": phone,
          "localizator": `${response.data.id}`,
          "type": "Terrawind",
          "currency":"COP",
          "price": amount,
          "url": `${urlWellezy}travelSecure/terrawind/generateVoucher`
        };

        try{
          const responseDataPay = await axios.post(`${url}api/place-to-pay/`,dataToPay);
 
          
          localStorage.setItem("requestId", responseDataPay.data.requestId);
          localStorage.setItem("formData", dataFormVoucher);
          localStorage.setItem("product", data[1].data.plan);
          localStorage.setItem("amount", data[1].data.price);
    
           if(responseDataPay.status === 200){
             window.location.href = responseDataPay.data.processUrl
           }else{
             setLoading(false);
             setErrorPay(true);
           }
        }catch{
          setLoading(false);
          setErrorPay(true);
        }
      }
    }catch{
      setLoading(false);
      setErrorPay(true);
    }
  }

  const validateData = () => {
    if(names === "" || names.length < 3 || /\d/.test(names)){
      setValidate("names");
      return false;
    }else if(lastNames === "" || lastNames.length < 3 || /\d/.test(lastNames)){
      setValidate("lastNames");
      return false;
    }else if (documentTypeId === "3" && isNaN(document)) {
      setValidate("document");
      return false;
    }else if(/[\s\W]/.test(document) || document.trim() === "" || document.length < 5 || documentTypeId.trim() === ""){
      setValidate("document");
      return false;
    }else if(!/\S+@\S+\.\S+/.test(email) || email.trim() === ""){
      setValidate("email");
      return false;
    }else if(isNaN(phone)){
      setValidate("phoneText");
      return false;
    }else if(phone === "" || phone.length < 8){
      setValidate("phone");
      return false
    }else{
      setValidate("")
    }

    return true;
  }

  useEffect(() => {
    validateData();
  }, [names, lastNames, document, documentTypeId, phone, email]);

  return (
    <div>
  
      <CarouselSevices current={t("carousel_services.travel_secure")} />
      <ReturnPage/>
      <div className="container-payment-card">
        <div className="credit-cards">
          <div className="title-method-pay">
            <p>Selecciona el metodo de pago</p>
          </div>
          <div className="text-description">
            <p>Ingresa la información para tu metodo de pago</p>
          </div>
          <div className="title-method-pay">
            <p>Metodos de pago</p>
          </div>
          <div className="container-payment-person">
            <div className="row-data-payment-person">
              <div className={ validate === "names" ? "validate-input-voucher": null}>
                <InputSimple label="Nombres" onInputChange={setNames}/>
                { validate === "names" ? <p className="span-simple-error">El campo es requerido</p> : null}
              </div>
              <div className={validate === "lastNames" ? "validate-input-voucher": null}>
                <InputSimple label="Apellidos" onInputChange={setLastNames}/>
                { validate === "lastNames" ? <p className="span-simple-error">El campo es requerido</p> : null}
              </div>
              <div>
                <InputDocument  onInputChange={setDocument} validate={validate === "document"? true : false} setDocumentTypeId={setDocumentTypeId}/>
              </div>
            </div>
            <div className="row-data-payment-person-second">
              <div className={validate === "email" ? "validate-input-voucher": null}>
                <InputSimple label="Correo" onInputChange={setEmail}/>
                { validate === "email" ? <p className="span-simple-error">El campo es requerido</p> : null}
              </div>
              <div className={validate === "phone" || validate === "phoneText"? "validate-input-voucher": null}>
                <InputSimple label="Telefono" onInputChange={setPhone}/>
                { validate === "phone"  || validate === "phoneText"? <p className="span-simple-error">{validate === "phone" ? "El campo es requerido": validate === "phoneText" ? "El campo debe ser numerico": null}</p> : null}
              </div>
              <div></div>
            </div>
          </div>
          {/* <div>
            <PaymentCards
              setSelectedCard={setSelectedCard}
              setProfileUser={setProfileUser}
              setValidateCard={setValidateCard}
            />
          </div>
          <div>
            {validateCard ? (
              <p
                className="validate-credit-card"
                style={{ color: "red", fontSize: "11px", marginBlock: "10px" }}
              >
                Selecciona una tarjeta de crédito
              </p>
            ) : null}
          </div>
          <div className="installments-payment">
            <p>
              *Pago a plazos sólo disponible para algunas tarjetas de crédito.
            </p>
          </div> */}
          <div className="btn-confirm-buy">
            {/* <button>Confirmar compra y continuar</button> */}
            <button onClick={payPSE}>Pagar con PSE</button>
          </div>
        </div>
        <DetailBuy colorTitles={false} details={details} />
      </div>
      <AnyQuestions title={t("browse_home.have_doubt")} />
      {modal ? (
        <div
          className="container-modal-method-pay"
        >
          {loading ? (
            <div
              className="modal-loading-method-pay"
            >
              <ActivityIndicator colorFill="#004274" size={40} />
            </div>
          ) : errorPay ? (
            <div
              className="modal-loading-method-pay"
              onClick={handleClickedInside}
              style={{ padding: "20px" }}
            >
              <p>Error al hacer el pago</p>
              <p style={{ fontWeight: "400", fontSize: "12px", textAlign: "center" }}>Solo se acepta documentos de tipo numerico al viajero que hara el pago.</p>
              <button
                className="btn-button-accept-error-method-pay"
                onClick={handleClickOutside}
              >
                Aceptar
              </button>
            </div>
          ) : null}
        </div>
      ) : null}
    </div>
  );
}

export default TerrawindPaymentCard;
