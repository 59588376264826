import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import CarouselSevices from '../share/components/CarouselServices/CarouselSevices';
import "./styles.css"
import pqrs from "./assets/question.svg"
import girl from "./assets/girl.png"
import mail from "./assets/mail.svg"
import whassap from "./assets/whassap.svg"
import televentas from "./assets/televentas.svg"
import contact from "./assets/contact.svg"
import DisabledModal from '../share/components/disabledModal/disabledModal';
import ReturnPage from '../share/components/return/ReturnPage';
import ContactWellezy from "@/Components/ContactWellezy/ContactWellezy";
import AnyQuestions from "../home/components/anyQuestion";

const Contact = () => {
  const { t, i18n } = useTranslation("global");

  const cards = [
    {
      title: t("contact.banner.title"),
      img: girl,
      text: (
        <p>
          {t("contact.banner.first_description")}
          <br />
          <br /> 
          {t("contact.banner.second_description")}
        </p>
      ),
      type: "big",
    },
    {
      title: t("contact.frequent_questions.title"),
      img: pqrs,
      text: <p>{t("contact.frequent_questions.description")}</p>,
      button: t("contact.frequent_questions.button_text"),
      url: "/pqrs",
      disable: true,
    },
    {
      title: t("contact.telesales.title"),
      img: televentas,
      text: (
        <p>
          {t("contact.telesales.first_description")}
          <br />
          <br />
          {t("contact.telesales.second_description")}
        </p>
      ),
      button: t("contact.telesales.button_text"),
      url: "https://wa.link/oy8353",
      disable: true,
      linkExit: true,
    },
    {
      title: t("contact.whatsapp.title"),
      img: whassap,
      text: (
        <p>{t("contact.whatsapp.description")}</p>
      ),
      button: t("contact.whatsapp.button_text"),
      url: "https://wa.link/oy8353",
      disable: true,
      linkExit: true,
    },
    {
      title: t("contact.we_contact_you.title"),
      img: contact,
      text: <p>{t("contact.we_contact_you.description")}</p>,
      button: t("contact.we_contact_you.button_text"),
      url: "https://wa.link/oy8353",
      disable: true,
      linkExit: true,
    },
  ];

  useEffect(() => {
    setTimeout(() => {
      window.scrollTo({
        top: 0,
        behavior: "smooth",
      });
    }, 100);
  }, []);

  return (
    <>
      <CarouselSevices />
      <ReturnPage />
      <div className="contain-card-contact">
        {cards.map((card, index) => {
          const item = {
            route: card.url,
            disable: card.disable,
          };
          return (
            <>
              {card.type === "big" ? (
                <div className="card-big">
                  <div className="infor">
                    <h2>{card.title}</h2>

                    {card.text}
                  </div>
                  <img src={card.img} alt="" />
                </div>
              ) : (
                <div className="card-small">
                  <div className="heade">
                    <img src={card.img} alt="" />
                    <h2>{card.title}</h2>
                  </div>
                  <div className="core">{card.text}</div>
                  <button className="foo">
                    <DisabledModal
                      item={item}
                      active={item.disable}
                      color={"white"}
                      linkExit={card?.linkExit ? true : false}
                    >
                      {card.button}
                    </DisabledModal>
                  </button>
                </div>
              )}
            </>
          );
        })}
      </div>

      <div className="offerst-mail">
        <div className="cont-input">
          <img src={mail} alt="" />
          <div>
            <h2>{t("contact.exclusive_offers.title")}</h2>
            <input type="text" placeholder={
              i18n.language === "es" ? "Ingresa tu email" : "Enter your email"
            } />
            <p>
              {t("contact.exclusive_offers.first_description")} 
              <b>{t("contact.exclusive_offers.second_description")}</b>
            </p>
          </div>

          <button>
            <DisabledModal
              item={{ route: "#", disable: false }}
              active={false}
              color={"white"}
            >
              {t("contact.exclusive_offers.button_text")}
            </DisabledModal>
          </button>
        </div>
      </div>
      <AnyQuestions title={t("browse_home.have_doubt")} />
    </>
  );
};

export default Contact;
