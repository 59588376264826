import React, { useState, useEffect, useRef } from "react";
import "./styles.css";
import { MdClose } from "react-icons/md";
import { RiFilterFill } from "react-icons/ri";
import DoubleRangeSlider from "./components/range/RangeFilter";
import TimeRangeFilter from "./components/timeRange/TimeRangeFilter";
import { is } from "date-fns/locale";
import TextComponent from "../../../../../share/components/fonts/TextComponent";
import { Slider } from "@mui/material";
import { currencyFormat } from "@/ui/utils";
import { useTranslation } from "react-i18next";


const FlightFilters = ({
    companies,
    nameOrigin,
    nameDestiny,
    isRoundTrip,
    Seg1,
    Seg2,
    allPassengersSelected,
    onFiltersChange,
    priceMax,
    priceMin,
    price,
    hour0Seg1,
    hour1Seg1,
    hour0Seg2,
    hour1Seg2,
    searcherFlight,
    directFlight,
    setDirectFlight,
    dollarFlight,
    setDollarFlight,
}) => {
    const { t, i18n } = useTranslation("global");
    const rangePriceFilterRef = useRef(null);
    const typeOfCoin = { name: dollarFlight, abbreviation: dollarFlight, simbol: '$' };
    const [isMobile, setIsMobile] = useState(window.innerWidth < 770);
    const [isOpenFilters, setIsOpenFilters] = useState(false);
    const [filter, setFilter] = useState(true);
    const [isRoundTripFlight, setIsRoundTripFlight] = useState(isRoundTrip);
    const [range, setRange] = useState([priceMin, priceMax]);
    const [rangePriceMin, setRangePriceMin] = useState(priceMin);
    const [rangePriceMax, setRangePriceMax] = useState(priceMax);
    const [resetPrice, setResetPrice] = useState(false);
    const [resetTime, setResetTime] = useState(false);

    const [timeHour0, setTimeHour0] = useState([hour0Seg1, hour1Seg1]);

    const [aeroLines, setAeroLines] = useState(companies);
    const [newAeroLines, setNewAeroLines] = useState(companies);
    const [selectedAirline, setSelectedAirline] = useState("");
    const [executiveFlight, setExecutiveFlight] = useState(false);
    console.log(companies)
    // const [directFlight, setDirectFlight] = useState(
    //   () => localStorage.getItem("directFlight") === "true"
    // );

    // const [dollarFlight, setDollarFlight] = useState(
    //   () => localStorage.getItem("dollarFlight") === "true"
    // );

    const [typeFlightWithBags, setTypeFlightWithBags] = useState(null);

    const marks = [
        {
            value: rangePriceMin,
            label: `$${currencyFormat(rangePriceMin, typeOfCoin).total}`,
        },
        {
            value: rangePriceMax,
            label: `$${currencyFormat(rangePriceMax, typeOfCoin).total}`,
        },
    ];

    function valueText(value) {
        return `${value}`;
    }



    // const AirlineFetch = async () => {
    //     try {
    //         const responses = await Promise.all(
    //             companies?.map((company) =>
    //                 fetch("https://travelflight.pdtcomunicaciones.com/api/airline/show", {
    //                     method: "POST",
    //                     headers: {
    //                         "Content-Type": "application/json",
    //                     },
    //                     body: JSON.stringify({ code: company }),
    //                 }).then((response) => {
    //                     if (!response.ok) {
    //                         throw new Error("Network response was not ok");
    //                     }
    //                     return response.json();
    //                 })
    //             )
    //         );
    //         const combinedData = responses.flat();
    //         setNewAeroLines(combinedData);
    //     } catch (error) {
    //         console.error("Error fetching airlines:", error);
    //     }
    // };

    const handleResize = () => {
        if (window.innerWidth < 770) {
            setIsMobile(true);
            setFilter(false);
        } else {
            setIsMobile(false);
            setFilter(true);
            setIsOpenFilters(false); // Cierra el modal cuando no es móvil
        }
    };

    useEffect(() => {
        // AirlineFetch();
        handleResize();
        setIsRoundTripFlight(isRoundTrip);
        window.addEventListener("resize", handleResize);

        return () => {
            window.removeEventListener("resize", handleResize);
        };
    }, []);

    const handleRangeChange = (event, newRange) => {
        setRange(newRange);
        sendFiltersToParent({ range: newRange });
        setResetPrice(false);
    };

    const handleRangeChangeR = (event, newRange) => {
        setRange(newRange);
        setRangePriceMin(newRange[0])
        setRangePriceMax(newRange[1])
    };

    const handleRangeTimeChange = (newRange) => {
        setTimeHour0(newRange);
        sendFiltersToParent({ timeRange: newRange });
        setResetTime(false);
    };

    const handleIsRoundTripFilter = (value) => {
        setIsRoundTripFlight(value);
        sendFiltersToParent({ isRoundTrip: value });
    };

    const handleAerolineChange = (event) => {
        const airline = event.target.value;
        setSelectedAirline(airline);
        sendFiltersToParent({ airline: airline });
    };

    const handleTypesWithBagsChange = (event) => {
        const bagsCount = event.target.checked
            ? parseInt(event.target.value, 10)
            : 0;
        setTypeFlightWithBags(bagsCount);
        sendFiltersToParent({ typeFlightWithBags: bagsCount });
    };

    const handleExecutiveFlightChange = (event) => {
        const executive = event.target.checked;
        setExecutiveFlight(executive);
        sendFiltersToParent({ executiveFlight: executive });
    };

    const handleDirectFlightChange = (event) => {
        const direct = event.target.checked;
        localStorage.setItem("directFlight", JSON.stringify(direct));
        setDirectFlight(direct);
        sendFiltersToParent({ directFlight: direct });
        searcherFlight(direct, dollarFlight);
    };

    const handleDollarFlightChange = (value, type) => {
        if (value) {
            localStorage.setItem("dollarFlight", "COP");
            setDollarFlight(type);
            sendFiltersToParent({ dollarFlight: type });
            searcherFlight(directFlight, type);
        } else {
            localStorage.setItem("dollarFlight", "COP");
            setDollarFlight("COP");
            sendFiltersToParent({ dollarFlight: "COP" });
            searcherFlight(directFlight, "COP");
        }
    };

    const cleanFilters = () => {
        setRange([priceMin, priceMax]);
        setResetPrice(true);
        setResetTime(true);

        setRangePriceMin(priceMin);
        setRangePriceMax(priceMax);
        setTimeHour0([0, 1440]);
        setSelectedAirline("");
        setTypeFlightWithBags(null);
        setExecutiveFlight(false);
        setDirectFlight(false);
        localStorage.setItem("directFlight", JSON.stringify(false));
        setDollarFlight("COP");
        localStorage.setItem("dollarFlight", "COP");
        sendFiltersToParent({
            range: [priceMin, priceMax],
            timeRange: [0, 1440],
            airline: "",
            typeFlightWithBags: null,
            executiveFlight: false,
            directFlight: false,
            dollarFlight: "COP",
        });
        searcherFlight(directFlight, "COP")
    };

    const sendFiltersToParent = (updatedFilters) => {
        const newFilters = {
            ...filter,
            ...updatedFilters,
        };
        onFiltersChange(newFilters);
    };

    return (
        <>
            {isMobile && (
                <button
                    onClick={() => setIsOpenFilters(true)}
                    className="modalButtonFilter"
                >
                    <RiFilterFill size={20} /> {t("fligth.flight_filters.filter_flights")}
                </button>
            )}

            <div
                className={`${isMobile && !filter
                    ? isOpenFilters
                        ? "containerFlightFiltersModal"
                        : "containerFlightFiltersModal close"
                    : "containerFlightFilters"
                    }`}
                onClick={(e) => {
                    if (e.currentTarget === e.target) {
                        setIsOpenFilters(false);
                    }
                }}
            >
                <div
                    className={`${isMobile && !filter
                        ? isOpenFilters
                            ? "containerFlightFiltersContentModal"
                            : "containerFlightFiltersContentModal close"
                        : "containerFlightFiltersContent"
                        }`}
                >
                    {isMobile && isOpenFilters && (
                        <button
                            onClick={() => setIsOpenFilters(false)}
                            className="containerFlightFiltersCloseModal"
                        >
                            <MdClose size={25} />
                        </button>
                    )}
                    <TextComponent
                        tag={"h3"}
                        children={t("fligth.flight_filters.filters")}
                        size={"md"}
                        fontWeight="bold"
                    />

                    <div className="containerFlightFiltersOptionFlight">
                        <div className="containerFlightFiltersOptionFlightTitle">
                            <TextComponent
                                tag={"h3"}
                                children={t("fligth.flight_filters.price")}
                                size={"md"}
                                fontWeight="bold"
                            />
                        </div>

                        <div className="rangeRadioFilter">
                            <TextComponent
                                tag={"p"}
                                children={t("fligth.flight_filters.range_price")}
                                size={"normal"}
                                fontWeight="light"
                                color="var(--bluePrimary)"
                            />
                            {/* <DoubleRangeSlider
                label="Rango de precios"
                min={rangePriceMin}
                max={rangePriceMax}
                value={range}
                onChange={handleRangeChange}
                reset={resetPrice}
                currency={dollarFlight}
              /> */}
                            <Slider
                                getAriaLabel={() => "Temperature range"}
                                value={range}
                                onChange={handleRangeChangeR}
                                onChangeCommitted={handleRangeChange}
                                max={priceMax}
                                min={priceMin}
                                valueLabelDisplay="auto"
                                getAriaValueText={valueText}
                                marks={marks}
                                sx={{
                                    '& .MuiSlider-markLabel': {
                                        fontSize: '10px', // Ajustar el tamaño de los labels a 10px
                                        fontWeight: "600",
                                        color: 'var(--bluePrimary)'
                                    },
                                    color: 'var(--bluePrimary)', // Usar la variable CSS para el color principal
                                    '& .MuiSlider-thumb': {
                                        backgroundColor: 'var(--bluePrimary)', // Usar la variable para el thumb
                                        width: "18px",
                                        height: "18px",
                                    },
                                    '& .MuiSlider-markLabel[data-index="0"]': {
                                        left: '15% !important', // Ajuste de la posición para el index 0
                                    },
                                    '& .MuiSlider-markLabel[data-index="1"]': {
                                        left: '85% !important', // Ajuste de la posición para el index 1
                                    },
                                    '& .MuiSlider-rail': {
                                        color: '#bdbdbd', // Puedes dejar este color o personalizarlo
                                    },
                                    '& .MuiSlider-track': {
                                        color: 'var(--bluePrimary)', // Usar la variable para la pista activa
                                    },
                                }}
                            />

                        </div>
                    </div>

                    <div className="containerFlightFiltersOptionFlight">
                        <div className="containerFlightFiltersOptionFlightTitle">
                            <TextComponent
                                tag={"h3"}
                                children={t("fligth.flight_filters.schedules")}
                                size={"md"}
                                fontWeight="bold"
                            />
                        </div>

                        {isRoundTripFlight === "false" ? (
                            <TimeRangeFilter
                                onChange={handleRangeTimeChange}
                                label={t("fligth.flight_filters.range_one_way")}
                                min={hour0Seg1}
                                max={hour1Seg1}
                                reset={resetTime}
                            />
                        ) : (
                            <div
                                style={{ display: "flex", flexDirection: "column", gap: 30 }}
                            >
                                <TimeRangeFilter
                                    onChange={handleRangeTimeChange}
                                    label={t("fligth.flight_filters.range_one_way")}
                                    min={hour0Seg1}
                                    max={hour1Seg1}
                                    reset={resetTime}
                                />

                                <TimeRangeFilter
                                    onChange={handleRangeTimeChange}
                                    label={t("fligth.flight_filters.range_roundtrip")}
                                    min={hour0Seg2}
                                    max={hour1Seg2}
                                    reset={resetTime}
                                />
                            </div>
                        )}

                    </div>

                    <div className="containerFlightFiltersOptionFlight">
                        <div className="containerFlightFiltersOptionFlightTitle">
                            <TextComponent
                                tag={"h3"}
                                children={t("fligth.flight_filters.airlines")}
                                size={"md"}
                                fontWeight="bold"
                            />
                        </div>

                        <div className="rangeRadioFilter">
                            <div className="radio-container">
                                <label
                                    htmlFor="All"
                                    className="optionRadioButton"
                                    style={{
                                        display: "flex",
                                        alignItems: "start",
                                        gap: "10px",
                                    }}
                                >
                                    <input
                                        className="hidden"
                                        type="radio"
                                        id="All"
                                        name="viaje"
                                        value=""
                                        checked={selectedAirline === ""}
                                        onChange={handleAerolineChange}
                                    />
                                    <p>{t("fligth.flight_filters.see_all")}</p>
                                </label>
                            </div>
                        </div>

                        {newAeroLines?.map((company, index) => {
                            // Check if the company object is not empty
                            if (company) {
                                return (
                                    <div className="rangeRadioFilter" key={index}>
                                        <div className="radio-container">
                                            <label
                                                htmlFor={index}
                                                className="optionRadioButton hover:underline"
                                                style={{
                                                    display: "flex",
                                                    alignItems: "start",
                                                    gap: "10px",
                                                    cursor: "pointer",
                                                }}
                                            >
                                                <input
                                                    className="hidden"
                                                    type="radio"
                                                    id={index}
                                                    name="viaje"
                                                    value={company}
                                                    checked={selectedAirline === company}
                                                    onChange={handleAerolineChange}
                                                />
                                                {company}
                                            </label>
                                        </div>
                                    </div>
                                );
                            }
                            // Return null if the company object is empty or lacks necessary properties
                            return null;
                        })}
                    </div>

                    <div className="containerFlightFiltersOptionFlight">
                        <div className="containerFlightFiltersOptionFlightTitle">
                            <TextComponent
                                tag={"h3"}
                                children={t("fligth.flight_filters.flights_type")}
                                size={"md"}
                                fontWeight="bold"
                            />
                        </div>

                        <div className="rangeRadioFilter">
                            <div className="radio-container">
                                <label
                                    htmlFor="flightsWithBags"
                                    className="optionRadioButton"
                                    style={{
                                        display: "flex",
                                        alignItems: "center",
                                        gap: "6px",
                                    }}
                                >
                                    <input
                                        className="hidden"
                                        type="checkbox"
                                        id="flightsWithBags"
                                        name="flightsWithBags"
                                        value={1}
                                        checked={typeFlightWithBags === 1}
                                        onChange={handleTypesWithBagsChange}
                                    />
                                    <p>{t("fligth.flight_filters.flights_with_bags")}</p>
                                </label>
                            </div>
                        </div>

                        {/* <div className="rangeRadioFilter">
              <div className="radio-container">
                <label
                  htmlFor="ExecutiveFlight"
                  className="optionRadioButton"
                  style={{
                    display: "flex",
                    alignItems: "center",
                    gap: "6px",
                  }}
                >
                  <input
                    className="hidden"
                    type="checkbox"
                    id="ExecutiveFlight"
                    name="ExecutiveFlight"
                    value={1}
                    checked={executiveFlight}
                    onChange={handleExecutiveFlightChange}
                  />
                  Vuelos clase ejecutiva
                </label>
              </div>
            </div> */}

                        <div className="rangeRadioFilter">
                            <div className="radio-container">
                                <label
                                    htmlFor="DirectFlight"
                                    className="optionRadioButton"
                                    style={{
                                        display: "flex",
                                        alignItems: "center",
                                        gap: "6px",
                                    }}
                                >
                                    <input
                                        className="hidden"
                                        type="checkbox"
                                        id="DirectFlight"
                                        name="DirectFlight"
                                        value={1}
                                        checked={directFlight}
                                        onChange={handleDirectFlightChange}
                                    />
                                    <p>{t("fligth.flight_filters.direct_flights")}</p>
                                </label>
                            </div>
                        </div>
                        <div className="containerFlightFiltersOptionFlight">
                            <div className="containerFlightFiltersOptionFlightTitle">
                                <TextComponent
                                    tag={"h3"}
                                    children={t("fligth.flight_filters.type_of_coins")}
                                    size={"md"}
                                    fontWeight="bold"
                                />
                            </div>

                            <div className="rangeRadioFilter">
                                <div className="radio-container">
                                    <label
                                        htmlFor="DollarFlight"
                                        className="optionRadioButton"
                                        style={{
                                            display: "flex",
                                            alignItems: "center",
                                            gap: "6px",
                                        }}
                                    >
                                        <input
                                            className="hidden"
                                            type="radio"
                                            id="DollarFlight"
                                            name="DollarFlight"
                                            value={1}
                                            checked={dollarFlight === "USD"}
                                            onChange={(e) => {
                                                handleDollarFlightChange(e.target.checked, "USD");
                                            }}
                                        />
                                        <p>{t("fligth.flight_filters.dollar_prices")}</p>
                                    </label>
                                </div>
                            </div>
                            <div className="rangeRadioFilter">
                                <div className="radio-container">
                                    <label
                                        htmlFor="EurosFlight"
                                        className="optionRadioButton"
                                        style={{
                                            display: "flex",
                                            alignItems: "center",
                                            gap: "6px",
                                        }}
                                    >
                                        <input
                                            className="hidden"
                                            type="radio"
                                            id="EurosFlight"
                                            name="EurosFlight"
                                            value={1}
                                            checked={dollarFlight === "EUR"}
                                            onChange={(e) => {
                                                handleDollarFlightChange(e.target.checked, "EUR");
                                            }}
                                        />
                                        <p>{t("fligth.flight_filters.euros_prices")}</p>
                                    </label>
                                </div>
                            </div>
                            <div className="rangeRadioFilter">
                                <div className="radio-container">
                                    <label
                                        htmlFor="PesosFlight"
                                        className="optionRadioButton"
                                        style={{
                                            display: "flex",
                                            alignItems: "center",
                                            gap: "6px",
                                        }}
                                    >
                                        <input
                                            className="hidden"
                                            type="radio"
                                            id="PesosFlight"
                                            name="PesosFlight"
                                            value={1}
                                            checked={dollarFlight === "COP"}
                                            onChange={(e) => {
                                                handleDollarFlightChange(e.target.checked, "COP");
                                            }}
                                        />
                                        <p>{t("fligth.flight_filters.cop_prices")}</p>
                                    </label>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="containerFlightFiltersOptionFlight">
                        <button className="buttonCleanFilters" onClick={cleanFilters}>
                            {t("fligth.flight_filters.clean_filters")}
                        </button>
                    </div>
                </div>
            </div>
        </>
    );
};

export default FlightFilters;
