import React from "react";
import Banner from "./Banner";

const Specialists = () => {

  return (
    <Banner
    />
  );
};

export default Specialists;