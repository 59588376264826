import React, { useState, useEffect } from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { BrowserRouter } from "react-router-dom";
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';
import esLocale from 'date-fns/locale/es';
import { I18nextProvider } from 'react-i18next';
import i18next from 'i18next';
import { Provider } from 'react-redux';
import { PersistGate } from 'redux-persist/integration/react';
import { store, persistor } from './store';

import global_es from './infra/Translations/es/global.json';
import global_en from './infra/Translations/en/global.json'; 

const userLanguage = navigator.language || navigator.userLanguage;

i18next.init({
  interpolation: { escapeValue: false },
  lng: `${userLanguage.startsWith('es') ? "es" : "en"}`,
  resources: {
    es: {
      global: global_es
    },
    en: {
      global: global_en
    }
  }
});

const root = ReactDOM.createRoot(document.getElementById('root'));

const Main = () => {  

  return (
    <Provider store={store}>
      <PersistGate loading={null} persistor={persistor}>
        <BrowserRouter>
          <MuiPickersUtilsProvider utils={DateFnsUtils} locale={esLocale}>
            <I18nextProvider i18n={i18next}>
              <App />  
            </I18nextProvider>
          </MuiPickersUtilsProvider>
        </BrowserRouter>
      </PersistGate>
    </Provider>
  );
};

root.render(<Main />); 

reportWebVitals();