import React from "react";
import "./ModalChange.css";
import { MdClose } from "react-icons/md";
import { useTranslation } from "react-i18next";
import { formatToCOP } from "../../../../utils";
import TextComponent from "@/ui/views/share/components/fonts/TextComponent";
import { FaCircleCheck } from "react-icons/fa6";

const ModalChange = ({ selectHab, show, setShow }) => {
  const { t } = useTranslation("global");
  function formatearCantidad(cantidad) {
    const cantidadFormateada = cantidad.toLocaleString("es", {
      style: "currency",
      currency: "COP",
      minimumFractionDigits: 0,
      maximumFractionDigits: 0,
    });

    return cantidadFormateada;
  }

  return (
    <div
      className={`${show ? "ModalChange" : "ModalChange close"}`}
      onClick={(e) => {
        if (e.currentTarget === e.target) {
          setShow(false);
        }
      }}
    >
      <div
        className={`${show ? "ModalChangeContent" : "ModalChangeContent close"
          }`}
      >
        <button
          className="ModalChangeCloseButton"
          onClick={() => setShow(false)}
        >
          <MdClose size={23} color="#104172" />
        </button>

        <div className="content-modal-card-title">
          <h2>{t("hotels.selected_room.modal.title")}</h2>

          <p style={{ color: "#2a2a2a", fontSize: 14 }}>
            {t("hotels.selected_room.modal.room_code")}:{" "}
            <span style={{ color: "#004274", fontWeight: 600 }}>
              {selectHab?.id}
            </span>
          </p>
        </div>
        <ul className="list-content-modal-card">
          <li>
            <span className="label-content-modal-card">{t("hotels.selected_room.modal.name")}: </span>
            <span className="value-content-modal-card">
              {selectHab?.name_room?.name}
            </span>
          </li>
          <li>
            <span className="label-content-modal-card">{t("hotels.rooms.size")}: </span>
            <span className="value-content-modal-card">
              {selectHab?.room_size + " m2"}
            </span>
          </li>
          {/* <li>
            <span className="label-content-modal-card">Ciudad</span>
            <span className="value-content-modal-card">
              {data?.pob?.charAt(0).toUpperCase() +
                data?.pob?.slice(1).toLowerCase()}
            </span>
          </li> */}
          <li>
            <span className="label-content-modal-card">{t("hotels.selected_room.modal.includes")}: </span>
            <ul>
              {
                selectHab?.article_and_services?.map((bed, index) => (
                  <li key={index}>
                    <TextComponent size={"md2"} >
                      <FaCircleCheck size={18} color={"var(--bluePrimary)"} />
                      {
                        bed?.name
                      }
                    </TextComponent>
                  </li>

                ))
              }
            </ul>
          </li>

          {selectHab?.reg?.[0]["@attributes"]?.nr && (
            <li>
              <span className="label-content-modal-card">{t("hotels.selected_room.modal.also")}: </span>
              <span className="value-content-modal-card">
              <ul>
              {
                selectHab?.rate_types?.map((bed, index) => (
                  <li key={index}>
                    <TextComponent size={"md2"} >
                      <FaCircleCheck size={18} color={"var(--bluePrimary)"} />
                      {
                        bed?.name
                      }
                    </TextComponent>
                  </li>

                ))
              }
            </ul>
              
                {
                  selectHab?.reg?.[0]["@attributes"]?.nr === "Reembolsable" ? t("hotels.selected_room.with_refund") : t("hotels.selected_room.without_refund")
                }
              </span>
            </li>
          )}
          <li>
            <span className="label-content-modal-card">
              {t("hotel.include_taxes")}
            </span>
          </li>
        </ul>

        <div className="price-content-modal-card">
          <span className="value-content-modal-card">{t("hotels.selected_room.modal.total")}:</span>
          <span style={{ color: "#004274 !important", fontSize: 18 }}>
            <b>{formatearCantidad(Number(selectHab?.priceWithDays))} COP</b>
          </span>
        </div>
        <p style={{ color: "#757575", paddingBottom: 10, fontSize: 14 }}>
          {t("hotels.selected_room.modal.footer_text")}
        </p>
      </div>
    </div>
  );
};

export default ModalChange;
