import React, { useState, useEffect } from "react";
import { Link, useLocation } from "react-router-dom";
import "./styles.css";
import CarouselSevices, {
    servicesTravel,
} from "../../share/components/CarouselServices/CarouselSevices";
import AsideHotelResult from "./aside/AsideHotelResult";
import AllCardsResults from "./allResultsCards/AllCardsResults";
import axios from "axios";
import Paginado from "./Paginado/Paginado";
import UseHotels from "../../../viewModels/hotels/UseHotels";
import { useTranslation } from "react-i18next";
import HotelSearchEngine from "../HotelSearchEngine/HotelSearchEngine";
import ReturnPage from "../../share/components/return/ReturnPage";
import Loading from "../../share/components/loading";
import { AiOutlineExclamationCircle } from "react-icons/ai";
import AllCardsResultsAiop from "./allResultsCardsAiop/AllCardsResultsAiop";

const HotelResults = () => {
    const extract = (stringt) => {
        const startIndex = pathname.indexOf(stringt);
        if (startIndex !== -1) {
            // Encontrado, buscar el índice del ampersand siguiente
            const endIndex = pathname.indexOf("&", startIndex);

            if (endIndex !== -1) {
                // Extraer el valor de dateOfDeparture
                const dateOfDeparture = pathname.slice(
                    startIndex + stringt.length,
                    endIndex
                );

                return dateOfDeparture;
            }
        }
    };
    const location = useLocation();
    const [firstRange, setFirstRange] = useState(0);
    const [secondRange, setSecondRange] = useState(0);
    const [loadingSearcht, setLoadingSearcht] = useState(true);
    const [resetResult, setResetResult] = useState(false);
    const [errorFilter, setErrorFilter] = useState("");
    const [activeFilterPrice, setActiveFilterPrice] = useState(false);
    const { state, pathname } = useLocation();
    const [services, setServices] = useState([]);
    const itemsPerPage = 10;
    const [dataHotels, setDataHotels] = useState([]);
    const [aiopHotels, setAiopHotels] = useState([]);
    const [currentPage, setCurrentPage] = useState(1);
    const totalPages = Math.ceil((services || []).length / itemsPerPage);
    const [onlyCodeCobol, setOnlyCodeCobol] = useState([]);
    const [hotelsAll, setHotelsAll] = useState([]);
    const [selectedStars, setSelectedStars] = useState(0);
    const [filteredData, setFilteredData] = useState([]);
    const [seePack, setSeePack] = useState(pathname.includes("/packages/hotels"));
    const [isShowingFind, setIsShowingFind] = useState(false);
    const [defaultHotel, setDefaultHotel] = useState(false);
    const [priceMin, setPriceMin] = useState(0);
    const [priceMax, setPriceMax] = useState(0);
    const [filterName, setFilterName] = useState('');
    const { t } = useTranslation("global");
    let rooms = [];
    const [sortOrder, setSortOrder] = useState("asc");

    const sortHotelsByPrice = (hotels, order) => {
        const hotelsSorted = [...hotels].sort((a, b) => {
            const priceA = Number(a.res.pax[0].hab[0].reg[0]["@attributes"].prr);
            const priceB = Number(b.res.pax[0].hab[0].reg[0]["@attributes"].prr);
            return order === "asc" ? priceA - priceB : priceB - priceA;
        });

        if (priceMin == 0 && priceMax == 0) {
            setPriceMin(Number(hotelsSorted[0]?.res.pax[0].hab[0].reg[0]["@attributes"].prr));
            setPriceMax(Number(hotelsSorted[hotelsSorted.length - 1]?.res.pax[0].hab[0].reg[0]["@attributes"].prr));
        }

        return hotelsSorted;
    };

    const {
        cardRef,
        openHotelSearch,
        paceholders,
        search,
        setSearch,
        destiny,
        dateEntry,
        setDateEntry,
        datesSelected,
        setDatesSelected,
        cardRooms,
        setCardRooms,
        roomsSelected,
        setRoomsSelected,
        quantityPersons,
        openHotelSearchFuct,
        destinationSelectionFunc,
        searchHotelSelected,
        findCountryClient,
        nacionalityDropdown,
        setNacionalityDropdown,
        dataNacionality,
        selectedNacionality,
        nacionality,
        codeNacionality,
        loading,
        loadingSearch,
        validationForm,
        errApi,
    } = UseHotels();

    function convertirACadenaArray(cadena) {
        // Separar la cadena por comas y eliminar espacios en blanco si los hubiera
        const array = cadena.split(",").map((item) => {
            return { code: item.trim() };
        });
        return array;
    }

    const datos = {
        arrival: extract("arrival="),
        departure: extract("departure="),
        country: "CO",
        countryClient: extract("countryClient="),
        rooms: rooms,
        hotels: convertirACadenaArray(extract("hotels=")),
    };

    const datosHotelAiop = {
        arrival: formatDate(extract("arrival=")),
        departure: formatDate(extract("departure=")),
        city: extract("city="),
        rooms: rooms,
        countryClient: extract("countryClient="),
    };
    
    // Función para formatear la fecha en Y-m-d
    function formatDate(dateString) {
        if (!dateString) return null; // Evitar errores si la fecha es nula o indefinida
        const date = new Date(dateString);
        if (isNaN(date)) return null; // Validar que sea una fecha válida
        return date.toISOString().split("T")[0]; // Formato YYYY-MM-DD
    }

    const match = pathname.match(/idHotel=(\d+)/);
    const idHotel = match ? match[1] : "";

    const convertirFecha = (fecha) => {
        let partes = fecha.split("-");
        return `${partes[0]}/${partes[1]}/${partes[2]}`;
    };

    let partes = pathname.split("&");

    for (let i = 0; i < partes.length; i++) {
        let parametro = partes[i].split("=");
        let clave = parametro[0];
        let valor = parametro[1];

        switch (clave) {
            case "arrival":
                datos.arrival = convertirFecha(valor);
                break;
            case "departure":
                datos.departure = convertirFecha(valor);
                break;
        }
    }

    const parseNumbers = (str) => {
        return str.split(",").map((num) => parseInt(num, 10));
    };

    const adultMatch = pathname.match(/adult=([\d,]+)/);
    const childMatch = pathname.match(/child=([\d,]+)/);
    const ageMatch = pathname.match(/age=([\d,-]+)/);

    const adultValues = adultMatch ? parseNumbers(adultMatch[1]) : [];
    const childValues = childMatch ? parseNumbers(childMatch[1]) : [];
    const ageValues = ageMatch
        ? ageMatch[1]
            .split(",")
            .map((ageRange) => ageRange.split("-").map((num) => parseInt(num, 10)))
        : [];

    const formattedValues = {
        adult: adultValues,
        child: childValues,
        age: ageValues,
    };

    const maxLength = Math.max(
        formattedValues.adult.length,
        formattedValues.child.length,
        formattedValues.age.length
    );

    for (let i = 0; i < maxLength; i++) {
        let room = {
            adult: formattedValues.adult[i] || 1,
            child: formattedValues.child[i] || 0,
            age: formattedValues.age[i] || [],
        };
        rooms.push(room);
    }

    useEffect(() => {
        const firstIndex = (currentPage - 1) * itemsPerPage;
        const lastIndex = firstIndex + itemsPerPage;
        setDataHotels(filteredData.slice(firstIndex, lastIndex));
    }, [currentPage, filteredData]);

    useEffect(() => {
        if (services && services.length > 0) {
            const filtered = services.filter((item) => {
                if (selectedStars === 0) return true;
                return Number(item.cat) === selectedStars;
            });

            // Aplicar la ordenación después de filtrar
            const sortedFiltered = sortHotelsByPrice(filtered, sortOrder);
            setFilteredData(sortedFiltered);
            setDataHotels(sortedFiltered.slice(0, itemsPerPage));
            setPriceMin(Number(sortedFiltered[0]?.res.pax[0].hab[0].reg[0]["@attributes"].prr));
            setPriceMax(Number(sortedFiltered[sortedFiltered.length - 1]?.res.pax[0].hab[0].reg[0]["@attributes"].prr));
        }
    }, [services, selectedStars, sortOrder]);

    useEffect(() => {
        if (Number(firstRange) || Number(secondRange) > 0) {
            if (services?.length > 0) {
                setErrorFilter("");
                const filtrados = services.filter((item) => {
                    return (
                        item.res.pax[0].hab[0].reg[0]["@attributes"].prr >= firstRange &&
                        item.res.pax[0].hab[0].reg[0]["@attributes"].prr <= secondRange
                    );
                });

                const sortedFiltered = sortHotelsByPrice(filtrados, sortOrder);
                setFilteredData(filtrados);
                setDataHotels(filtrados.slice(0, itemsPerPage));
                //setServices(filtrados);
            }
        } else {
            setErrorFilter(t("hotel.necessary_camp"));
        }
    }, [activeFilterPrice]);

    useEffect(() => {
        if (services && services.length > 0) {
            const filtered = services.filter((item) => {
                if (item.nom.toLowerCase().includes(filterName.toLowerCase())) return true;
            });

            // Aplicar la ordenación después de filtrar
            const sortedFiltered = sortHotelsByPrice(filtered, sortOrder);
            setFilteredData(sortedFiltered);
            setDataHotels(sortedFiltered.slice(0, itemsPerPage));
            setPriceMin(Number(sortedFiltered[0]?.res.pax[0].hab[0].reg[0]["@attributes"].prr));
            setPriceMax(Number(sortedFiltered[sortedFiltered.length - 1]?.res.pax[0].hab[0].reg[0]["@attributes"].prr));
        }
    }, [filterName]);

    const nextHandler = () => {
        if (currentPage < totalPages) {
            setCurrentPage((prevPage) => prevPage + 1);
        }
        window.scrollTo(0, 0);
    };

    const prevHandler = () => {
        if (currentPage > 1) {
            setCurrentPage((prevPage) => prevPage - 1);
        }
        window.scrollTo(0, 0);
    };

    let totalPersons = rooms.reduce(
        (total, room) => total + room.adult + room.child,
        0
    );

    useEffect(() => {
        window.scrollTo(0, 0);
        setLoadingSearcht(true); // Activar loading antes de las peticiones

        const request1 = axios.post(`${process.env.REACT_APP_URL_API_FLY}restel/disponibility`, datos);
        const request2 = axios.post(`${process.env.REACT_APP_URL_API_AIOP}api/search/hotels`, datosHotelAiop);

        Promise.all([request1, request2])
            .then(([res1, res2]) => {
                setServices(res1?.data);
                setHotelsAll(res1?.data);
                setDataHotels(res1?.data.slice(0, itemsPerPage));
                setAiopHotels(res2?.data);
            })
            .catch((err) => {
                console.log(err);
                setDataHotels([]);
                setAiopHotels([]);
            })
            .finally(() => {
                setLoadingSearcht(false); // Desactivar loading cuando ambas terminan
            });

    }, [pathname]);


    useEffect(() => {
        setDataHotels(hotelsAll.slice(0, itemsPerPage));
        setServices(hotelsAll);
        setFirstRange(0);
        setSecondRange(0);
    }, [resetResult]);

    const routeStartsWithBuildPackageHotel = location.pathname.startsWith(
        "/build-package/hotel"
    );


    return (
        <div className="content-search-hotels">
            {loadingSearcht && <Loading />}
            {!routeStartsWithBuildPackageHotel && (
                <>
                    <CarouselSevices current={servicesTravel[1].title} />
                    <ReturnPage />

                    <HotelSearchEngine
                        titleSize={"20px"}
                        iconTitleSize={"50"}
                        containDateFlexDirection={"column"}
                        containDateJustify={"space-between"}
                        layoutCampoWidth={"100%"}
                        containFinishFlexDirection={"column"}
                        containFinishJustify={"space-between"}
                        layoutCampoVWidth={"100%"}
                        widthContain={"100"}
                        cardRef={cardRef}
                        cardRooms={cardRooms}
                        codeNacionality={codeNacionality}
                        dataNacionality={dataNacionality}
                        dateEntry={dateEntry}
                        datesSelectedEntry={datesSelected.entryDate}
                        datesSelectedExit={datesSelected.exitDate}
                        destinationSelectionFunc={destinationSelectionFunc}
                        destiny={destiny}
                        errApi={errApi}
                        findCountryClient={findCountryClient}
                        loading={loading}
                        loadingSearch={loadingSearch}
                        nacionality={nacionality}
                        nacionalityDropdown={nacionalityDropdown}
                        openHotelSearch={openHotelSearch}
                        openHotelSearchFuct={openHotelSearchFuct}
                        paceholders={paceholders}
                        quantityPersons={quantityPersons}
                        roomsSelected={roomsSelected}
                        search={search}
                        searchHotelSelected={searchHotelSelected}
                        selectedNacionality={selectedNacionality}
                        setCardRooms={setCardRooms}
                        setDateEntry={setDateEntry}
                        setDatesSelected={setDatesSelected}
                        setNacionalityDropdown={setNacionalityDropdown}
                        setRoomsSelected={setRoomsSelected}
                        setSearch={setSearch}
                        validationForm={validationForm}
                        defaultHotel={defaultHotel}
                        setDefaultHotel={setDefaultHotel}
                    />
                </>
            )}

            <div className="contain-results-hotels">
                <div className="main-content-resulta-filter-hotels">
                    <AsideHotelResult
                        priceMin={priceMin}
                        priceMax={priceMax}
                        services={services}
                        setSecondRange={setSecondRange}
                        activeFilterPrice={activeFilterPrice}
                        setActiveFilterPrice={setActiveFilterPrice}
                        errorFilter={errorFilter}
                        setFirstRange={setFirstRange}
                        setResetResult={setResetResult}
                        resetResult={resetResult}
                        setSelectedStars={setSelectedStars}
                        setFilterName={setFilterName}
                    />



                    <div>
                        {
                            aiopHotels.length > 0 && loadingSearcht === false  ? (
                                <AllCardsResultsAiop
                                    data={aiopHotels}
                                    arrival={datosHotelAiop?.arrival}
                                    departure={datosHotelAiop?.departure}
                                    persons={totalPersons}
                                />
                            ) :
                                ""
                        }
                        {
                            !loadingSearcht && (
                                
                                services?.length > 0 && filteredData?.length ? (
                                    <AllCardsResults
                                        data={dataHotels}
                                        currentPage={currentPage}
                                        dataS={services}
                                        nextHandler={nextHandler}
                                        prevHandler={prevHandler}
                                        loading={loadingSearcht}
                                        seePack={seePack}
                                        dataRoom={datos}
                                        persons={totalPersons}
                                        fen={datos.arrival}
                                        fsa={datos.departure}
                                        rooms={rooms}
                                        country_client={datos.countryClient}
                                        selectedStars={selectedStars}
                                        filteredData={filteredData}
                                        setFilteredData={setFilteredData}
                                    />
                                ) : (
                                    <div className="main-content-resulta-filter-hotels-not-results">
                                        <div className="noItemsHotels">
                                            <div className="messageNoItemsHotels">
                                                {t("hotels.without_searchs")}
                                                <AiOutlineExclamationCircle
                                                    size={20}
                                                    color="var(--bluePrimary)"
                                                />
                                            </div>

                                            <Link to="/hotel" className="buyNoItemsHotels">
                                                {t("hotels.search_again")}
                                            </Link>
                                        </div>
                                    </div>
                                )
                            )
                        }
                    </div>


                </div>

                {services?.length > 0 && filteredData?.length > 0 && (
                    <Paginado
                        currentPage={currentPage}
                        data={filteredData}
                        filteredData={filteredData}
                        nextHandler={nextHandler}
                        prevHandler={prevHandler}
                        setCurrentPage={setCurrentPage}
                        itemsPerPage={itemsPerPage}
                        pageRangeDisplayed={5}
                        selectedStars={selectedStars}
                    />
                )}
            </div>


        </div>
    );
};

export default HotelResults;
