// AnyQuestions.js

import React, { useState, useEffect } from "react";
import "./styles.css";
import SvgIcon, { iconList } from "../../../share/components/iconSVG";
import { useTranslation } from "react-i18next";
import TextComponent from "../../../share/components/fonts/TextComponent";
import { useNavigate } from "react-router-dom";

function AnyQuestions({ title }) {
  const [t] = useTranslation("global");
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 600);
  const navigate = useNavigate();
  const handleResize = () => {
    setIsMobile(window.innerWidth <= 600);
  };

  useEffect(() => {
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);


  const redirect = () => {
    navigate("/policies-and-terms");
  }
  const redirectWhatsapp = () => {
    navigate("/policies-and-terms");
  }

  const items = [
    <a href="https://maps.app.goo.gl/JKuCCZ7iJqzEfG8M6" target={"_blank"}>
      <div className="anyQuestionsOption cursor-pointer" key="1" >
        <div className="anyQuestionsOptionIcon">
          <SvgIcon name={iconList.IconPinMap} color={"#00AFE8"} size={60} />
        </div>
        <TextComponent
          className={"anyQuestionsOptionText"}
          children={t("browse_home.any_question.attention_points")}
          size={"min"}
          fontWeight="normal"
        />
      </div>
    </a>,

    <a href="https://api.whatsapp.com/send?phone=573004040116" target={"_blank"}>
      <div className="anyQuestionsOption cursor-pointer" key="2">
        <div className="anyQuestionsOptionIcon">
          <SvgIcon name={iconList.IconChat} color={"#00AFE8"} size={70} />
        </div>
        <TextComponent
          className={"anyQuestionsOptionText"}
          children={t("browse_home.any_question.online_chat")}
          size={"min"}
          fontWeight="normal"
        />
      </div>
    </a>,

    <a href="https://api.whatsapp.com/send?phone=573004040116" target={"_blank"}>
      <div className="anyQuestionsOption cursor-pointer" key="3">
        <div className="anyQuestionsOptionIcon">
          <SvgIcon name={iconList.IconPhoneRing} color={"#00AFE8"} size={80} />
        </div>
        <TextComponent
          className={"anyQuestionsOptionText"}
          children={t("browse_home.any_question.call_us")}
          size={"min"}
          fontWeight="normal"
        />
      </div>
    </a>,

    <a href="https://api.whatsapp.com/send?phone=573004040116" target={"_blank"}>
      <div className="anyQuestionsOption cursor-pointer" key="4">
        <div className="anyQuestionsOptionIcon">
          <SvgIcon name={iconList.IconCall} color={"#00AFE8"} size={60} />
        </div>
        <TextComponent
          className={"anyQuestionsOptionText"}
          children={t("browse_home.any_question.we_call")}
          size={"min"}
          fontWeight="normal"
        />
      </div>
    </a>,

    <a href="https://api.whatsapp.com/send?phone=573004040116" target={"_blank"}>
      <div className="anyQuestionsOption cursor-pointer" key="5">
        <div className="anyQuestionsOptionIcon">
          <SvgIcon name={iconList.IconMegaPhone} color={"#00AFE8"} size={60} />
        </div>
        <TextComponent
          className={"anyQuestionsOptionText"}
          children={t("browse_home.any_question.contact_us")}
          size={"min"}
          fontWeight="normal"
        />
      </div>
    </a>,

    <div className="anyQuestionsOption cursor-pointer" key="6" onClick={redirect}>
      <div className="anyQuestionsOptionIcon">
        <SvgIcon name={iconList.IconMegaPhone} color={"#00AFE8"} size={60} />
      </div>
      <TextComponent
        className={"anyQuestionsOptionText"}
        children={t("browse_home.any_question.policy")}
        size={"min"}
        fontWeight="normal"
      />
    </div>,
  ];

  return (
    <section className="anyQuestionsContainer">
      <div className="anyQuestions">
        <TextComponent
          className={"anyQuestionsTitle"}
          children={title}
          size={"lg"}
          fontWeight="bold"
        />

        <div className="carousel-inner-max">
          <div className="carousel-inner">
            {isMobile ? (
              /* En móviles, duplicamos los elementos para la animación infinita */
              items.concat(items).map((item, index) => (
                <div className="carousel-item-footer" key={index}>
                  {item}
                </div>
              ))
            ) : (
              /* En escritorio, solo mostramos los elementos una vez */
              items.map((item, index) => (
                <div className="carousel-item-footer" key={index}>
                  {item}
                </div>
              ))
            )}
          </div>
        </div>
      </div>
    </section>
  );
}

export default AnyQuestions;
