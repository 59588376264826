import React from "react";
import "./styles.css";
import { IoIosClose } from "react-icons/io";
import { useTranslation } from "react-i18next";

const ModalCardDetail = ({
  data,
  total,
  modalCardDetail,
  setModalDetail,
}) => {
  const { t } = useTranslation("global");

  return (
    <div
      className={`${modalCardDetail ? "modal-card-detail" : "modal-card-detail close"
        }`}
      onClick={(e) => {
        if (e.currentTarget === e.target) {
          setModalDetail(false);
        }
      }}
    >
      <div
        className={`${modalCardDetail
          ? "content-modal-card-detail"
          : "content-modal-card-detail close"
          }`}
      >
        <button
          className="modal-card-detail-close"
          onClick={() => setModalDetail(false)}
        >
          <IoIosClose size={45} color="var(--bluePrimary)" />
        </button>

        <div className="content-modal-card">
          <div className="content-modal-card-title">
            <h2>
              {
                t("reserve_modal.title")
              }
            </h2>

            <p>
              Estrellas
              :
              <span className="ml-[2px]">
                {data?.category}
              </span>
            </p>
          </div>
          <ul className="list-content-modal-card">
            {(data?.name || data?.nombre_h) && (
              <li>
                <span className="label-content-modal-card">
                  {t("reserve_modal.reserve_name")}
                </span>
                <span className="value-content-modal-card">

                  {data?.name || data?.nombre_h}
                </span>
              </li>
            )}

            {data?.type_hotel && (
              <li>
                <span className="label-content-modal-card">
                  {t("reserve_modal.reserve_type")}
                </span>
                <span className="value-content-modal-card">
                  {
                    data?.type_hotel?.name
                  }
                </span>
              </li>
            )}

            {(data?.pob || data?.poblacion) && (
              <li>
                <span className="label-content-modal-card">
                  {t("reserve_modal.reserve_city")}
                </span>
                <span className="value-content-modal-card">
                  {data?.pob?.charAt(0).toUpperCase() +
                    data?.pob?.slice(1).toLowerCase() ||
                    data?.poblacion?.charAt(0).toUpperCase() +
                    data?.poblacion?.slice(1).toLowerCase() ||
                    data?.poblacion?.charAt(0).toUpperCase() +
                    data?.poblacion?.slice(1).toLowerCase()}
                </span>
              </li>
            )}

            {data?.direction && (
              <li>
                <span className="label-content-modal-card">
                  {t("reserve_modal.reserve_address")}
                </span>
                <span className="value-content-modal-card">
                  {data?.direction}
                </span>
              </li>
            )}

            {data?.establishment_services && (
                  <li >
                    <span className="label-content-modal-card">
                      {t("reserve_modal.reserve_includes")}
                    </span>

                    <span className="value-content-modal-card">
                      {data?.establishment_services.map((item, key) => {
                        return(
                          item.name+", "
                        )
                      
                      })}
                    </span>
                    
                  </li>
                
              )
            }




          </ul>

          <div className="price-content-modal-card">
            <span className="value-content-modal-card">
              {t("reserve_modal.total")}
            </span>
            <span>
              <b>
                {total + " COP"}
              </b>
            </span>
          </div>
          <p
            className="price-content-modal-card-description"
          >
            {t("reserve_modal.footer_text")}
          </p>
        </div>
      </div>
    </div>
  );
};

export default ModalCardDetail;
