import { useState } from "react";
//import img2 from "../../../../assets/svg/BannerBottom.jpg";
import img2 from "../../../assets/svg/BannerBottom.jpg";
import "./styles.css";

import { useTranslation } from "react-i18next";
import CardPackages from "./CardPackages";
import Paginado from "../../Hotel/HotelResult/Paginado/Paginado";
import { useNavigate } from "react-router-dom";
import DateInputWithPicker from "../../BuildPackage/pages/std/components/SelectedDate/DateInputWithPicker";
import { LuCalendarPlus } from "react-icons/lu";
import { useEffect } from "react";
import { RiFilterFill } from "react-icons/ri";
import { IoClose } from "react-icons/io5";
import { Dialog } from "@mui/material";
import { IoIosArrowBack, IoIosArrowForward } from "react-icons/io";

const PackageSectionHealth = ({
  tour,
  inputValue,
  selectValue,
  seker,
  loading,
  dataPackage,
  numberPages,
  currentPage,
  handleNextPage,
  handlePrevPage,
  setCurrentPage
}) => {
  if (!inputValue) {
    inputValue = "";
  }

  //Variables de estado
  //Traducción
  const { t, i18n } = useTranslation("global");

  const totalPages = Math.ceil(numberPages / 10);
  const pageNumbers = Array.from({ length: totalPages }, (_, i) => i + 1);

  const [inputValueSection, setInputValueSection] = useState(inputValue);
  const [selectValueSection, setSelectValueSection] = useState(selectValue);

  //Variables de paginación 
  const itemsPerPage = 10;
  const navigate = useNavigate();

  const [operationDate, setOperationDate] = useState("");
  const [selectedOperationDate, setSelectedOperationDate] = useState(null);

  const [selectedMonth, setSelectedMonth] = useState('');
  const [selectedDeparture, setSelectedDeparture] = useState('');
  const [selectedDestination, setSelectedDestination] = useState('');
  const [filteredItems, setFilteredItems] = useState(dataPackage);
  const [filtersApplied, setFiltersApplied] = useState(false);
  const [open, setOpen] = useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  useEffect(() => {
    setFilteredItems(dataPackage);
  }, [dataPackage]);

  const uniqueDeparturePlaces = [...new Set(dataPackage.map(item => item.departure_place))];
  const uniqueDestinations = [...new Set(dataPackage.map(item => item.destination))];

  const uniqueMonths = [...new Set(dataPackage.map(item => {
    const date = new Date(item.trip_from);
    return `${date.getFullYear()}-${date.getMonth() + 2}`;
  }))];

  const handleSearch = () => {
    const filtered = dataPackage.filter(item =>
      (selectedDeparture ? item.departure_place === selectedDeparture : true) &&
      (selectedDestination ? item.destination === selectedDestination : true) &&
      (selectedMonth ? `${new Date(item.trip_from).getFullYear()}-${new Date(item.trip_from).getMonth() + 2}` === selectedMonth : true) &&
      (selectedOperationDate ? new Date(item.date_validity) <= new Date(selectedOperationDate) : true)
    );
    handleClose();
    setFilteredItems(filtered);
    setFiltersApplied(true);
  };

  const handleClear = () => {
    setSelectedDeparture('');
    setSelectedDestination('');
    setSelectedMonth('');
    setSelectedOperationDate(null);
    setFilteredItems(dataPackage);
    setFiltersApplied(false);
    handleClose();
  };

  const months = {
    en: [
      'January', 'February', 'March', 'April', 'May', 'June',
      'July', 'August', 'September', 'October', 'November', 'December'
    ],
    es: [
      'Enero', 'Febrero', 'Marzo', 'Abril', 'Mayo', 'Junio',
      'Julio', 'Agosto', 'Septiembre', 'Octubre', 'Noviembre', 'Diciembre'
    ]
  };

  const handleClick = () => {
    navigate('/TourismHealth');
  };

  const handlePageClick = (number) => {
    setTimeout(() => {
      window.scrollTo({
        top: 0,
        behavior: "smooth",
      });
    }, 200);
    setCurrentPage(number);
  };



  return (
    <div id="pack" className="packages-section" style={{ width: "90%" }}>
      <h2 id="title" className="title-packages-section">
        {tour ? "Tours" : t("packs.health.title")}
      </h2>
      <p className="p-pack">{t("packs.health.description")}</p>
      <div>
        <div>
          <div>
            {seker ? (
              <div>
                <div>
                  <label className="label-filter-package" htmlFor="">
                    {t("packs.tourism.packages_section.place")}
                  </label>
                  <input
                    className="input-filter-packages"
                    type="text"
                    onChange={(event) =>
                      setInputValueSection(event.target.value)
                    }
                  />
                </div>
                <div>
                  <label className="label-filter-package" htmlFor="">
                    {t("packs.tourism.packages_section.destination_type")}
                  </label>
                  <select
                    className="select-filter-packages"
                    name=""
                    id=""
                    onChange={(event) =>
                      setSelectValueSection(event.target.value)
                    }
                  >
                    <option value="">{t("packs.tourism.types.all")}</option>
                    <option value="international">{t("packs.tourism.types.international")}</option>
                    <option value="national">{t("packs.tourism.types.national")}</option>
                    <option value="regional">{t("packs.tourism.types.regional")}</option>
                  </select>
                </div>
              </div>
            ) : null}
          </div>
          <div className="layout-packages">
            <div className="containerPackages">
              <div className="buttonFiltersPackages" onClick={handleClickOpen}>
                <RiFilterFill size={20} />
                {t("build_package.steps.clinic.filters")}
              </div>
              <div className="filtersPackages">
                <div className="topFilterPackages">
                  <p>{t("build_package.steps.clinic.filters")}</p>
                </div>

                <div className="filtersContainerPackages">
                  <div className="filterDatePackages">
                    <div>
                      <h3>{t('packages.expires_until')}</h3>
                      <p>{t('packages.expires_until_date')}</p>
                    </div>
                    <DateInputWithPicker
                      inputId="operationDate"
                      selectedDate={selectedOperationDate}
                      setSelectedDate={setSelectedOperationDate}
                      setOperationDate={setOperationDate}
                      isBirthday={false}
                      placeholder="dd/mm/yyyy"
                      icon={() => <LuCalendarPlus color="var(--blueLightTransparent)" size={17} />}
                      hasButton={false}
                      validationForm={false}
                    />
                  </div>
                  <div className="filterDatePackages filterDatePackagesSelect">
                    <h3>{t('packages.departure_place')}</h3>
                    <p>{t('clinics.filter_by_city')}</p>
                    <select value={selectedDeparture} onChange={(e) => setSelectedDeparture(e.target.value)}>
                      <option value="">{t('clinics.select')}</option>
                      {uniqueDeparturePlaces.map(place => (
                        <option key={place} value={place}>{place}</option>
                      ))}
                    </select>
                  </div>
                  <div className="filterDatePackages filterDatePackagesSelect">
                    <h3>{t('packages.destination_place')}</h3>
                    <p>{t('clinics.filter_by_city')}</p>
                    <select value={selectedDestination} onChange={(e) => setSelectedDestination(e.target.value)}>
                      <option value="">{t('clinics.select')}</option>
                      {uniqueDestinations.map(destination => (
                        <option key={destination} value={destination}>{destination}</option>
                      ))}
                    </select>
                  </div>
                  <div className="filterDatePackages filterDatePackagesSelect">
                    <h3>{t('packages.by_month')}</h3>
                    <p>{t('packages.by_month_surgery')}</p>
                    <select value={selectedMonth} onChange={(e) => setSelectedMonth(e.target.value)}>
                      <option value="">{t('clinics.select')}</option>
                      {uniqueMonths.map(month => {
                        const [year, monthIndex] = month.split('-');
                        const monthName = months[i18n.language][parseInt(monthIndex) - 1];
                        return (
                          <option key={month} value={month}>{`${monthName} ${year}`}</option>
                        );
                      })}
                    </select>
                  </div>
                  {filtersApplied ? (
                    <div onClick={handleClear} className="searchButtonPackages">
                      {t("hotels.hotel_filters.clean_filters")}
                    </div>
                  ) : (
                    <div onClick={handleSearch} className="searchButtonPackages">
                      {t("tourism.search")}
                    </div>
                  )}
                </div>
              </div>
              <div style={{ width: '100%' }}>
                <div className="content-packs-new" style={{ marginTop: "10px" }}>
                  {filteredItems.length > 0 ? (
                    filteredItems.map((pack, index) => (
                      <CardPackages key={index} pack={pack} imgType={false} />
                    ))
                  ) : (
                    <h1>{t("packs.tourism.without_coincidence")}</h1>
                  )}
                </div>
              </div>
            </div>
            <div className="paginationButtons">
              {totalPages > 1 && (
                <div className="contain-paginate-hotels">
                  <div className="contain-paginate-hotels-buttons">
                    <div style={{ display: "flex", gap: 10, alignItems: "center" }}>
                      <button
                        className={`${currentPage <= 1
                          ? "contain-button-prev"
                          : "contain-button-prev active"
                          }`}
                        onClick={() => setCurrentPage(1)}
                        disabled={currentPage === 1}
                      >
                        <IoIosArrowBack
                          style={{ position: "absolute", right: 13 }}
                          size={25}
                        />
                        <IoIosArrowBack size={30} />
                      </button>
                      <button
                        className={`${currentPage <= 1
                          ? "contain-button-prev"
                          : "contain-button-prev active"
                          }`}
                        onClick={handlePrevPage}
                        disabled={currentPage === 1}
                      >
                        <IoIosArrowBack size={30} />
                      </button>
                    </div>

                    <div
                      style={{
                        display: "flex",
                        flexWrap: "wrap",
                        gap: 10,
                        alignItems: "center",
                        justifyContent: "center",
                      }}
                    >

                      {pageNumbers.map((number) => (
                        <button
                          key={number}
                          onClick={() => handlePageClick(number)}
                          className={`contain-button-current ${currentPage === number ? "" : "active"}`}
                        >
                          {number}
                        </button>
                      ))}

                    </div>

                    <div style={{ display: "flex", gap: 10, alignItems: "center" }}>
                      <button
                        className={`${currentPage >= totalPages
                          ? "contain-button-next"
                          : "contain-button-next active"
                          }`}
                        onClick={handleNextPage}
                        disabled={currentPage === totalPages}
                      >
                        <IoIosArrowForward size={30} />
                      </button>

                      <button
                        className={`${currentPage >= totalPages
                          ? "contain-button-next"
                          : "contain-button-next active"
                          }`}
                        onClick={() => setCurrentPage(totalPages)}
                        disabled={currentPage === totalPages}
                      >
                        <IoIosArrowForward size={30} />
                        <IoIosArrowForward
                          style={{ position: "absolute", left: 13 }}
                          size={25}
                        />
                      </button>
                    </div>
                  </div>
                </div>
              )}
            </div>
          </div>
          <div className="banner-bottom-package" onClick={handleClick} style={{ cursor: 'pointer' }}>
            <img src={img2} alt="" />
          </div>
        </div>
      </div>




      <Dialog
        PaperProps={{
          sx: {
            width: "100%",
            maxWidth: "730px!important",
            borderRadius: '12px',
            maxHeight: '80vh',
            padding: '19px'
          }
        }}
        BackdropProps={{
          sx: {
            backgroundColor: '#021624b0'
          }
        }}
        open={open} onClose={handleClose} maxWidth="md" fullWidth>
        <div className="dialogFilterPackages">
          <div className="topFilterPackagesDialog">
            <p>{t("build_package.steps.clinic.filters")}</p>
            <IoClose
              onClick={handleClose}
              size={28}
            />
          </div>

          <div>
            <div className="filterDatePackages">
              <div>
                <h3>{t('packages.expires_until')}</h3>
                <p>{t('packages.expires_until_date')}</p>
              </div>
              <DateInputWithPicker
                inputId="operationDate"
                selectedDate={selectedOperationDate}
                setSelectedDate={setSelectedOperationDate}
                setOperationDate={setOperationDate}
                isBirthday={false}
                placeholder="dd/mm/yyyy"
                icon={() => <LuCalendarPlus color="var(--blueLightTransparent)" size={17} />}
                hasButton={false}
                validationForm={false}
              />
            </div>
            <div className="filterDatePackages filterDatePackagesSelect">
              <h3>{t('packages.departure_place')}</h3>
              <p>{t('clinics.filter_by_city')}</p>
              <select value={selectedDeparture} onChange={(e) => setSelectedDeparture(e.target.value)}>
                <option value="">{t('clinics.select')}</option>
                {uniqueDeparturePlaces.map(place => (
                  <option key={place} value={place}>{place}</option>
                ))}
              </select>
            </div>
            <div className="filterDatePackages filterDatePackagesSelect">
              <h3>{t('packages.destination_place')}</h3>
              <p>{t('clinics.filter_by_city')}</p>
              <select value={selectedDestination} onChange={(e) => setSelectedDestination(e.target.value)}>
                <option value="">{t('clinics.select')}</option>
                {uniqueDestinations.map(destination => (
                  <option key={destination} value={destination}>{destination}</option>
                ))}
              </select>
            </div>
            <div className="filterDatePackages filterDatePackagesSelect">
              <h3>{t('packages.by_month')}</h3>
              <p>{t('packages.by_month_surgery')}</p>
              <select value={selectedMonth} onChange={(e) => setSelectedMonth(e.target.value)}>
                <option value="">{t('clinics.select')}</option>
                {uniqueMonths.map(month => {
                  const [year, monthIndex] = month.split('-');
                  const monthName = months[i18n.language][parseInt(monthIndex) - 1];
                  return (
                    <option key={month} value={month}>{`${monthName} ${year}`}</option>
                  );
                })}
              </select>
            </div>
            {filtersApplied ? (
              <div onClick={handleClear} className="searchButtonPackages">
                {t("hotels.hotel_filters.clean_filters")}
              </div>
            ) : (
              <div onClick={handleSearch} className="searchButtonPackages">
                {t("tourism.search")}
              </div>
            )}
          </div>



        </div>
      </Dialog>
    </div>
  );
};

export default PackageSectionHealth;
