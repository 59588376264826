import React, { useEffect } from "react";
import "./styles.css";
import { useTranslation } from "react-i18next";
import { useLocation, useNavigate } from "react-router-dom";
import { MdDelete } from "react-icons/md";
import { BiCartDownload } from "react-icons/bi";
import { use } from "i18next";

const CartReserve = ({
  deleteReserve,
  list,
  navigateMakeReservation,
}) => {
  // const dataHotJSON = JSON.stringify(dataHot);
  // localStorage.setItem("dataHotJSON", dataHotJSON);
  const location = useLocation();

  const routeStartsWithBuildPackageRoom = location.pathname.startsWith(
    "/build-package/room"
  );

  const navigate = useNavigate();
  const { pathname } = useLocation();
  const [t] = useTranslation("global");
  let suma = list.reduce(
    (acc, item) => acc + Number(item?.priceWithDays),
    0
  );

  function formatearCantidad(cantidad) {
    const cantidadFormateada = cantidad.toLocaleString("es", {
      style: "currency",
      currency: "COP",
      minimumFractionDigits: 0,
      maximumFractionDigits: 0,
    });
    return cantidadFormateada;
  }

  const navigateFligthPack = () => {
    if (list.length === 0) {
    } else {
      localStorage.setItem("hab-selected", JSON.stringify(list));

      navigate(localStorage.getItem("urlFligth"));
    }
  };

  return (
    <div className="contain-cart-reserve">
      <h2>{t("hotel.bookings")}</h2>

      <div className="table-wrapper">
        <table className="fl-table">
          <thead>
            <tr style={{ color: "var(--bluePrimary)" }}>
              <th>{t("hotel.room")}</th>
              <th className="hidde-accommodation">
                  Precio x noche
              </th>
              <th className="show-accommodation">{t("hotel.price")}</th>
              <th>{t("hotel.deleted")}</th>
              <th className="hidde-accommodation">{t("hotel.price")}</th>
              <th className="show-accommodations">
                {t("hotel.accommodation")}
              </th>
            </tr>
          </thead>
          <tbody>
            {list.length < 1 ? (
              <tr>
                <td style={{ borderBottom: "none" }}>
                  {t("hotel.no_selected")}
                </td>
              </tr>
            ) : (
              list.map((item, key) => {
                return (
                  <tr key={key}>
                    <td>{item?.name_room?.name}</td>
                    <td className="hidde-accommodation">
                      <b>{item?.price}</b>
                    </td>
                    <td className="show-accommodation">
                      <b>
                        {formatearCantidad(Number(item?.priceWithDays))}
                      </b>
                    </td>
                    <td>
                      <span
                        style={{ cursor: "pointer", display:'flex', alignItems:'center', justifyContent:'center' }}
                        onClick={() => {
                          deleteReserve(item?.id);
                        }}
                      >
                        <MdDelete size={20} color={"var(--bluePrimary)"} />
                      </span>
                    </td>
                    <td className="hidde-accommodation">
                      <b>
                        {formatearCantidad(Number(item?.priceWithDays))}
                      </b>
                    </td>
                    <td className="show-accommodations">
                      <b>{item?.acomodation}</b>
                    </td>
                  </tr>
                );
              })
            )}
          </tbody>

          {list.length > 1 && (
            <thead>
              <tr>
                <th></th>
                <th className="hidde-accommodation"></th>
                <th>Total</th>
                <th>
                  <b>{formatearCantidad(suma)}</b>
                </th>
                <th className="show-accommodation"></th>
              </tr>
            </thead>
          )}
        </table>
      </div>
      {list.length > 0 && pathname.includes("packages") ? (
        <div>
          <button className="reserve-button" onClick={navigateFligthPack}>
            {t("hotels.rooms.select_room")}
          </button>
        </div>
      ) : (
        <>
          {list.length > 0 && (
            <div style={{ display: "flex", justifyContent: "flex-end" }}>
              <button
                className="reserve-button"
                onClick={navigateMakeReservation}
              >
                <BiCartDownload size={25} /> {
                  !routeStartsWithBuildPackageRoom ?
                  t("hotel.reserve"):
                  t("hotels.rooms.select_room")
                }
              </button>
            </div>
          )}
        </>
      )}
    </div>
  );
};

export default CartReserve;
