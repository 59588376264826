import React, { useState } from "react";
import axios from "axios";
import { useLocation, useNavigate } from "react-router-dom";

const UseMakeReservationAffiliate = (verifyCaptcha) => {
  const { state } = useLocation();
  const [loadingPay, setLoadingPay] = useState(false)

  const [nombreTomador, setNombreTomador] = useState("");
  const [emailTomador, setEmailTomador] = useState("");
  const [telTomador, setTelTomador] = useState("");
  const [paxes, setPaxes] = useState([]);
  const [step, setStep] = useState(1);
  const [paymentCardSelected, setPaymentCardSelected] = useState(null);
  const [errorReservation, setErrorReservation] = useState(false);
  let cantAcomodationAdult = 0;
  let cantAcomodationChild = 0;
  let personsTotal = Number(state?.person);
  const [loadingReservation, setLoadingReservation] = useState(false);
  const [loadingRespose, setLoadingResponse] = useState(false);
  const [reservation, setReservation] = useState({});
  const [verifyCaptchaErr, setVerifyCaptchaErr] = useState(false)

  function getBaseUrl(url) {
    const parsedUrl = new URL(url);
    const baseUrl = `${parsedUrl.protocol}//${parsedUrl.host}`;
    return baseUrl;
}

  const functionNavigatePaceToPay = () => {
    setLoadingPay(true)
    const dataNew = {
      "localizator": reservation?.id,
      "type": "Affiliate",
      "price": reservation?.monto,
      "url": `${getBaseUrl(window.location.href)}/hotels/PayHotel/affiliate`,
      "currency":"COP"
    }
    const url = process.env.REACT_APP_URL_CREATE_LINK
      
      axios.post(url, dataNew)
        .then(res => {
          localStorage.setItem('data-pay-hotel', JSON.stringify(reservation))
          localStorage.setItem("request-id-pay", res.data?.requestId)
          putUrlReserve(res.data?.processUrl,reservation?.id)
        })
        .catch(err => {
          console.log(err)
        })
  }

  const putUrlReserve = (url,id)=>{
    const data = {
      "url": url,
      "id": id
    }
    const urlPut = `${process.env.REACT_APP_URL_API_AIOP}api/reserves/hotel-url`
    axios.post(urlPut, data)
      .then(res => {
        window.location.href = url
      })
      .catch(err => {
        console.log(err)
      })
  }


  function formatDate(dateString) {
    const date = new Date(dateString);
    return date.toISOString().split("T")[0]; // Extrae solo "YYYY-MM-DD"
  }

  const createReservation = () => {
    if (paxes?.length < personsTotal) {
      setErrorReservation(true);
    } else {
      if (verifyCaptcha()) {
        setVerifyCaptchaErr(false)
        const body = {
          "hotel_id": state?.hotel?.id,
          "nombre_titular": paxes[0]?.name,
          "apellido_titular": paxes[0]?.lastname,
          "documento_titular": paxes[0]?.doc,
          "fecha_llegada": state?.hotel?.arrival,
          "fecha_salida": state?.hotel?.departure,
          "monto": state?.price,
          "habitaciones": [],
          "huespedes": paxes,
          "telefono": paxes[0]?.tel,
          "correo": paxes[0]?.email,
          "url":""
        };
        state?.hab.map((item) => {
          body.habitaciones.push(item.id)
        })

        setLoadingReservation(true);
        setLoadingResponse(true);
        const url = `${process.env.REACT_APP_URL_API_AIOP}api/reserves/hotel`;
        axios
          .post(url, body)
          .then((res) => {
            setReservation(res.data?.reserva);
            console.log(res.data);
            setErrorReservation(false);
            setLoadingResponse(false);

          })
          .catch((err) => {
            console.log(err);
          });
      } else {
        setVerifyCaptchaErr(true)
      }

    }
  };



  return {
    emailTomador,
    setEmailTomador,
    telTomador,
    setTelTomador,
    paxes,
    setPaxes,
    step,
    setStep,
    cantAcomodationAdult,
    createReservation,
    nombreTomador,
    setNombreTomador,
    paymentCardSelected,
    setPaymentCardSelected,
    state,
    cantAcomodationChild,
    errorReservation,
    setErrorReservation,
    loadingReservation,
    setLoadingReservation,
    loadingRespose,
    reservation,
    functionNavigatePaceToPay,
    verifyCaptchaErr,
    personsTotal,
    loadingPay
  };
};

export default UseMakeReservationAffiliate;
