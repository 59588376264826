import axios from "axios";
import CarouselSevices, { servicesTravel } from "../../share/components/CarouselServices/CarouselSevices";
import { useEffect } from "react";
import { useState, useContext } from "react";
import ReturnPage from "../../share/components/return/ReturnPage";
import "./styles.css";
import TextComponent from "../../share/components/fonts/TextComponent";
import SvgIcon, { iconList } from "../../share/components/iconSVG";
import { IoCloseCircle } from "react-icons/io5";
import { TbLoader2 } from "react-icons/tb";
import { BiQuestionMark } from "react-icons/bi";
import { useTranslation } from "react-i18next";
import { GoCheckCircleFill } from "react-icons/go";
import { currencyFormat } from "@/ui/utils";
import { useNavigate } from "react-router-dom";
import toast, { Toaster } from "react-hot-toast";
import UserContext from "@/Context/user/userProvider";
import Loading from "../../share/components/loading";


const formatDate = (dateString) => {
    const date = new Date(dateString);

    // Obtener las partes individuales de la fecha
    const day = date.getDate().toString().padStart(2, '0');
    const month = (date.getMonth() + 1).toString().padStart(2, '0'); // Los meses empiezan en 0
    const year = date.getFullYear();

    // Obtener las partes de la hora
    const hours = date.getHours().toString().padStart(2, '0');
    const minutes = date.getMinutes().toString().padStart(2, '0');

    // Formato final
    return `${day}-${month}-${year} ${hours}:${minutes}`;
};

const MedicalPackagePay = () => {
    const [authUserSession, setAuthUserSession] = useState(null);
    const [payStatus, setPayStatus] = useState(null);
    const [loading, setLoading] = useState(true);
    const { t, i18n } = useTranslation("global");
    const [flightUrl, setFlightUrl] = useState("/Tourismhealth");
    const [packageInfo, setPackageInfo] = useState([]);
    const [userPayments, setUserPayments] = useState([]);
    const { userDetail } = useContext(UserContext);

    const [dollarFlight, setDollarFlight] = useState(
        localStorage.getItem("dollarFlight") === null
            ? "COP"
            : localStorage.getItem("dollarFlight")
    );

    const typeOfCoin = {
        name: dollarFlight,
        abbreviation: dollarFlight,
        simbol: "$",
    };

    const navigate = useNavigate();

    const notify = (title, icon) =>
        toast(title, {
            icon: icon,
        });

    useEffect(() => {
        setAuthUserSession(userDetail);

        const handleGetStatePay = async () => {
            await getStatePay(userDetail);
        }

        if (userDetail?.id && userDetail?.email) {
            handleGetStatePay();
        }

        const storedData = JSON.parse(localStorage.getItem('request-medical-datapackage'));
        if (storedData && storedData.length > 0) {
            setPackageInfo(storedData[0]);
        }
    }, [userDetail]);

    const getStatePay = async (authUserData) => {
        try {
            const requestId = localStorage.getItem('request-id-medicalpackage');

            if (!requestId) {
                navigate("/TourismHealth");
            }

            const url = `${process.env.REACT_APP_URL_STATE_PAY}medical-packacage`;

            const medicalPaymentStatus = await axios.post(url, {
                request_id: Number(requestId)
            });

            if (medicalPaymentStatus.data?.pay?.status === "APPROVED") {
                setPayStatus(medicalPaymentStatus.data.pay);

                const paymentsArray = [];

                const paymentInformation = medicalPaymentStatus.data?.pay;

                const paymentDataToStore = {
                    user_id: authUserData?.id,
                    email: authUserData?.email,
                    localizator: paymentInformation.localizator,
                    payment_date: paymentInformation.date,
                    payment_created_at: paymentInformation.created_at,
                    payment_updated_at: paymentInformation.updated_at,
                    price: paymentInformation.price.toString(),
                    reference: paymentInformation.reference,
                    reason: paymentInformation.reason,
                    status: paymentInformation.status,
                    request_id: paymentInformation.request_id,
                    type: paymentInformation.type
                }; 

                const url = `${process.env.REACT_APP_URL_API_AIOP}api/user-payments`;
                const userPayment = await axios.post(url, paymentDataToStore);
                paymentsArray.push(userPayment.data?.payment);
                setUserPayments(paymentsArray);

                const payment_message = t("packs.tourism.success.approved_payment");
                const reserve_message = t("packs.tourism.success.completed_reserve_normal");

                console.log("starting sending reservation data...")
                const reservationData = {
                  user_id: authUserData?.id,
                  full_name: `${packageInfo.name} ${packageInfo.lastname}`,
                  terms: true,
                  tel: packageInfo.cel,
                  email: packageInfo.email,
                  medical_package_id: packageInfo.packageId,
                  state: "reserved",
                  total_value: packageInfo.packagePrice,
                  origin: "wellezy",
                  observations: "No",
                  type: "COP",
                  city: packageInfo.city,
                  payments: JSON.stringify(paymentsArray),
                };
  
  
                console.log("reservation data: ", reservationData);
                const urlReserve = `${process.env.REACT_APP_URL_API_AIOP}api/medic/package/reserve`;
                await axios.post(urlReserve, reservationData) 
                
                localStorage.removeItem('request-medical-datapackage');
                localStorage.removeItem('request-id-medicalpackage');
                localStorage.removeItem('data-pay-medicalpackage');

                notify(payment_message, "✅");
                notify(reserve_message, "✅");
                setLoading(false);
                
            } else {
                setPayStatus(medicalPaymentStatus.data.pay);
                setLoading(false);
            }
            
        } catch (error) {
            setLoading(false);
            console.error('Error fetching package medic:', error);
        }
    };

    return (
        <div>
            <Toaster position="top-center" reverseOrder={false} />
            <CarouselSevices current={t("carousel_services.packages")} />
            <ReturnPage nav={flightUrl} reload={false} />
            {
                loading &&
                <Loading size={90}/>
            }
            
            <div className='methodPayContainer' >
                <div className='methodPayHeader'>
                    <h3>{t("packs.tourism.payment_detail.title")}</h3>
                    <p>{t("packs.tourism.payment_detail.description")}</p>
                </div>
                <div className='methodPayContain' >
                    <div className='methodPayContainQuestions'>
                        <div className='methodPayContainerTermsContain'>
                            <div className='detailPayFlight'>
                                <div className="detailPayFlightHeader">
                                    <div className="detailPayFlightHeaderImage">
                                        <img src={`${process.env.REACT_APP_URL_API_AIOP}storage/medic_pack/${packageInfo?.packageImage}`} alt="Paquete Médico" />
                                    </div>
                                    <div className="detailPayFlightInfo">
                                        <h2>
                                            {packageInfo?.packageName}
                                        </h2>
                                    </div>
                                </div>

                                <h3>{t("packs.tourism.payment_detail.package_information")}</h3>

                                <div className="itemDetailsFlights">
                                    <div className="itemDetailsFlights1">
                                        <div className="itemDetailsFlightsColumn1">
                                            <p className="typeFlight">
                                                <strong>{t("packs.tourism.payment_detail.payment_date")}</strong> {formatDate(payStatus?.created_at)}
                                            </p>
                                        </div>
                                    </div>
                                    <div className="itemDetailsFlights2">
                                        <div className="itemDetailsFlightsColumn1">
                                            <p className="typeFlight">
                                                <strong>{t("packs.tourism.payment_detail.payment_reference")}</strong> {payStatus?.reference}
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </div>


                        </div>
                    </div>

                    <div className="methodPayContainInfo">
                        <div className="methodPayImageContain">
                            <div className="methodPayContainInfoContain">
                                <div
                                    className={`contentStatePay ${payStatus?.status === "APPROVED"
                                        ? "APPROVED"
                                        : payStatus?.status === "PENDING"
                                            ? "PENDING"
                                            : payStatus?.status === "REJECTED"
                                                ? "REJECTED"
                                                : ""
                                        }`}
                                >
                                    {payStatus?.status === "APPROVED" ? (
                                        <GoCheckCircleFill size={40} />
                                    ) : payStatus?.status === "PENDING" ? (
                                        <TbLoader2 size={40} />
                                    ) : payStatus?.status === "REJECTED" ? (
                                        <IoCloseCircle size={40} />
                                    ) : (
                                        <BiQuestionMark size={40} />
                                    )}
                                    <div>
                                        <div className="contentStatePayMessage">
                                            <h3>{t("packs.tourism.payment_detail.status.text")}:</h3>
                                            <p>
                                                {payStatus?.status === "APPROVED"
                                                    ? t("packs.tourism.payment_detail.status.approved")
                                                    : payStatus?.status === "PENDING"
                                                        ? t("packs.tourism.payment_detail.status.pending")
                                                        : payStatus?.status === "REJECTED"
                                                            ? t("packs.tourism.payment_detail.status.rejected")
                                                            : t("packs.tourism.payment_detail.status.loading")}
                                            </p>
                                        </div>
                                        <p>
                                            {payStatus?.status === "APPROVED"
                                                ? t("packs.tourism.payment_detail.status.success_payment")
                                                : payStatus?.status === "PENDING"
                                                    ? t("packs.tourism.payment_detail.status.pending_payment")
                                                    : payStatus?.status === "REJECTED"
                                                        ? t("packs.tourism.payment_detail.status.rejected_payment")
                                                        : t("packs.tourism.payment_detail.status.loading")}
                                        </p>
                                    </div>
                                </div>
                                <div className="methodPayContainInfoContainPadding">
                                    <div>
                                        <h3>{t("packs.tourism.payment_detail.finished_purchase")}</h3>
                                        <p>{t("packs.tourism.payment_detail.total_purchase")}</p>
                                    </div>
                                    <div className="methodValuesContaint">
                                        <div className="methodValuesItem">
                                            <h4>{t("fligth.form_passenger.step3.sub_total")}:</h4>
                                            <span>$ {currencyFormat(payStatus?.price, typeOfCoin).total}</span>
                                        </div>
                                        <div className="methodValuesItemTotal">
                                            <h4>{t("fligth.form_passenger.step3.total")}:</h4>
                                            <span>$ {currencyFormat(payStatus?.price, typeOfCoin).total} </span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="securityPay">
                                <SvgIcon
                                    color={"var(--bluePrimary)"}
                                    name={iconList.IconKey}
                                    size={40}
                                />
                                <div>
                                    <TextComponent
                                        children={t("packs.tourism.payment_detail.secure_payment")}
                                        size={"md"}
                                        fontWeight={"bold"}
                                        tag="h4"
                                    />
                                    <TextComponent
                                        children={t("packs.tourism.payment_detail.secure_payment_description")}
                                        size={"md2"}
                                        fontWeight={"light"}
                                        tag="span"
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="text-center">
                    {payStatus?.status === "APPROVED" && (
                        <p>
                            {t("packs.tourism.payment_detail.footer_text", {status: t("packs.tourism.payment_detail.status.approved")})}
                        </p>
                    )}

                    {payStatus?.status === "PENDING" && (
                        <p>
                            {t("packs.tourism.payment_detail.footer_text", {status: t("packs.tourism.payment_detail.status.pending")})}
                        </p>
                    )}

                    {payStatus?.status === "REJECTED" && (
                        <p>
                            {t("packs.tourism.payment_detail.footer_text", {status: t("packs.tourism.payment_detail.status.rejected")})}
                        </p>
                    )}
                </div>
            </div>
        </div>
    );
};

export default MedicalPackagePay;
