import React, { useEffect, useState } from 'react'
import axios from 'axios'
import { useLocation, useNavigate } from 'react-router-dom'

const UsePayHotelAffiliate = () => {

  const [loadinPay, setLoadinPay] = useState(false)
  const requestId = localStorage.getItem("request-id-pay")
  const [statusTransaction, setStatusTransaction] = useState({})
  const [stateValue, setStateValue] = useState(JSON.parse(localStorage.getItem('data-pay-hotel')))


  const getStatePay = () => {
    const url = `${process.env.REACT_APP_URL_API_AIOP}api/reserves/hotel/pay`;
    axios.post(url, {
      request_id: Number(requestId)
    })
      .then(res => {
        console.log(res.data)
        setLoadinPay(false)
        setStatusTransaction(res.data)
        // setStatusTransaction(res.data)
        // if (res.data?.pay?.status === "APPROVED") {
        //   setDataEmitter(res.data)
        //   setResponseApi(false)
        //   setLoadinPay(false)
        // } else {
        //   setResponseApi(false)
        //   setLoadinPay(false)
        // }
      })
      .catch(err => {
        console.log(err)
        setLoadinPay(false)
      })

  }


  useEffect(() => {
      getStatePay()
  }, [])



  return {
    loadinPay,
    statusTransaction,
    stateValue
  }
}

export default UsePayHotelAffiliate;