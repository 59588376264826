import CarouselSevices from "../share/components/CarouselServices/CarouselSevices";
import "react-date-range/dist/styles.css"; // main css file
import "react-date-range/dist/theme/default.css"; // theme css file
import { useTranslation } from "react-i18next";
import banner from "../../assets/pictures/BannerNuevo1.jpg";
import banner2 from "../../assets/pictures/BannerNuevo2.jpg";
import banner3 from "../../assets/pictures/BannerNuevo3.jpg";
import banner4 from "../../assets/pictures/BannerNuevo4.jpg";
import banner5 from "../../assets/pictures/BannerNuevo5.jpg";
import axios from "axios";
import "./assets/styles.css";

import { useState, useEffect } from "react";
import AnyQuestions from "../home/components/anyQuestion";
import { Link, useNavigate } from "react-router-dom";
import CustomSlider from "../Packages/DetailPackages/sliderImg/CustomSlider";
import SearchProcedures from "./Searcher/SearchProcedures";
import PackageSectionHealth from "./PackageSection/PackageSectionHealth";

const Packages = () => {
    const { t } = useTranslation("global");
    const [inputValue, setInputValue] = useState("");
    const [selectValue, setSelectValue] = useState("");
    const [loading, setLoading] = useState(true);
    const [dataPackage, setDataPackage] = useState([]);
    const [pagesNumber, setPagesNumber] = useState(0);
    const [currentPage, setCurrentPage] = useState(1);

    let counter = 0;

    const navigate = useNavigate()

    const photos = [
        {
            id: 1,
            img: banner,
        },
        {
            id: 2,
            img: banner2,
        },
        {
            id: 3,
            img: banner3,
        },
        {
            id: 4,
            img: banner4,
        },
    ];





    const handleClick = () => {
        navigate('/TourismHealth');
    };


    const searchTypeCards = (page = 1) => {
        const baseUrl = `${process.env.REACT_APP_URL_API_AIOP}api/medic_packages`;
        const url = page === 1 ? baseUrl : `${baseUrl}?page=${page}`; 

        axios
            .get(url)
            .then((res) => {
                const packs = res.data?.packs.data || [];
                const numberPackages = res.data?.packs.total || 0;

                const duplicatedPacks = [...packs]; // Duplicar los elementos
                // Ordenar los packs por precio de menor a mayor
                duplicatedPacks.sort((a, b) => a.price - b.price);
             //   console.log("PAQUETES MEDICS", duplicatedPacks);
             //   console.log("NUMERO DE PAG", numberPackages);

                setDataPackage(duplicatedPacks);
                setPagesNumber(numberPackages);
                setTimeout(() => {
                    setLoading(false);
                }, 1000);
            })
            .catch((err) => {
                setLoading(false);
            });
    };

    useEffect(() => {
        setLoading(true);
        searchTypeCards(currentPage);
    }, [currentPage]);

    const handleNextPage = () => {
        setTimeout(() => {
            window.scrollTo({
                top: 0,
                behavior: "smooth",
            });
        }, 200);
        setCurrentPage((prevPage) => prevPage + 1);
    };

    const handlePrevPage = () => {
        setTimeout(() => {
            window.scrollTo({
                top: 0,
                behavior: "smooth",
            });
        }, 200);
        setCurrentPage((prevPage) => Math.max(prevPage - 1, 1));
    };

    return (
        <div>
            <CarouselSevices current={t("carousel_services.packages")} />
            <div className="content-packages-new">
                <div className="content-packages-colum-1">

                    <aside onClick={handleClick} style={{ cursor: 'pointer' }}>
                        <img src={banner5} className="img-pack-aside" alt="paquetes" />
                        <div className="info">
                            <div className="letter"></div>
                            <div
                                className="cardRegisterNowButtonContain"
                                style={{
                                    position: "relative",
                                    bottom: "0px",
                                }}
                            >
                                <Link
                                    to={"/TourismHealth"}
                                    style={{
                                        backgroundColor: "#00AFE8",
                                        fontSize: "24px",
                                        fontWeight: "900",
                                        borderRadius: "0px",
                                        width: "100%",
                                    }}
                                    className="cardRegisterNowButton"
                                >
                                    {t("common.see_more.uppercase")}
                                </Link>
                            </div>
                        </div>
                    </aside>
                </div>

                <div className="content-packages-colum-2">
                    <article>
                        <CustomSlider>
                            {photos.map((image, index) => {
                                return (
                                    <img
                                        className="section-image"
                                        src={image.img}
                                        key={index}
                                        alt={`${index + 1}`}
                                        style={{
                                            objectFit: "cover",
                                            height: "100%",
                                            width: "100%",
                                        }}
                                    />
                                );
                            })}
                        </CustomSlider>

                        <section className="search-packages-turisticos">
                            <h2>{t("packs.health.title")}</h2>
                            <p>
                                {t("packs.health.description")}
                            </p>
                            <SearchProcedures />
                        </section>
                    </article>
                </div>
            </div>


            <PackageSectionHealth
                inputValue={inputValue}
                selectValue={selectValue}
                seeker={false}
                dataPackage={dataPackage}
                numberPages={pagesNumber}
                loading={loading}
                currentPage={currentPage}
                handleNextPage={handleNextPage}
                handlePrevPage={handlePrevPage}
                setCurrentPage={setCurrentPage}
            />
            <AnyQuestions title={t("browse_home.have_doubt")} />
        </div>
    );
};

export default Packages;
