import React, { useEffect, useState } from "react";

import CarouselSevices from "../../share/components/CarouselServices/CarouselSevices";

import axios from "axios";
import CustomSlider from "./sliderImg/CustomSlider";

import { useLocation } from "react-router-dom";
import { formatMoney } from "../../../utils";

import toast, { Toaster } from "react-hot-toast";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "./stylesNew.css";
import { useTranslation } from "react-i18next";
import Recomendation from "../typePackages/Recomendation/Recomendation";
import Loading from "../../share/components/loading";
import AnyQuestions from "../../home/components/anyQuestion";
import ReturnPage from "../../share/components/return/ReturnPage";
import { IoClose } from "react-icons/io5";
import { MdCameraAlt, MdDateRange, MdOutlineLocationOn } from "react-icons/md";
import { FaUsers } from "react-icons/fa6";
import { v4 as uuidv4 } from 'uuid';
import SessionModal from "@/ui/views/session/components/SessionModal/SessionModal";
import { useDispatch, useSelector } from "react-redux";
import ModalReservePackage from "./ModalReservePackage";

const DetailPackages = () => {
  const imageUrls = [];

  const location = useLocation();
  const idPackage = location.pathname.split("/")[2];
  const [t] = useTranslation("global");
  const dispatch = useDispatch()

  const [selectedImage, setSelectedImage] = useState(imageUrls[0]);
  const [packaged, setPackaged] = useState();
  const [loading, setLoading] = useState(true);
  const [cleanDescription, setCleanDescription] = useState("");
  const [images, setImages] = useState([]);
  const [packsRecomendation, setPacksRecomendation] = useState([]);
  const [loadingMin, setLoadingMin] = useState(false);
  const [loadingFull, setLoadingFull] = useState(false);
  const [showAuthModal, setShowAuthModal] = useState(false);
  const [showReserveModal, setShowReserveModal] = useState(false);

  const notify = (message, icon) => {
    if (typeof message !== "string") {
      console.error("Invalid message type for toast:", message);
      message = "Error desconocido";
    }
  
    toast(message, {
      icon: icon,
    });
  };
  

  function getBaseUrl(url) {
    const parsedUrl = new URL(url);
    const baseUrl = `${parsedUrl.protocol}//${parsedUrl.host}`;
    return baseUrl;
  }

  function calcularDiasYNoches(comienzo, salida) {
    // Convertir las cadenas de fecha a objetos Date
    const fechaComienzo = new Date(comienzo);
    const fechaSalida = new Date(salida);
  
    // Validar que las fechas sean válidas
    if (isNaN(fechaComienzo) || isNaN(fechaSalida)) {
      throw new Error("Las fechas proporcionadas no son válidas.");
    }
  
    // Calcular la diferencia en milisegundos entre fechas
    const diferenciaEnMilisegundos = fechaSalida.setHours(0, 0, 0, 0) - fechaComienzo.setHours(0, 0, 0, 0);
  
    // Convertir la diferencia a días, sumando 1 para incluir el último día
    const diferenciaEnDias = Math.ceil(diferenciaEnMilisegundos / (24 * 60 * 60 * 1000)) + 1;
  
    // El número de noches es la diferencia en días menos uno
    const noches = Math.max(diferenciaEnDias - 1, 0);
  
    return {
      dias: diferenciaEnDias,
      noches: noches,
    };
  }
  
  

  const getPacksRecomendation = () => {
    const url = `${process.env.REACT_APP_URL_API_AIOP}api/packages`;
    axios
      .get(url)
      .then((response) => {
        setPacksRecomendation(response.data.packs);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const findServices = () => {
    axios
      .get(`${process.env.REACT_APP_URL_API_AIOP}api/packages/${idPackage}`)
      .then((response) => {
        setPackaged(response.data);
        setCleanDescription(response.data.pack.description);
        const imageUrls = response?.data?.pack?.images?.map(
          (image) => image.url
        );
        setImages([
          `${response.data.pack.image}`,
          ...imageUrls.map((url) => `pack/${url}`),
        ]);

        setTimeout(() => {
          setLoading(false);
          window.scrollTo({
            top: 0,
            behavior: "smooth",
          });
        }, 1000);
      })
      .catch((error) => {
        console.error("Error en la llamada a la API de planes", error);
        setLoading(false);
      });
  };

  const createLinkPay = async(amount, type) => {
    try{ 
        if (type === "min") {
          setLoadingMin(true);
        } else {
          setLoadingFull(true);
        }

        // Verify if there is an active session but send to login
        const authUserSession = await JSON.parse(localStorage.getItem("@authUserSession") || "{}");

        // Generate a randomly uuid of maximum 14 characters and only for payment
        const uuidSliced = uuidv4().slice(0, 14);

        const dataNew = {
          name: authUserSession?.user?.name || "",
          email: authUserSession?.user?.email || "",
          cel: authUserSession?.user?.prex_phone_public + authUserSession?.user?.phone_public || "",
          localizator: uuidSliced,
          type: "PackTurist",
          price: process.env.REACT_APP_URL_ENV === "staging" ? "200000" : amount,
          currency: "COP",
          url: `${getBaseUrl(window.location.href)}/packages/payment-reservation-response`,
        };

        const url = process.env.REACT_APP_URL_CREATE_LINK;

        const payment = await axios.post(url, dataNew)
        const packageId = window.location.pathname.split("/")[2];

        const tourismPackage = {
          ...payment.data,
          packageId: packageId,
          packageUrl: window.location.href
        };

        localStorage.setItem("data-pay-tourism-package", JSON.stringify(tourismPackage));
        localStorage.setItem("data-type-pay-tourism-package", type);
        localStorage.setItem("request-id-pay-tourism-package", payment.data?.requestId);

        window.location.href = await payment.data?.processUrl;
      }
      catch (error) {
        console.error(error);
        throw new Error (error);
      } finally {
        if (type === "min") {
          setLoadingMin(false);
        } else {
          setLoadingFull(false);
        }
      }
  };

  const handleReservation = async (amount, type) => {
    try {
      const authUserSession = await JSON.parse(localStorage.getItem("@authUserSession") || "{}");

      if (!authUserSession?.user?.id) {
        setShowAuthModal(true);
        return;
      }

      await createLinkPay(amount, type);
    } catch (error) {
      console.error(error);
      const message = t("packs.tourism.errors.payment_link_creation")
      notify(message, "❌");
    }
  };

  useEffect(() => {
    setLoading(true);
    findServices();
    getPacksRecomendation();
  }, [location.pathname]);

  useEffect(() => {
    if (packaged !== undefined) {
      setSelectedImage(`${packaged.url}/${images[0]}`);
    }
  }, [images]);

  const [imagesCarrusel, setImagesCarrusel] = useState([]);

  useEffect(() => {
    if (packaged && packaged.pack && packaged.pack.description) {
      const imgUrls = extractImages(packaged.pack.description);
      setImagesCarrusel(imgUrls);
    }
    console.log(imagesCarrusel);
  }, [packaged]);

  const extractImages = (htmlString) => {
    const parser = new DOMParser();
    const doc = parser.parseFromString(htmlString, "text/html");
    const imgElements = doc.querySelectorAll("img");
    const imgUrls = Array.from(imgElements).map((img) => img.src);
    return imgUrls;
  };

  const [show, setShow] = useState(false);

  // Estado para controlar si las imágenes se han cargado o no
  const [loadedImages, setLoadedImages] = useState(new Array(images.length).fill(false));

  // Estado para controlar si hay errores al cargar las imágenes
  const [imageErrors, setImageErrors] = useState(new Array(images.length).fill(false));

  // Función para manejar cuando una imagen se carga correctamente
  const handleImageLoad = (index) => {
    setLoadedImages(prevState => {
      // Clonar el estado anterior y actualizar el valor de la imagen correspondiente
      const newState = [...prevState];
      newState[index] = true;
      return newState;
    });
    setImageErrors(prevState => {
      // Clonar el estado anterior y marcar que no hay error en la imagen correspondiente
      const newState = [...prevState];
      newState[index] = false;
      return newState;
    });
  };

  // Función para manejar cuando una imagen no se carga correctamente
  const handleImageError = (index) => {
    setImageErrors(prevState => {
      // Clonar el estado anterior y marcar que hay un error en la imagen correspondiente
      const newState = [...prevState];
      newState[index] = true;
      return newState;
    });
  };

  return (
    <div>
      <CarouselSevices current={t("carousel_services.packages")} />
      <ReturnPage />

      <SessionModal
        activeSessionModal={showAuthModal}
        setActiveSessionModal={setShowAuthModal}
        url={`/packages`}
      />

      <div className="content-detail-pack-new">
        <header>
          <h1>{packaged?.pack?.name}</h1>
        </header>
        {loading == true ? (
          <Loading />
        ) : (
          <main>
            <article>
              {images.length == 0 ? (
                <p>{t("packs.tourism.detailed_pack.without_images")}</p>
              ) : images?.length == 1 ? (
                <>
                {(!loadedImages[0] || imageErrors[0]) && (
                    <div className='img-transport-skeleton2'>
                        <MdCameraAlt size={40} color="#c8c8c8" />
                    </div>
                )}
                <img
                    className="only-img w-auto h-full object-fill"
                    src={`${process.env.REACT_APP_URL_API_AIOP}storage/pack/${packaged.pack.image}`}
                    onClick={() =>
                        setSelectedImage(
                            `${process.env.REACT_APP_URL_API_AIOP}storage/pack/${packaged.pack.image}`
                        )
                    }
                    onLoad={() => handleImageLoad(0)}
                    onError={() => handleImageError(0)}
                    style={{ display: (loadedImages[0] && !imageErrors[0]) ? 'block' : 'none', maxHeight: "500px" }}
                    alt={`${1}`}
                />
            </>
            
              ) : (
                <CustomSlider>
                  {images?.map((image, index) => {
                    const imageUrl = index === 0
                      ? `${process.env.REACT_APP_URL_API_AIOP}storage/pack/${image}`
                      : `${process.env.REACT_APP_URL_API_AIOP}storage/${image}`;

                    return (
                      <div key={index} style={{ }}>
                        {(!loadedImages[index] || imageErrors[index]) && (
                          <div className='img-transport-skeleton'>
                            <MdCameraAlt size={40} color="#c8c8c8" />
                          </div>
                        )}
                        <img
                          className="section-image"
                          src={imageUrl}
                          onLoad={() => handleImageLoad(index)}
                          onError={() => handleImageError(index)}
                          alt={`${index + 1}`}
                          style={{
                            display: (loadedImages[index] && !imageErrors[index]) ? 'block' : 'none',
                            height: "100%",
                            width: "100%",
                            maxHeight: "500px",
                          }}
                        />
                      </div>
                    );
                  })}

                </CustomSlider>
              )}
              <div className="content-btn-actions-pack !max-h-max">
                <div className="camp">
                  <span>{t("packs.tourism.detailed_pack.price")}</span>
                  <h3 style={{ fontSize: "24px", fontWeight: "700" }}>
                    {packaged?.pack?.price
                      ? formatMoney(packaged?.pack?.price)
                      : t("packs.tourism.detailed_pack.not_exists")}
                    {packaged?.pack?.currency}
                  </h3>
                </div>
                <div className="camp-flex">
                  <MdDateRange size={30} color="var(--bluePrimary)" />
                  <div className="camp">
                    <span>{t("packs.tourism.detailed_pack.departure_and_return")}</span>
                    <h3>
                      {t("packs.tourism.detailed_pack.from_the")} {packaged?.pack?.start_date || 0} {t("packs.tourism.detailed_pack.to_the")}{" "}
                      {packaged?.pack?.due_date || 0}
                    </h3>
                    <h3>
                      {
                        calcularDiasYNoches(
                          packaged?.pack?.start_date,
                          packaged?.pack?.due_date
                        ).dias || 0
                      }{" "}
                      {t("packs.tourism.detailed_pack.days")} /{" "}
                      {
                        calcularDiasYNoches(
                          packaged?.pack?.start_date,
                          packaged?.pack?.due_date
                        ).noches || 0
                      }{" "}
                      {t("packs.tourism.detailed_pack.nights")}
                    </h3>
                  </div>
                </div>
                <div className="camp-flex">
                  <FaUsers size={25} color="var(--bluePrimary)" />
                  <div className="camp">
                    <span>{t("packs.tourism.detailed_pack.available_units")}</span>
                    <h3>
                      <span className="!text-gray-500">{packaged?.pack?.availables_units}</span>
                      <br />
                      <span className="!text-[8px] !text-red-500 mt-5">
                      {
                      packaged?.pack?.availables_units ? t("packs.tourism.detailed_pack.availability_established")
                       : t("packs.tourism.detailed_pack.without_units")}
                      </span>
                    </h3>
                    
                  </div>
                </div>

                <div className="camp-flex">
                  <MdOutlineLocationOn size={30} color="var(--bluePrimary)" />
                  <div className="camp">
                    <span>{t("packs.tourism.detailed_pack.destination")}</span>
                    <h3>{packaged?.pack?.destination}</h3>
                  </div>
                </div>
                
                {
                  packaged?.pack?.availables_units > 0 && (    
                  <div className="content-buttons-packs">
                    <button
                      disabled={packaged?.pack?.availables_units === 0}
                      className="btnCheckout !flex !justify-center !items-center"
                      onClick={() => setShowReserveModal(true)}
                    >
                      {t("packs.tourism.detailed_pack.reserve_now")}
                    </button>
                    {/* 
                    <button
                      disabled={packaged?.pack?.availables_units === 0}
                      className="btnCheckout !flex !justify-center !items-center"
                      onClick={() => addCart()}
                    >
                      {t("packs.tourism.detailed_pack.add_to_cart")}
                    </button>*/}
                  </div>
                  )
                }
              </div>
            </article>
            <aside>
              <div className="column-a">
                <div className="content-list-detail-pack">
                  <h3 className="titlet">{t("packs.tourism.detailed_pack.description")}:</h3>
                  <div
                    className="paragraphs-packages-images"
                    dangerouslySetInnerHTML={{
                      __html: packaged?.pack?.description
                        ? packaged?.pack?.description?.replace(
                          /<img/g,
                          '<img className="hidden-img-carr"'
                        )
                        : `<h4>${t("packs.tourism.detailed_pack.without_information")}</h4>`,
                    }}
                  />

                  <div style={{ cursor: "pointer" }}>
                    {imagesCarrusel.length >= 1 && (
                      <CustomSlider>
                        {imagesCarrusel?.map((imgUrl, index) => {
                          return (
                            <img
                              onClick={() => setShow(true)}
                              key={index}
                              className="section-image"
                              src={imgUrl}
                              alt={`packImage ${index + 1}`}
                              style={{
                                objectFit: "cover",
                                height: "100%",
                                width: "100%",
                                maxHeight: "500px",
                              }}
                            />
                          );
                        })}
                      </CustomSlider>
                    )}
                  </div>

                  <div
                    className={`${show
                      ? "modal-list-images-pack"
                      : "modal-list-images-pack close"
                      }`}
                    onClick={(e) => {
                      if (e.currentTarget === e.target) {
                        setShow(false);
                      }
                    }}
                  >
                    <div
                      className={`${show
                        ? "content-list-images-pack"
                        : "content-list-images-pack close"
                        }`}
                    >
                      <button
                        className="content-list-images-pack-button-close"
                        onClick={() => setShow(false)}
                      >
                        <IoClose size={20} />
                      </button>
                      {imagesCarrusel.length >= 1 && (
                        <CustomSlider>
                          {imagesCarrusel?.map((imgUrl, index) => {
                            return (
                              <img
                                key={index}
                                className="section-image"
                                src={imgUrl}
                                alt={`packImageBtn ${index + 1}`}
                              />
                            );
                          })}
                        </CustomSlider>
                      )}
                    </div>
                  </div>
                </div>
              </div>
              <div className="column-b">
                <div className="content-list-detail-pack">
                  <h3 className="titlet">{t("packs.tourism.detailed_pack.includes")}:</h3>
                  <div
                    className="paragraphs-packages"
                    dangerouslySetInnerHTML={{
                      __html: packaged?.pack?.include
                        ? packaged?.pack?.include
                        : `<h4>${t("packs.tourism.detailed_pack.without_information")}</h4>`,
                    }}
                  />
                  <h3 className="titlet">{t("packs.tourism.detailed_pack.not_included")}:</h3>
                  <div
                    className="paragraphs-packages"
                    dangerouslySetInnerHTML={{
                      __html: packaged?.pack?.not_include
                        ? packaged?.pack?.not_include
                        : `<h4>${t("packs.tourism.detailed_pack.without_information")}</h4>`,
                    }}
                  />
                </div>
              </div>
            </aside>
          </main>
        )}
      </div>
      <Recomendation article={packsRecomendation} />
      <AnyQuestions title={t("browse_home.have_doubt")} />

      <ModalReservePackage
        loadingMin={loadingMin}
        loadingFull={loadingFull}
        showModal={showReserveModal} 
        onClose={() => setShowReserveModal(false)}
        packaged={packaged}
        handleReservation={handleReservation}
      />

    </div>
  );
};

export default DetailPackages;
