import React, { useEffect, useState } from "react";
import SvgIcon, { iconList } from "../../../share/components/iconSVG";
import { IoIosArrowDown } from "react-icons/io";
import { FaCircleCheck } from "react-icons/fa6";
import "./styles.css";
import { useTranslation } from "react-i18next";

const PersonForm = ({ 
  index, 
  type, 
  paxes, 
  setPaxes,
  validate,
  setValidate,
  setSubmitData,
  submitData,
  accordionFather,
  setAccordionFather,
  person
}) => {
  const { t, i18n } = useTranslation("global");

  const [sex, setSex] = useState("");
  const [accordion, setAccordion] = useState(index === 1 && type === "person");
  const [nom, setNom] = useState("");
  const [email, setEmail] = useState("");
  const [tel, setTel] = useState("");
  const [lastName, setLastName] = useState("");
  const [age, setAge] = useState("");
  const [addHErr, setAddHErr] = useState(false);
  const [checkSuccess, setCheckSuccess] = useState(false);
  const [typeDocument, setTypeDocument] = useState("");
  const [Document, setDocument] = useState("");
  const [modalTypeDoc, setModalTypeDoc] = useState(false);
  const [focusedFields, setFocusedFields] = useState({});
  const [error, setError] = useState({
    nom: "",
    lastName: "",
    Document: "",
    typeDocument: "",
    tel: "",
    email: "",
    age: "",
    sex: "",
  });

  const validateNom = (nom) => {
    if (nom === "") {
      return t("hotels.reserve.required_field");
    }
  
    return "";
  };
  
  const validateLastName = (lastName) => {
    if (lastName === "") {
      return t("hotels.reserve.required_field");
    }
  
    return "";
  };

  
  const validateDocument = (Document) => {
    if (Document === "") {
      return t("hotels.reserve.required_field");
    }
  
    return "";
  }
  
  const validateTypeDocument = (typeDocument) => {
    if (typeDocument === "" || typeDocument === t("document_types.text")) {
      return t("hotels.reserve.required_field");
    }
  
    return "";
  }
  
  const validateTel = (tel) => {
    if(tel === "") {
      return t("hotels.reserve.required_field");
    } else if (isNaN(tel)) {
      return t("hotels.reserve.numeric_field");
    }
  
    return "";
  }
  
  const validateEmail = (email) => {
    const emailRegex = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/;
    if (email === "") {
      return t("hotels.reserve.required_field");
    } else if (!emailRegex.test(email) || /[\s]/.test(email)) {
      return t("hotels.reserve.invalid_field");;
    }
  
    return "";
  }
  
  const validateAge = (age) => {
    if (age === "") {
      return t("hotels.reserve.required_field");
    } else if (isNaN(age)) {
      return t("hotels.reserve.numeric_field");;
    }
  
    return "";
  }
  
  const validateSex = (sex) => {
    if(sex === "") {
      return t("hotels.reserve.required_field");
    }
  
    return "";
  }

  useEffect(() => {
    if (accordion) {
      setAccordionFather(`${type}-${index}`);
    }
  }, [accordion]);

  useEffect(() => {
    if (accordionFather === `${type}-${index}` && accordion) {
      setAccordion(true);
    }else{
      setAccordion(false);
    }
  }, [accordionFather]);

  const listTypeDoc = [
    { value: "CC", field: t("document_types.cc") },
    { value: "TI", field: t("document_types.ti") },
    { value: "PPN", field: t("document_types.ppn") },
    { value: "CE", field: t("document_types.ce") },
    { value: "RUT", field: t("document_types.rut") },
    { value: "NIT", field: t("document_types.nit") },
  ];

  const handleBlur = (field, value) => {
    let error = "";
    switch (field) {
      case "nom":
        error = validateNom(value);
        break;
      case "lastName":
        error = validateLastName(value);
        break;
      case "typeDocument":
        error = validateTypeDocument(value);
        break;
      case "Document":
        error = validateDocument(value);
        break;
      case "tel":
        error = validateTel(value);
        break;
      case "email":
        error = validateEmail(value);
        break;
      case "age":
        error = validateAge(value);
        break
      case "sex":
        error = validateSex(value);
        break;
      default:
        break;
      }
      setError((prev) => ({ ...prev, [field]: error }));

      if (error === "") {
        setValidate(true);
      }else{
        setValidate(false);
      }
  };

  const validateForm = () => {
    const nomError = validateNom(nom);
    const lastNameError = validateLastName(lastName);
    const DocumentError = validateDocument(Document);
    const typeDocumentError = validateTypeDocument(typeDocument);
    const telError = validateTel(tel);
    const emailError = validateEmail(email);
    const ageError = validateAge(age);
    const sexError = validateSex(sex);

    setError({
      nom: nomError,
      lastName: lastNameError,
      Document: DocumentError,
      typeDocument: typeDocumentError,
      tel: telError,
      email: emailError,
      age: ageError,
      sex: sexError,
    });

    if(type === "person" && (nomError || lastNameError || DocumentError || typeDocumentError || telError || emailError || ageError || sexError)) {
      return false;
    }

    return true;
  };

  useEffect(() => {
    if(submitData) {
      setSubmitData(false);
      setValidate(validateForm());
    }
  }, [submitData]);

  useEffect(() => {
    const hasErrors =
      (type === "person" &&
        (nom === "" ||
          lastName === "" ||
          sex === "" ||
          email === "" ||
          tel === "" ||
          age === "" ||
          Document === "" ||
          typeDocument === ""));
    
    if (hasErrors) {
      setAddHErr(true);
      setCheckSuccess(false);
      return;
    }
  
    setAddHErr(false);
  
    const newPaxes = {
      sex,
      name: nom,
      lastname: lastName,
      age,
      ...(type === "person" && {
        email,
        tel,
        doc: Document,
        typeDoc: typeDocument,
      }),
    };
  
    setPaxes((prevList) => {
      const existingIndex = prevList.findIndex(
        (pax) =>
          pax.doc === Document && pax.typeDoc === typeDocument
      );
  
      if (existingIndex !== -1) {
        // Si existe, editar
        const updatedList = [...prevList];
        updatedList[existingIndex] = newPaxes;
        return updatedList;
      } else {
        // Si no existe, agregar
        return [...prevList, newPaxes];
      }
    });
  
    setCheckSuccess(true);
  }, [
    sex,
    nom,
    lastName,
    age,
    email,
    tel,
    Document,
    typeDocument,
    type,
    setPaxes,
  ]);
  

  const getInputClass = (value, fieldName) =>
    addHErr && value === "" && focusedFields[fieldName] ? "input-error" : "";

  // const handleBlur = (fieldName) => {
  //   setFocusedFields((prev) => ({ ...prev, [fieldName]: true }));
  // };

  return (
    <div>
      <section
        className="accordion-detail-form-hotel"
        style={{
          backgroundColor: `${checkSuccess ? "var(--greenPrimary)" : ""}`,
        }}
        onClick={() => {
          if (person !== 1 ) {
            setAccordion(!accordion);
          }
        }}
      >
        <div className="typeTitle">
          <h2>
            Persona {index}
          </h2>
          {index === 1 && type === "person" && (
            <p>
              {t("hotels.reserve.responsible")}
            </p>
          )}
        </div>
        <span className="circle-icon-accordion">
          {checkSuccess ? (
            <FaCircleCheck color={"var(--white)"} size={20} />
          ) : accordion ? (
            <IoIosArrowDown
              style={{ rotate: "180deg" }}
              color={"var(--white)"}
              size={20}
            />
          ) : (
            <IoIosArrowDown color={"var(--white)"} size={20} />
          )}
        </span>
      </section>

      <div
        className={`${
          accordion ? "form-persons-pay-hotel active" : "form-persons-pay-hotel"
        }`}
      >
        <div className={ error.nom ? 'validate-input-voucher camp-form-person-hotel' : 'camp-form-person-hotel' }>
          <label htmlFor="">{t("hotels.reserve.fields.responsible.name.label")} *</label>
          <div>
            <input
              type="text"
              className="input-name"
              style={{ width: "100%" }}
              onChange={(e) => {
                setNom(e.target.value)
                handleBlur("nom", e.target.value)
              }}
              onBlur={(e) => handleBlur("nom", e.target.value)}
              placeholder={
                index === 1 && type === "person" ? 
                t("hotels.reserve.fields.responsible.name.placeholder") :
                t("hotels.reserve.fields.other_person.name.placeholder")
              }
            />
            { error.nom && <p className="span-simple-error">{error.nom}</p> }
          </div>
        </div>
        <div className={ error.lastName ? "validate-input-voucher camp-form-person-hotel" : "camp-form-person-hotel" }>
          <label htmlFor="">{t("hotels.reserve.fields.responsible.last_name.label")} *</label>
          <div>
            <input
              type="text"
              className="input-name"
              style={{ width: "100%" }}
              onChange={(e) => {
                setLastName(e.target.value)
                handleBlur("lastName", e.target.value)
              }}
              onBlur={(e) => handleBlur("lastName", e.target.value)}
              placeholder={
                index === 1 && type === "person"
                  ? t("hotels.reserve.fields.responsible.last_name.placeholder")
                  : t("hotels.reserve.fields.other_person.last_name.placeholder")
              }
            />
            { error.lastName && <p className="span-simple-error">{error.lastName}</p> }
          </div>
        </div>
        {type === "person" && (
          <div className="camp-form-person-hotel-doc">
            <label htmlFor="">{t("hotels.reserve.fields.responsible.document.label")} *</label>
            <div>
              <div className={ error.Document ? 'validate-input-voucher contain-inputs-selecteds' : 'contain-inputs-selecteds' }>
                <div className="select-type-doc" style={ error.typeDocument ? { border: "1px solid red", cursor: "pointer" } : {cursor: "pointer"} } onClick={() => setModalTypeDoc(!modalTypeDoc)}>
                  <div className="select-onclick-modal-type">
                    <span style={{ fontSize: 12 }}>
                      {
                        i18n.language === "en" && !typeDocument  ? "Document type" : i18n.language === "es" && !typeDocument ? "Tipo de documento" : typeDocument
                      }
                    </span>
                    <SvgIcon
                      color={"rgb(99, 99, 99)"}
                      name={iconList.IconArrowDown}
                      size={15}
                    />
                  </div>
                  {modalTypeDoc && (
                    <div className="modal-select-type-doc">
                      <ul>
                        {listTypeDoc.map((item, index) => (
                          <li
                            key={index}
                            onClick={() => {
                              setTypeDocument(item?.value);
                              setModalTypeDoc(false);
                              handleBlur("typeDocument", item?.value);
                            }}
                          >
                            {item?.field}
                          </li>
                        ))}
                      </ul>
                    </div>
                  )}
                </div>
                <input
                  type="text"
                  className="input-name"
                  onChange={(e) => {
                    setDocument(e.target.value);
                    handleBlur("Document", e.target.value);
                  }}
                  onBlur={(e) => handleBlur("Document", e.target.value)}
                  placeholder={
                    index === 1 && type === "person" ? t("hotels.reserve.fields.responsible.document.placeholder") : ""
                  }
                />
              </div>
              { error.Document && <p className="span-simple-error">{error.Document}</p> }
            </div>
          </div>
        )}

        { type === "person" && (
          <>
            <div className={ error.tel ? "validate-input-voucher camp-form-person-hotel" : "camp-form-person-hotel" }>
              <label htmlFor="">{t("hotels.reserve.fields.responsible.phone.label")} *</label>
              <div>
                <input
                  type="text"
                  className="input-name"
                  style={{ width: "100%" }}
                  onChange={(e) => {
                    setTel(e.target.value);
                    handleBlur("tel", e.target.value);
                  }}
                  onBlur={(e) => handleBlur("tel", e.target.value)}
                  placeholder={index === 1 ? t("hotels.reserve.fields.responsible.phone.placeholder") : ""}
                />
                { error.tel && <p className="span-simple-error">{error.tel}</p> }
              </div>
            </div>
            <div className={ error.email ? "validate-input-voucher camp-form-person-hotel" : "camp-form-person-hotel" }>
              <label htmlFor="">{t("hotels.reserve.fields.responsible.email.label")} *</label>
                <div>
                  <input
                    type="text"
                    className="input-name"
                    style={{ width: "100%" }}
                    onChange={(e) => {
                      setEmail(e.target.value);
                      handleBlur("email", e.target.value);
                    }}
                    onBlur={(e) => handleBlur("email", e.target.value)}
                    placeholder={index === 1 ? t("hotels.reserve.fields.responsible.email.placeholder") : ""}
                  />
                  { error.email && <p className="span-simple-error">{error.email}</p> }
                </div>
            </div>
          </>
        )}
        <div className={ error.age ? "validate-input-voucher camp-form-person-hotel" : "camp-form-person-hotel" }>
          <label htmlFor="">{t("hotels.reserve.fields.responsible.age.label")} *</label>
          <input
            type="text"
            className={getInputClass(age, "age")}
            onChange={(e) => {
              setAge(e.target.value);
              handleBlur("age", e.target.value);
            }}
            onBlur={(e) => handleBlur("age", e.target.value)}
            placeholder={
              index === 1 && type === "person" ? t("hotels.reserve.fields.responsible.age.placeholder") : t("hotels.reserve.fields.other_person.age.placeholder")
            }
          />
          { error.age && <p className="span-simple-error">{error.age}</p> }
        </div>
        <div className="camp-passenger-form-hotel">
          <label htmlFor="" style={{ fontWeight: "bold" }}>{t("hotels.reserve.fields.responsible.gender.label")} *</label>
          <div className="contain-sex-selected">
            <section onClick={() => {
                setSex("F")
                handleBlur("sex", "F")
              }} className="sex-selected-pay">
              <div>
                <span
                  style={{ backgroundColor: `${sex === "F" ? "var(--bluePrimary)" : ""}` }}
                ></span>
              </div>
              <p>{t("hotels.reserve.fields.responsible.gender.female")}</p>
            </section>
            <section className="sex-selected-pay" onClick={() => {
              setSex("M")
              handleBlur("sex", "M")
            }}>
              <div>
                <span
                  style={{ backgroundColor: `${sex === "M" ? "var(--bluePrimary)" : ""}` }}
                ></span>
              </div>
              <p>{t("hotels.reserve.fields.responsible.gender.male")}</p>
            </section>
          </div>
          { error.sex && <p className="span-simple-error">{ error.sex }</p> }
        </div>
      </div>
    </div>
  );
};

export default PersonForm;
