import React, { useState } from "react";
import { useLocation } from "react-router-dom";
import WellezyAiop from "../../../assets/presentation-logo.svg"; // SVG del logo
import HotelesSVG from "../../../assets/hotels-info.svg"; // Texto "Hoteles"
import TravelSVG from "../../../assets/travel-info.svg"; // Texto "Travel"
import ClusterSVG from "../../../assets/cluster-info.svg"; // Texto "Cluster"
import axios from "axios";
import toast from "react-hot-toast";
import { LuLoader2 } from "react-icons/lu";

const Banner = () => {
  const location = useLocation();

  const [isLoadingForm, setIsLoadingForm] = useState(false);
  // Variables de estado para el formulario
  const [formData, setFormData] = useState({
    nombre: "",
    apellido: "",
    email: "",
    numero: "",
    mensaje: "",
    type_presentation: "",
  });

  const notify = (title, icon) =>
    toast(title, {
      icon: icon,
    }
  );

  // Renderizado condicional del SVG del título y el fondo del degradado
  let titleSVG, backgroundStyle, blurFilter, buttonPrimary, buttonSecondary, buttonText, buttonBg, urlVerMas;
  
  if (location.pathname.includes("hotels")) {
    titleSVG = HotelesSVG;
    backgroundStyle =
      "radial-gradient(50% 50% at 50% 50%, rgba(16, 65, 114, 0.4) 0%, rgba(16, 65, 114, 0) 100%)";
    blurFilter = "blur(69.6px)";
    buttonPrimary = "bg-[#104172] border-[#104172] text-white";
    buttonSecondary = "border-[#104172] text-[#104172]";
    buttonBg = "#104172";
    buttonText = "#104172";
    urlVerMas = "https://view.genially.com/67bb99850df219649fd613c8/dossier-hoteles-wellezy";
    formData.type_presentation = "hotels";
  } else if (location.pathname.includes("travel")) {
    titleSVG = TravelSVG;
    backgroundStyle =
      "radial-gradient(50% 50% at 50% 50%, rgba(15, 104, 247, 0.6) 0%, rgba(12, 59, 201, 0) 100%)";
    blurFilter = "blur(58.8px)";
    buttonPrimary = "bg-[#0C3BC9] border-[#0C3BC9] text-white";
    buttonSecondary = "border-[#0C3BC9] text-[#0C3BC9]";
    buttonBg = "#0C3BC9";
    buttonText = "#0C3BC9";
    urlVerMas = "https://view.genially.com/67bc8af7e79d3c059ffd771c/dossier-agencias-de-viajes-wellezy";
    formData.type_presentation = "travel";
  } else if (location.pathname.includes("specialists")) {
    titleSVG = ClusterSVG;
    backgroundStyle =
      "radial-gradient(50% 50% at 50% 50%, rgba(1, 174, 232, 0.6) 0%, rgba(1, 174, 232, 0) 100%)";
    blurFilter = "blur(58.8px)";
    buttonPrimary = "bg-[#17AEE6] border-[#17AEE6] text-white";
    buttonBg = "#17AEE6";
    buttonSecondary = "border-[#17AEE6] text-[#17AEE6]";
    buttonText = "#17AEE6";
    urlVerMas = "https://view.genially.com/67b4c9c0b1c5c0444594a116/dossier-cluster-wellezy";
    formData.type_presentation = "specialists";
  }

  // Manejar cambios en los inputs
  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  // Enviar el formulario al backend
  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsLoadingForm(true);

    try {
      const url = `${process.env.REACT_APP_URL_API_AIOP}api/presentation-form`;
      await axios.post(url, formData);
      notify("Formulario enviado correctamente", "✅");
      setFormData({ nombre: "", apellido: "", email: "", numero: "", mensaje: "", type_presentation: formData.type_presentation });
    } catch (error) {
      notify("Error al enviar el formulario", "❌");
    } finally {
      setIsLoadingForm(false);
    }
  };

  return (
    <div className="relative w-full h-screen sm:overflow-x-hidden sm:overflow-y-auto md:overflow-hidden lg:overflow-hidden">
      {/* Degradado de fondo correctamente posicionado */}
      <div
        className="absolute hidden md:block" // Ocultamos en mobile
        style={{
          background: backgroundStyle,
          filter: blurFilter,
          width: "1627px",
          height: "1627px",
          left: "616px",
          top: "-98px",
          zIndex: "-1",
        }}
      ></div>

      {/* Desktop: Se mantiene igual, Mobile: Cambia a flex-col */}
      <div className="w-full h-full grid md:grid-cols-2 items-center px-6 md:px-24 gap-8">
        
        {/* Sección Izquierda */}
        <div className="relative mt-10 md:mt-0 lg:mt-0 flex flex-col items-center md:items-start space-y-8">
          {/* Logo (Centrado en mobile) */}
          <img
            src={WellezyAiop}
            alt="Logo Wellezy AIOP"
            className="w-40 md:w-56 lg:w-56 md:absolute md:top-[-150px]" // ✅ Mobile centrado, Desktop en su posición
          />

          {/* Texto en SVG (Centrado en mobile) */}
          <img src={titleSVG} alt="Título" className="w-80" />

          {/* Botones (Centrado en mobile) */}
          <div className="flex flex-col md:pt-10 md:flex-row space-y-4 md:space-y-0 md:space-x-6">
            <button
              className={`px-8 py-3 ${buttonPrimary} rounded-lg text-[18px] font-medium w-[166px] h-[60px] shadow-md`}
              onClick={() => window.open(urlVerMas, "_blank")}
            >
              Ver más
            </button>
            <button
              className={`px-[28px] py-[16px] ${buttonSecondary} rounded-lg text-[18px] font-medium w-[169px] h-[60px] hover:shadow-lg shadow-md`}
              onClick={() => window.open("https://wellezy.com/contact", "_blank")}
            >
              Contactarme
            </button>
          </div>
        </div>

        {/* Sección Derecha - Formulario (Ajuste en mobile) */}
        <div className="relative w-full max-w-[450px] bg-white bg-opacity-90 backdrop-blur-xl p-6 md:p-10 rounded-xl shadow-lg mx-auto">
          <h3 className="text-[28px] md:text-[36px] font-bold text-[#104172] text-center">
            ¡Únete a Nosotros!
          </h3>
          <p className="text-[#000] text-[16px] md:text-[18px] text-center leading-6 mt-2">
            Aprovecha los grandes beneficios que tenemos para ti.
          </p>

          {/* Formulario */}
          <form className="mt-6 space-y-4 mb-10" onSubmit={handleSubmit}>
            <input
              name="nombre"
              onChange={handleChange}
              value={formData.nombre}
              className="w-full p-3 border rounded-lg text-lg shadow-md border-gray-300"
              type="text"
              placeholder="Nombre"
            />
            <input
              name="apellido"
              onChange={handleChange}
              value={formData.apellido}
              className="w-full p-3 border rounded-lg text-lg shadow-md border-gray-300"
              type="text"
              placeholder="Apellido"
            />
            <input
              name="email"
              onChange={handleChange}
              value={formData.email}
              className="w-full p-3 border rounded-lg text-lg shadow-md border-gray-300"
              type="email"
              placeholder="Email"
            />
            <input
              name="numero"
              onChange={handleChange}
              value={formData.numero}
              className="w-full p-3 border rounded-lg text-lg shadow-md border-gray-300"
              type="text"
              placeholder="Número de contacto"
            />
            <textarea
              name="mensaje"
              onChange={handleChange}
              value={formData.mensaje}
              className="w-full p-3 border rounded-lg text-lg shadow-md border-gray-300"
              placeholder="Mensaje"
            ></textarea>
            <div className="flex items-center gap-2">
              <input type="checkbox" />
              <span className="text-md text-gray-600">
                Estoy de acuerdo con la{" "}
                <a
                  href="https://wellezy.com/privacy-policies"
                  target="_blank"
                  rel="noopener noreferrer"
                  className="text-blue-900 underline"
                >
                  política de privacidad
                </a>
              </span>
            </div>
            <button className={`w-full py-4 ${buttonPrimary} text-white rounded-lg text-lg ${formData.mensaje === "" || formData.email === "" || formData.apellido === "" || formData.nombre === "" || formData.numero === "" ? "opacity-50 cursor-not-allowed" : ""}`} type="submit" disabled={formData.mensaje === "" || formData.email === "" || formData.apellido === "" || formData.nombre === "" || formData.numero === ""}>
              { isLoadingForm ? (
                <div className="flex justify-center">
                  <LuLoader2 size={20} className="flex justify-center" />
                </div>
              ) : (
                <div>
                  Enviar
                </div>
              )}
              
            </button>
          </form>
        </div>
      </div>
    </div>
  );
};

export default Banner;
