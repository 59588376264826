import  { useState } from 'react'


const UseRecapchat = () => {

    const [captchaValue, setCaptchaValue] = useState(null);
    const [captchaVerified, setCaptchaVerified] = useState(false);
    const siteKey = '6Ld8dPAqAAAAAJ_G8RiP4sXFcrEW4ci_IwfRds6o';

    const verifyCaptcha =  () => {
        if (captchaValue) {
            return true
        } else {
            return false
        }
    };

    

    const onChangeCaptcha = (value) => {
        setCaptchaValue(value);
    };

    return {
        verifyCaptcha,
        captchaValue,
        siteKey,
        setCaptchaValue,
        captchaVerified,
        onChangeCaptcha
    }
}

export default UseRecapchat