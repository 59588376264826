import { useEffect, useState } from "react";
import { IoIosCloseCircleOutline } from "react-icons/io";
import { FaCheckCircle, FaTimesCircle } from "react-icons/fa";
import { MdCheck, MdInfo, MdWarning } from "react-icons/md";
import { useTranslation } from "react-i18next";
import { LuLoader2 } from "react-icons/lu";
import CustomPaymentModal from "./CustomPaymentModal";

const ModalPaymentCenter = ({ item, handleClose, handlePayment, isLoadingMinimumPayment, handleOnSubmit, isLoadingCustomPayment }) => {
  const [details, setDetails] = useState(null);
  const [showInnerModal, setShowInnerModal] = useState(false);
  const [innerModalData, setInnerModalData] = useState(null);
  const [timeRemaining, setTimeRemaining] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);

  const { t } = useTranslation("global");

  useEffect(() => {
    if (item) {
      const parsedDetails = {
        flights: item.flights ? JSON.parse(item.flights) : [],
        hotels: item.hotels ? JSON.parse(item.hotels) : [],
        extras: item.extras ? JSON.parse(item.extras) : { other_amounts: [], health_amounts: [] },
      };
  
      setDetails(parsedDetails);
  
      const createdAt = new Date(item.created_at);
      const expirationTime = createdAt.getTime() + 24 * 60 * 60 * 1000;
  
      // Función para calcular el tiempo restante
      const calculateTimeRemaining = () => {
        const now = new Date().getTime();
        const distance = expirationTime - now;
        if (distance < 0) {
          setTimeRemaining(0);
        } else {
          const hours = Math.floor((distance % (24 * 60 * 60 * 1000)) / (60 * 60 * 1000));
          const minutes = Math.floor((distance % (60 * 60 * 1000)) / (60 * 1000));
          const seconds = Math.floor((distance % (60 * 1000)) / 1000);
          setTimeRemaining(`${hours}h ${minutes}m ${seconds}s`);
        }
      };
  
      // Ejecutar inmediatamente al montar el componente
      calculateTimeRemaining();
  
      // Ejecutar por primera vez después de 1 segundo
      const initialTimeout = setTimeout(() => {
        calculateTimeRemaining();
        // Luego, establecer un intervalo cada 10 minutos (600,000 ms)
        const interval = setInterval(calculateTimeRemaining, 600000); 
        return () => clearInterval(interval);
      }, 1000);
  
      return () => clearTimeout(initialTimeout);
    }
  }, [item]);
  

  if (!details) return null;

  const calculateSavings = () => {
    return item.total_pay - item.amount_pay; 
  };
  
  const getTimeRemaining = () => {
    const createdAt = new Date(item.created_at);
    const expiryTime = new Date(createdAt.getTime() + 24 * 60 * 60 * 1000); // 24 horas después
    const now = new Date();
    const timeDiff = expiryTime - now;
  
    if (timeDiff <= 0) return "0h 0m 0s"; 
  
    const hours = Math.floor(timeDiff / (1000 * 60 * 60));
    const minutes = Math.floor((timeDiff % (1000 * 60 * 60)) / (1000 * 60));
    const seconds = Math.floor((timeDiff % (1000 * 60)) / 1000);
  
    return `${hours}h ${minutes}m ${seconds}s`;
  };

  const formatPriceCOP = (price) => {
    return `${new Intl.NumberFormat("es-CO", {
      style: "currency",
      currency: "COP",
      minimumFractionDigits: 0,
      maximumFractionDigits: 0,
    }).format(price)} COP`;
  };

  const { flights, hotels, extras } = details;

  const openInnerModal = (data) => {
    setInnerModalData(data);
    setShowInnerModal(true);
  };

  const closeInnerModal = () => {
    setShowInnerModal(false);
    setInnerModalData(null);
  };

  const calculateMinimumPayment = () => {
    const flightPrice = flights.length ? flights[0].price : 0;
    const hotelPrice = hotels.length ? hotels[0].price : 0;
    const minimumPayment = flightPrice + hotelPrice + 2000000;
    return minimumPayment;
  };
  
  const toggleCustomPaymentModal = () => {
    setIsModalOpen(!isModalOpen);
  }

  const handleSubmit = async (item, amount) => {
    await handleOnSubmit(item, amount);
  };

  const calculateRemainingAmount = () => {
    if (!item) return;
  
    const paymentsArray = JSON.parse(item.payments || '[]');
    const totalPaid = paymentsArray.reduce((sum, payment) => sum + (payment.price ? Math.round(Number(payment.price)) : 0), 0);
  
    const createdAt = new Date(item.created_at);
    const firstPaymentDate = paymentsArray.length > 0 ? new Date(paymentsArray[0].created_at) : null;
  
    const expirationTime = createdAt.getTime() + 24 * 60 * 60 * 1000;

    const now = new Date().getTime();
    const distance = expirationTime - now;

    const baseAmount = (distance < 0) ? item.total_pay : item.amount_pay;
  
    if (!item.payments) return baseAmount;

    const remaining = baseAmount - totalPaid;
    return Math.round(remaining / 1000) * 1000; // Redondear al múltiplo de 1000
  };

  const verityIfPaymentsHasLength = () => {
    if (!item || !item.payments) return false;
  
    const paymentsArray = JSON.parse(item.payments || '[]');
    return paymentsArray.length > 0;
  }
  

  return (
    <div className="fixed inset-0 bg-gray-900 bg-opacity-50 flex items-center justify-center z-50">
      <div className="bg-white rounded-lg shadow-lg max-w-4xl w-full max-h-[80vh] overflow-y-auto p-6 customScroll">
        {/* Header */}
        <div className="flex justify-between items-center border-b pb-4">
          <h2 className="text-lg font-bold text-gray-800">{t("payment_hub.payment_details")} - # {item.id}</h2>
          <IoIosCloseCircleOutline
            onClick={handleClose}
            className="text-2xl text-gray-600 cursor-pointer hover:text-gray-800"
          />
        </div>

        {
          timeRemaining === null ? (
            <div className="flex justify-center">
              <LuLoader2 size={20} className="flex justify-center" />
            </div>
          ) :
          (
            <>
              {/* Content */}
              <div className="space-y-6 mt-4">
                {item.status === "pending" && (
                  <div className="flex gap-2 items-center bg-yellow-100 text-yellow-800 p-2 rounded-md mb-4">
                    <MdWarning size={20} className="text-yellow-800" /> 
                    {timeRemaining === null 
                      ? t("payment_hub.loading") 
                      : timeRemaining !== 0 
                        ? `${t("payment_hub.expire_amounts_date")} ${timeRemaining}` 
                        : `${t("payment_hub.expired_amounts")}`}
                  </div>
                )}
                

                {/* Vuelo */}
                <div>
                  <h3 className="text-lg font-semibold text-gray-700">
                    {t("payment_hub.flight")} - 
                    <button
                      onClick={() => openInnerModal({ type: "flight", data: flights })}
                      className="text-customBlue underline cursor-pointer hover:text-customHoverBlue ml-2"
                    >
                      {t("payment_hub.flight_details")}
                    </button>
                  </h3>
                  <table className="w-full border-collapse border border-gray-200">
                    <thead>
                      <tr>
                        <th className="border border-gray-300 p-2 text-left">{t("payment_hub.price_to_pay")}</th>
                        <th className="border border-gray-300 p-2 text-center">{t("payment_hub.included_in_quote")}</th>
                      </tr>
                    </thead>
                    <tbody>
                      {flights.length > 0 ? (
                        flights.map((flight, index) => (
                          <tr key={index} className="border-b border-gray-200">
                            <td className="border border-gray-300 p-2">{formatPriceCOP(flight.price)}</td>
                            <td className="border border-gray-300 p-2 text-center">
                              {flight.is_checked && timeRemaining ? (
                                <FaCheckCircle className="text-green-500 text-xl mx-auto" />
                              ) : (
                                <FaTimesCircle className="text-red-500 text-xl mx-auto" />
                              )}
                            </td>
                          </tr>
                        ))
                      ) : (
                        <tr>
                          <td colSpan="2" className="text-gray-500 p-2">
                            {t("payment_hub.no_flight")}
                          </td>
                        </tr>
                      )}
                    </tbody>
                  </table>
                </div>

                {/* Hotel */}
                <div>
                  <h3 className="text-lg font-semibold text-gray-700">{t("payment_hub.hotel")} -
                  <button
                      onClick={() => openInnerModal({ type: "hotel", data: hotels })}
                      className="text-customBlue underline cursor-pointer hover:text-customHoverBlue ml-2"
                    >
                      {t("payment_hub.hotel_details")}
                    </button>
                  </h3>
                  <table className="w-full border-collapse border border-gray-200">
                    <thead>
                      <tr>
                        <th className="border border-gray-300 p-2 text-left">{t("payment_hub.price_to_pay")}</th>
                        <th className="border border-gray-300 p-2 text-center">{t("payment_hub.included_in_quote")}</th>
                      </tr>
                    </thead>
                    <tbody>
                      {hotels.length > 0 ? (
                        hotels.map((hotel, index) => (
                          <tr key={index} className="border-b border-gray-200">
                            <td className="border border-gray-300 p-2">{formatPriceCOP(hotel.price)}</td>
                            <td className="border border-gray-300 p-2 text-center">
                              {hotel.is_checked && timeRemaining ? (
                                <FaCheckCircle className="text-green-500 text-xl mx-auto" />
                              ) : (
                                <FaTimesCircle className="text-red-500 text-xl mx-auto" />
                              )}
                            </td>
                          </tr>
                        ))
                      ) : (
                        <tr>
                          <td colSpan="2" className="text-gray-500 p-2">
                            {t("payment_hub.no_hotel")}
                          </td>
                        </tr>
                      )}
                    </tbody>
                  </table>
                </div>

                {/* Montos Adicionales */}
                <div>
                  <h3 className="text-lg font-semibold text-gray-700">{t("payment_hub.additional_amounts")}</h3>
                  <table className="w-full border-collapse border border-gray-200">
                    <thead>
                      <tr>
                        <th className="border border-gray-300 p-2 text-left">{t("payment_hub.price_to_pay")}</th>
                        <th className="border border-gray-300 p-2 text-center">{t("payment_hub.included_in_quote")}</th>
                      </tr>
                    </thead>
                    <tbody>
                      {extras.other_amounts.length > 0 ? (
                        extras.other_amounts.map((amount, index) => (
                          <tr key={index} className="border-b border-gray-200">
                            <td className="border border-gray-300 p-2">
                              {amount.name}: {formatPriceCOP(amount.value)}
                            </td>
                            <td className="border border-gray-300 p-2 text-center">
                              {amount.is_checked && timeRemaining ? (
                                <FaCheckCircle className="text-green-500 text-xl mx-auto" />
                              ) : (
                                <FaTimesCircle className="text-red-500 text-xl mx-auto" />
                              )}
                            </td>
                          </tr>
                        ))
                      ) : (
                        <tr>
                          <td colSpan="2" className="text-gray-500 p-2">
                            {t("payment_hub.no_additional_amounts")}
                          </td>
                        </tr>
                      )}
                    </tbody>
                  </table>
                </div>

                {/* Montos de Salud */}
                <div>
                  <h3 className="text-lg font-semibold text-gray-700">{t("payment_hub.health_amounts")}</h3>
                  <table className="w-full border-collapse border border-gray-200">
                    <thead>
                      <tr>
                        <th className="border border-gray-300 p-2 text-left">{t("payment_hub.price_to_pay")}</th>
                        <th className="border border-gray-300 p-2 text-center">{t("payment_hub.included_in_quote")}</th>
                      </tr>
                    </thead>
                    <tbody>
                      {extras.health_amounts.length > 0 ? (
                        extras.health_amounts.map((amount, index) => (
                          <tr key={index} className="border-b border-gray-200">
                            <td className="border border-gray-300 p-2">
                              {amount.name}: {formatPriceCOP(amount.value)}
                            </td>
                            <td className="border border-gray-300 p-2 text-center">
                              {amount.is_checked && timeRemaining ? (
                                <FaCheckCircle className="text-green-500 text-xl mx-auto" />
                              ) : (
                                <FaTimesCircle className="text-red-500 text-xl mx-auto" />
                              )}
                            </td>
                          </tr>
                        ))
                      ) : (
                        <tr>
                          <td colSpan="2" className="text-gray-500 p-2">
                            {t("payment_hub.no_health_amounts")}
                          </td>
                        </tr>
                      )}
                    </tbody>
                  </table>
                </div>

                <div className="flex justify-center gap-4 text-center items-center">
                  {/* Monto Total */}
                  <div>
                    <h3 className="text-lg font-semibold text-gray-700">{t("payment_hub.total_quotation_amount")}</h3>
                    <p className="text-customBlue text-xl font-bold">{formatPriceCOP(item.total_pay)}</p>
                  </div>

                  {/* Monto a Pagar */}
                  { timeRemaining !== 0 && (
                    <div>
                      <h3 className="text-lg font-semibold text-gray-700">{t("payment_hub.total_payment_amount")}</h3>
                      <p className="text-customBlue text-xl font-bold">{formatPriceCOP(item.amount_pay)}</p>
                    </div>
                    )
                  }

                  <div>
                    <h3 className="text-lg font-semibold text-gray-700">{t("payment_hub.remaining_amount")}</h3>
                    <p className="text-customBlue text-xl font-bold">{formatPriceCOP(calculateRemainingAmount())}</p>
                  </div>
                </div>
                
                {timeRemaining === null 
                    ? t("payment_hub.loading") 
                    : (item.status === "pending" && timeRemaining !== 0) ? (  
                  <div className="flex justify-center gap-2 items-center bg-blue-100 text-blue-800 p-2 rounded-md my-4">
                    <MdInfo size={20} color="text-blue-800" /> 
                    <span>
                      {t("payment_hub.paying_limit")} <strong>{getTimeRemaining()}</strong> {t("payment_hub.you_save")} <strong>{formatPriceCOP(calculateSavings())}</strong>!
                    </span>
                  </div>
                ) : ((item.status === "completed") && timeRemaining !== 0) && (
                    <div className="flex justify-center gap-2 items-center bg-blue-100 text-green-800 p-2 rounded-md my-4">
                      <MdCheck size={20} color="text-green-800" /> 
                      <span>
                        {t("payment_hub.you_saved")} <strong>{formatPriceCOP(calculateSavings())}</strong>!
                      </span>
                    </div>
                  )
                }

                {
                  (item.status !== "completed") && (
                    <div className="mt-4 flex flex-col gap-4">
                      {
                        !verityIfPaymentsHasLength() && (
                        <button
                          onClick={() => handlePayment(item, calculateMinimumPayment())}
                          className="bg-customBlue text-white px-4 py-2 rounded-lg hover:bg-customHoverBlue text-center"
                        >
                          {
                            isLoadingMinimumPayment ? (
                              <div className="flex justify-center">
                                <LuLoader2 size={20} className="flex justify-center" />
                              </div>
                            ) : (
                              <div>
                                {t("payment_hub.min_payment")}: {formatPriceCOP(calculateMinimumPayment())}
                              </div>
                            )
                          }
                        </button>
                      )}


                      <button
                        onClick={() => toggleCustomPaymentModal()}
                        className="bg-customBlue text-white px-4 py-2 rounded-lg hover:bg-customHoverBlue text-center"
                      >
                        {t("payment_hub.custom_payment")}
                      </button>
                    </div>
                  )
                }

              </div>
            </>
          )
        }
      </div>

      {/* Inner Modal */}
      {showInnerModal && innerModalData && (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-60">
          <div className="bg-white rounded-lg shadow-lg max-w-xl w-full p-6">
            <div className="flex justify-between items-center pb-4 border-b">
              <h2 className="text-lg font-bold text-gray-800">
                {innerModalData.type === "flight" ? t("payment_hub.flight_detail") : t("payment_hub.hotel_detail")}
              </h2>
              <IoIosCloseCircleOutline
                onClick={closeInnerModal}
                className="text-2xl text-gray-600 cursor-pointer hover:text-gray-800"
              />
            </div>
            <div className="mt-4">
              { innerModalData.data.length > 0 && (
                  innerModalData.data.map((detail, index) => (
                    <div key={index} className="mb-4 border-b pb-4">
                      {innerModalData.type === "flight" && detail.data && (
                        <div>
                          <p>{t("payment_hub.from")}: {detail.data.from}</p>
                          <p>{t("payment_hub.to")}: {detail.data.to}</p>
                          <p>{t("payment_hub.departure")}: {detail.data.departure}</p>
                          <p>{t("payment_hub.arrival")}: {detail.data.arrival}</p>
                          <p>{t("payment_hub.price")}: {formatPriceCOP(detail.data.price)}</p>
                        </div>
                      )}
                      {innerModalData.type === "hotel" && detail.data && (
                        <div>
                          <p>{t("payment_hub.hotel")}: {detail.data.hotel}</p>
                          <p>{t("payment_hub.destination")}: {detail.data.destination}</p>
                          <p>{t("payment_hub.arrival")}: {detail.data.arrival}</p>
                          <p>{t("payment_hub.departure")}: {detail.data.departure}</p>
                          <p>{t("payment_hub.price")}: {formatPriceCOP(detail.data.price)}</p>
                        </div>
                      )}
                    </div>
                  ))
                )
              }
            </div>
          </div>
        </div>
      )}

      { isModalOpen && (
        <CustomPaymentModal 
          item={item} 
          open={toggleCustomPaymentModal} 
          onClose={toggleCustomPaymentModal} 
          onSubmit={handleSubmit} 
          loadingLink={isLoadingCustomPayment}
        />
        )
      }

    </div>
  );
};

export default ModalPaymentCenter;
