import React, { useEffect } from "react";
import CarouselSevices, {
  servicesTravel,
} from "../../share/components/CarouselServices/CarouselSevices";
import "./styles.css";
// import usePassengerInformationForm from "../../../viewModels/flights/passenger.information.form.viewModel";
import ModalContainer from "../../share/components/modalContainer";
import UseMakeReservation from "../../../viewModels/hotels/UseMakeReservation";
// import { useTranslation } from "react-i18next";
import PersonForm from "./PersonForm/PersonForm";
import SendInvoices from "./SendInvoce/SendInvoices";
import DetailPay from "./detailPay/DetailPay";
import DetailBuy from "./detailBuy copy/DetailBuy";
import Loading from "../../share/components/loading";
import { FaCircleCheck } from "react-icons/fa6";
import TextComponent from "../../share/components/fonts/TextComponent";
import ReturnPage from "../../share/components/return/ReturnPage";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { setDate } from "date-fns";
import AnyQuestions from "../../home/components/anyQuestion";
import Recapchat from "../../share/components/recapchat/Recapchat";
import UseRecapchat from "@/Hooks/useRecapchat/UseRecapchat";
import UseMakeReservationAffiliate from "@/ui/viewModels/hotels/UseMakeReservationAffiliate";
import { CircularProgress } from "@material-ui/core";

const MakeReservationAffiliate = () => {

  const {
    verifyCaptcha,
    onChangeCaptcha,
    siteKey
  } = UseRecapchat()

  const {
    paxes,
    setPaxes,
    cantAcomodationAdult,
    createReservation,
    state,
    cantAcomodationChild,
    errorReservation,
    setErrorReservation,
    loadingReservation,
    setLoadingReservation,
    loadingRespose,
    reservation,
    functionNavigatePaceToPay,
    verifyCaptchaErr,
    personsTotal,
    loadingPay
  } = UseMakeReservationAffiliate(verifyCaptcha);

  const { t, i18n } = useTranslation("global");

  const persons = [];
  const mountRoom = JSON.parse(localStorage.getItem("mountRoom"));

  const [errorForm, setErrorForm] = useState({});
  const [validate, setValidate] = useState(false);
  const [submitData, setSubmitData] = useState(false);
  const [accordionFather, setAccordionFather] = useState("person-1");

  const validateForm = () => {
    const errors = {};
    paxes.forEach((pax, index) => {
      if (pax.type === "adult") {
        if (!pax.name) errors[`nom${index}`] = true;
        if (!pax.lastname) errors[`lastName${index}`] = true;
        if (!pax.sex) errors[`sex${index}`] = true;
        if (!pax.email) errors[`email${index}`] = true;
        if (!pax.tel) errors[`tel${index}`] = true;
        if (!pax.age) errors[`age${index}`] = true;
        if (!pax.doc) errors[`Document${index}`] = true;
        if (pax.typeDoc === "Tipo Documento") errors[`typeDoc${index}`] = true;
      }
      // } else if (pax.type === "child") {
      //   if (!pax.name) errors[`nom${index}`] = true;
      //   if (!pax.lastname) errors[`lastName${index}`] = true;
      //   if (!pax.sex) errors[`sex${index}`] = true;
      //   if (!pax.age) errors[`age${index}`] = true;
      // }
    });
    setErrorForm(errors);
    return Object.keys(errors).length === 0;
  };

  const handleReservation = () => {
    setSubmitData(true);

    if (validate) {
      createReservation();
    }
  };

  function formatearCantidad(cantidad) {
    const cantidadFormateada = cantidad?.toLocaleString("es", {
      style: "currency",
      currency: "COP",
      minimumFractionDigits: 0,
      maximumFractionDigits: 0,
    });

    return cantidadFormateada;
  }


  for (let index = 0; index < personsTotal; index++) {
    persons?.push(
      <PersonForm
        index={index + 1}
        key={index}
        type={"person"}
        paxes={paxes}
        setPaxes={setPaxes}
        setError={setErrorForm}
        validate={validate}
        setValidate={setValidate}
        submitData={submitData}
        setSubmitData={setSubmitData}
        accordionFather={accordionFather}
        setAccordionFather={setAccordionFather}
        person={personsTotal}
      />
    );
  }



  return (
    <div>
      <CarouselSevices current={servicesTravel[1].title} />
      <ReturnPage />
      <div className="contain-hotel-checkout">
        <div className="contain-hotel-pay">
          {loadingRespose ? (
            <Loading size={90} />
          ) : (
            <ModalContainer
              show={loadingReservation}
              hiddsetActiveChangeServiceen={setLoadingReservation}
              sizeWidth={50}
              sizeHeight={50}
              width={650}
            >
              <div className="content-modal-card conten-modal-prereserve">
                <div className="content-modal-card-title">
                  <h2></h2>
                </div>
                <h3>{t("hotels.reserve.information")}</h3>
                <ul className="list-content-modal-card list-modal-preserve-modal">
                  <li>
                    <span className="label-content-modal-card">{t("hotels.reserve.hotel")} id: </span>
                    <span className="value-content-modal-card">{reservation?.hotel_id}</span>
                  </li>
                  <li>
                    <span className="label-content-modal-card">{t("hotels.reserve.code")}: </span>
                    <span className="value-content-modal-card">{reservation?.id}</span>
                  </li>
                  <li>
                    <span className="label-content-modal-card">{t("hotels.reserve.taker")}: </span>
                    <span className="value-content-modal-card">{reservation?.nombre_titular}</span>
                  </li>
                  <li>
                    <span className="label-content-modal-card">{t("hotels.reserve.email")}: </span>
                    <span className="value-content-modal-card">{reservation?.correo}</span>
                  </li>
                </ul>
                <div className="price-content-modal-card">
                  <span className="value-content-modal-card">{t("hotels.reserve.total")}</span>
                  <span style={{ color: "#004274 !important", fontSize: 18 }}>
                    <b>
                      $ {new Intl.NumberFormat("es-ES", {
                        style: "currency",
                        currency: "COP",
                        minimumFractionDigits: 0,
                        maximumFractionDigits: 0,
                      }).format(reservation?.monto)}
                    </b>
                  </span>
                </div>
                <div className="content-btn-reserve-modal-prereserve">
                  <button onClick={functionNavigatePaceToPay} className="btn-reserve-hotel-modal-prereserve">
                    {
                      loadingPay ?
                        <CircularProgress size={20} color="white" />
                        :
                        "Pagar"
                    }

                  </button>
                </div>
              </div>
            </ModalContainer>
          )}

          <div className="layout-hotel-pay">
            <section className="main-hotel-pay">
              <TextComponent size={"md"} fontWeight="bold" tag="h3">
                {t("hotels.reserve.title")}
              </TextComponent>

              <TextComponent size={"md2"} fontWeight="bold" tag="h4">
                {t("hotels.reserve.subtitle")}
              </TextComponent>

              {persons.map((index) => (
                index
              ))}

              <SendInvoices />
            </section>

            <section className="aside-hotel-pay">
              <div className="aside-hotel-pay-detail">
                <DetailPay
                  acomodation={personsTotal}
                  total={state?.price}
                  taxes={0}
                />
                <DetailBuy
                  // canceledDay={
                  //   state?.listReserve[0]?.gastos?.gasto?.dias_antelacion
                  // }
                  // lat={state?.dataHab?.lat}
                  // lon={state?.dataHab?.lon}
                  hab={state?.hab}
                  nom={state?.hotel?.name}
                  stars={state?.hotel?.category}
                  // arrival={state?.arrival}
                  // departure={state?.departure}
                  address={state?.hotel?.direction}
                  checkIn={state?.hotel?.check_in}
                  checkOut={state?.hotel?.check_out}
                  mountRoom={mountRoom}
                />
              </div>

              <div className="content-button-reservation-hotel">
                <div>
                  {i18n.language === "es" ? (
                    <Recapchat language="es" siteKey={siteKey} onChangeCaptcha={onChangeCaptcha} />
                  ) : (
                    <Recapchat language={i18n.language} siteKey={siteKey} onChangeCaptcha={onChangeCaptcha} />
                  )}

                  {verifyCaptchaErr && (
                    <TextComponent size={"md2"} color="var(--redPrimary)">
                      {t("hotels.reserve.verify_recaptcha")}
                    </TextComponent>
                  )}
                </div>

                <TextComponent size={"md2"}>
                  {t("hotels.reserve.total_pay")}:{" "}
                  {/* <span>$ {formatearCantidad(state?.price)}</span> */}
                </TextComponent>
                <button onClick={handleReservation}>
                  <FaCircleCheck size={20} color="#fff" />
                  {t("hotels.reserve.button_text")}
                </button>
              </div>
            </section>
          </div>
        </div>
      </div>
      <AnyQuestions title={t("browse_home.have_doubt")} />
    </div>
  );
};

export default MakeReservationAffiliate;
