import React, { useEffect, useState } from "react";
import "./styles.css";
import { currencyFormat } from "../../../../utils";
import { useTranslation } from "react-i18next";
import TextComponent from "../../../share/components/fonts/TextComponent";
import { Text } from "iconsax-react";

function ContainerPriceDinamic({ price, getChoose, toBuy, currency, company, airlines }) {
  const [t, i18n] = useTranslation("global");

  const typeOfCoin = { name: currency, abbreviation: currency, simbol: '$' };



  const discount = airlines.includes(company) ? 0.01 : 0;

  const priceFare = price.fareAmount - (price.fareAmount * discount);

  const priceTax = price?.taxAmount;

  const priceTotal = priceFare + priceTax + ((priceFare + priceTax) * 0.02);

  return (
    <div className="sectionPriceDinamic">
      <div className="sectionPriceDinamicPrice none600">
        <p className="textBig">
          <span style={{ display: "flex" }}>${currencyFormat(price.fareAmount, typeOfCoin).value} </span>
          <TextComponent children={currencyFormat(0, typeOfCoin).currency} className={"textMediun"} size={"md"} fontWeight="bold" />
        </p>
        <p className="textMediun">{t("fligth.list_flights.taxes")}: <span>${currencyFormat(price?.taxAmount, typeOfCoin).total}</span></p>
        {/* <p className="textMediun"></p> */}
        <p className="textSmall">
          {t("fligth.list_flights.price_with_tax")}:{" "}
          <span className="textSmallWeight">
            ${currencyFormat(price?.fareAmount + price?.taxAmount, typeOfCoin).total}
          </span>
        </p>
      </div>

      <div className="priceResponsive">
        <div className="priceResponsiveFinal" >
          <h3>{t("fligth.list_flights.price_with_tax")}:</h3>
          <p> ${currencyFormat(price?.fareAmount + price?.taxAmount, typeOfCoin).total}</p>
        </div>

        <div className="pricesResponsiveRight" >
          <button onClick={() => toBuy(true, {
            fareAmount: price.fareAmount, taxAmount: price?.taxAmount, totalAmount: price?.fareAmount + price?.taxAmount
          })}>{t("fligth.list_flights.buy")}</button>
        </div>
      </div>


      <button className="btn none600" onClick={() => toBuy(true, {
        fareAmount: price.fareAmount, taxAmount: price?.taxAmount, totalAmount: price?.fareAmount + price?.taxAmount
      })}>
        {t("fligth.list_flights.buy")}
      </button>
    </div>
  );
}

export default ContainerPriceDinamic;
