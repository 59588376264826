import React, { useEffect } from "react";
import "./styles.css";
import FlowPack from "../../../share/components/flowPack/FlowPack";
import { widgetsList } from "../../../../../infra/constants/app";
import { Link, useLocation } from "react-router-dom";
import axios from "axios";
import { CiSearch } from "react-icons/ci";
import { useSelector } from "react-redux";
import { AiOutlineExclamationCircle } from "react-icons/ai";
import { useTranslation } from "react-i18next";
import CardHotelResultAiop from "./CardHotelResultAiop";

const AllCardsResultsAiop = ({
  data,
  arrival,
  departure,
  persons
}) => {


  return (
    <div className={`contain-all-results-hotels-aiop`}>
      <h3 >Nuestros Hoteles Aliados</h3>
      {data?.map((item, key) => (
        <CardHotelResultAiop
          key={key}
          position={key}
          data={item}
          arrival={arrival}
          departure={departure}
          persons={persons}
        />
      ))
      }
    </div>
  );
};

export default AllCardsResultsAiop;