import React, { useEffect, useState } from "react";
import "./styles.css";
import SvgIcon, { iconList } from "../../../share/components/iconSVG/index";
import { currencyFormat, formatToCOP } from "../../../../utils";
import { useLocation, useNavigate } from "react-router-dom";
import axios from "axios";
import { CircularProgress } from "@mui/material";

import { widgetsList } from "../../../../../infra/constants/app";

import { useTranslation } from "react-i18next";
import { MdCameraAlt } from "react-icons/md";
import ModalCardDetail from "./components/ModalCardDetail";
import { IoLocationOutline } from "react-icons/io5";
import TextComponent from "../../../share/components/fonts/TextComponent";
import { PiCoffee, PiFireFill } from "react-icons/pi";
import Tooltip from "@/Components/Tooltip/Tooltip";

//calcular duración de días
const calculateDaysBetween = (date1, date2) => {
  const firstDate = new Date(date1);
  const secondDate = new Date(date2);
  const timeDifference = Math.abs(secondDate - firstDate);
  const daysDifference = Math.ceil(timeDifference / (1000 * 60 * 60 * 24));
  return daysDifference;
};

const CardHotelResultAiop = ({
  data,
  key,
  position,
  arrival,
  departure,
  persons
}) => {
  const location = useLocation();
  const navigate = useNavigate();

  const { pathname } = useLocation();
  const { t } = useTranslation("global");
  const [dataHot, setDataHot] = useState([]);
  const [loadingViewRoom, setLoadingViewRoom] = useState(false);
  const stars = [];
  const routeStartsWithBuildPackageHotel = location.pathname.startsWith(
    "/build-package/hotel"
  );

  // Create an URL object
  const match = pathname.match(/idHotel=(\d+)/);

  // Verify if there was a coincidence and obtain the value of idhotel
  const idHotel = match ? match[1] : "";

  for (let i = 0; i < Number(data?.category); i++) {
    stars.push(
      <SvgIcon key={i} name={iconList.IconStar} color={"#FFD902"} size={20} />
    );
  }

  function formatterText(frase) {
    // Divide the phrase into individual words
    let palabras = frase?.toLowerCase()?.split(" ");

    // Capitalize the first letter of each word
    for (let i = 0; i < palabras?.length; i++) {
      palabras[i] =
        palabras[i]?.charAt(0)?.toUpperCase() + palabras[i]?.slice(1);
    }

    // Unify the words in a single phrase
    let resultado = palabras?.join(" ");

    return resultado;
  }


  function sumPorcentage(num) {
    const numero = Number(num);
    const porcentaje = numero * 0.35;
    const resultado = numero + porcentaje;
    const resultadoRedondeado = Math.round(resultado * 100) / 100;
    return currencyFormat(resultadoRedondeado).total;
  }

  function sumPorcentageV2(num) {
    const numero = Number(num);
    const porcentaje = numero * 0.35;
    const resultado = numero + porcentaje;
    const resultadoRedondeado = Math.round(resultado * 100) / 100;
    return resultadoRedondeado;
  }

  function calcularPorcentaje(valorActual, valorOriginal) {
    // Convert valoractual to number using parseFloat
    let newValorActual = parseFloat(valorActual);
    let newValorOriginal = parseFloat(valorOriginal);

    // Calculate the discount percentage
    const descuento = newValorOriginal - newValorActual;
    const porcentajeDescuento = (descuento / newValorOriginal) * 100;
    // Round the result and return it as an integer number
    const porcentajeRedondeado = Math.round(porcentajeDescuento);

    return porcentajeRedondeado;
  }

  const detailHotelView = () => {
    const params = new URLSearchParams({
      id: data?.id,
      arrival: arrival,
      departure: departure,
      persons: persons
    });
    navigate(`/hotelAfiliate?${params.toString()}`);
  }

  const [loading, setLoading] = useState(true);
  const [showImage, setShowImage] = useState(false);
  const [loadingCurrentPage, setLoadingCurrentPage] = useState(false);

  useEffect(() => {
    if (data?.image) {
      const img = new Image();
      img.src = `${process.env.REACT_APP_URL_API_AIOP}storage/${data?.image}`;
      img.onload = () => {
        setLoading(false);
        setTimeout(() => {
          setShowImage(true);
        }, 500);
      };
      img.onerror = () => {
        setLoading(true);
        setShowImage(false);
      };
    } else {
      setLoading(true);
      setShowImage(false);
    }
  }, [data?.image]);

  // useEffect(() => {
  //   setLoadingCurrentPage(false);
  //   setTimeout(() => {
  //     setLoadingCurrentPage(true);
  //   }, 1000);
  // }, [currentPage]);

  const [modalCardDetail, setModalDetail] = useState(false);


  return (
    <>
      <ModalCardDetail
        data={data}
        total={currencyFormat(data?.rooms[0]?.priceWithDays).value}
        modalCardDetail={modalCardDetail}
        setModalDetail={setModalDetail}
      />
      <div className="card-result-hotel">
        {position == 0
          &&
          <div className="best-offer-label">
            <Tooltip content={t("hotels.recommended_offer.annotation")}>
              <div className="best-offer-label-content">
                <PiFireFill size={30} />
                <div>
                  <h4>{t("hotels.recommended_offer.title")}</h4>
                  <p>{t("hotels.recommended_offer.description")}</p>
                </div>
              </div>
            </Tooltip>
          </div>
        }

        <div className="contain-img-hotel-result">
          {loading || !data?.image ? (
            // <div className="img-hotel-skeleton">
            //   <MdCameraAlt size={40} color="#c8c8c8" />
            // </div>
             <img className="img-hotel-result" src={"https://www.rutamayatravel.com/images/building_loader.gif"} alt="" />
          ) : (
            <img className="img-hotel-result" src={`${process.env.REACT_APP_URL_API_AIOP}storage/${data?.image}`} alt="" />
          )}
          <div className="service-room-include">
            <PiCoffee size={18} />
            {data?.parking ? 'Con Estacionamiento' : 'Sin estacionamiento'
            }
          </div>
        </div>

        <div className="contain-info-result-hotels">
          <section className="contain-info-hotel-card">
            <TextComponent children={data?.name} fontWeight="bold" size={"lg"} tag="h2" />
            <div className="stars-hotel-container">{stars}</div>
            <div className="card-hotel-habitacion">
              <TextComponent className={"dir-hotel-card"} size={"md2"}>
                <IoLocationOutline color={"#004274"} size={22} />
                {formatterText(data?.city?.name)}
              </TextComponent>

              <TextComponent size={"md2"}>
                <SvgIcon
                  name={iconList.IconRestRoom}
                  color={"#004274"}
                  size={30}
                />
                {data?.rooms[0]?.name_room?.name}
              </TextComponent>
            </div>
            <div className="contain-porcentage-desc">
              <TextComponent size={"md"} fontWeight="bold" tag="span">
                -
                {calcularPorcentaje(
                  data?.rooms[0]?.priceWithDays,
                  sumPorcentageV2(data?.rooms[0]?.priceWithDays)
                )}
                %
              </TextComponent>
            </div>
          </section>
          <section className="contain-price-hotel-card">
            <div>
              <div className="">
                <TextComponent className="price-descount-hotel-card" fontWeight="bold" size={"lg"}>
                  {currencyFormat(data?.rooms[0]?.priceWithDays).total}
                </TextComponent>
                <TextComponent className="price-normal-hotel-card" size={"md"}>
                  {sumPorcentage(data?.rooms[0]?.priceWithDays)}
                </TextComponent>
                <TextComponent className="dias-duracion flex justify-end gap-1">
                  <span>

                    {t("hotels.results.price_for")}
                  </span>
                  <span>
                    {data?.rooms[0]?.stayDays}
                  </span>
                  <span>
                    {
                      data?.rooms[0]?.stayDays <= 1 ?
                        t("hotels.results.day") : t("hotels.results.days")
                    }
                  </span>
                </TextComponent>
              </div>
              <TextComponent className="dias-duracion2 ">
                {t("hotels.results.price_for")} {data?.rooms[0]?.stayDays} {
                  data?.rooms[0]?.stayDays === 1 ?
                    t("hotels.results.day") : t("hotels.results.days")
                }
              </TextComponent>
              <TextComponent className="taza-impuestos" size={"sm"}>{t("hotel.include_taxes")}</TextComponent>
            </div>


            <div className="detail-button">
              <TextComponent onclick={{
                voidP: (parameters) => setModalDetail(parameters),
                parameters: true
              }} className="what-include" size={"md2"}>
                {t("hotel.what_include_price")}
              </TextComponent>

              <button
                className="button-detail-habitaciones"
                onClick={detailHotelView}
                style={{
                  PointerEvent: loadingViewRoom ? "none" : "auto",
                  opacity: loadingViewRoom ? 0.5 : 1,
                }}
              >
                {loadingViewRoom === true ? (
                  <CircularProgress size={25} />
                ) : (
                  t("hotel.see_habitation")
                )}
              </button>
            </div>
          </section>
        </div>
      </div></>
  );
};

export default CardHotelResultAiop;
