import policies from '../../../assets/images/politicas-de-privacidad.png'
import { useState } from "react";
import "@react-pdf-viewer/core/lib/styles/index.css";
import aviso from "../../../assets/AVISO DE PRIVACIDAD Y AUTORIZACIÓN EXPRESA PARA EL TRATAMIENTO DE DATOS PERSONALES WELLEZY.pdf"
import politica from "../../../assets/Politica de Tratamiento Personal Wellezy.pdf"
import reclamaciones from "../../../assets/RECLAMACIONES-DATOS-PERSONALES.pdf"


const PdfViewer = ({ pdfUrl, onClose }) => {
    return (
        <div className="fixed top-0 z-[9999] left-0 w-full h-full bg-black bg-opacity-50 flex justify-center items-center">
            <div className="bg-white w-11/12 h-screen p-5 rounded-lg shadow-lg relative">
                <button onClick={onClose} className="absolute top-1 right-1 text-lg font-bold">✖</button>
                <iframe src={pdfUrl} width="100%" height="100%"></iframe>
            </div>
        </div>
    );
};

const TermsPolicies = () => {
    const [selectedPdf, setSelectedPdf] = useState(null);

    const documents = [
        { name: "Reclamaciones Datos", url: reclamaciones },
        { name: "Política de Tratamiento", url: politica },
        { name: "Aviso de Privacidad", url: aviso },
    ];

    const redirectToHome = () => {
        window.location.href = '/';
    }

    return (
        <main className='my-7 flex flex-col gap-7'>
            <section className='flex flex-col  gap-4 md:gap-0 justify-center w-11/12 my-0 mx-auto md:flex-row'>
                <div className='w-full md:w-1/2 flex flex-col justify-center gap-7 items-center'>
                    <h1 className='text-xl md:text-3xl text-[var(--bluePrimary)] font-bold text-center'>Terminos y Condiciones</h1>
                    <p className='text-sm md:text-base text-slate-700 text-center'>
                        Para tu comodidad, puedes descargar una copia de nuestros términos y condiciones en formato PDF haciendo clic en los documentos de abajo. De esta manera, podrás consultarlos en cualquier momento sin necesidad de estar conectado a internet.
                    </p>
                    <button className='bg-[var(--bluePrimary)] text-white px-4 py-2 rounded-lg font-bold text-sm w-fit' onClick={redirectToHome}>Home</button>
                </div>
                <div className='w-full md:w-1/2'>
                    <img src={policies} alt="policies" className='w-10/12 my-0 mx-auto' />
                </div>
            </section>
           {/* Sección de Documentos */}
           <section className="flex flex-col gap-4 w-11/12 my-0 mx-auto">
                <h2 className="text-xl font-semibold text-[var(--bluePrimary)]">Descargar Documentos</h2>
                <div className="grid grid-cols-1 md:grid-cols-3 gap-5 w-full">
                    {documents.map((doc, index) => (
                        <div key={index} className="flex flex-col items-center p-4 border rounded-lg shadow-lg w-full bg-white">
                            <p className="text-lg font-medium">{doc.name}</p>
                            <div className="flex gap-3 mt-3">
                                <button
                                    onClick={() => setSelectedPdf(doc.url)}
                                    className="bg-[var(--bluePrimary)] text-white px-4 py-2 rounded-lg font-bold text-sm">
                                    Ver
                                </button>
                                <a href={doc.url} download
                                    className="bg-gray-500 text-white px-4 py-2 rounded-lg font-bold text-sm">
                                    Descargar
                                </a>
                            </div>
                        </div>
                    ))}
                </div>
            </section>

            {selectedPdf && <PdfViewer pdfUrl={selectedPdf} onClose={() => setSelectedPdf(null)} />}
        </main>
    )
}

export default TermsPolicies