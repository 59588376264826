import axios from "axios";
import CarouselSevices, { servicesTravel } from "../../share/components/CarouselServices/CarouselSevices";
import { useEffect } from "react";
import { useState } from "react";
import ReturnPage from "../../share/components/return/ReturnPage";
import "./styles.css";
import TextComponent from "../../share/components/fonts/TextComponent";
import SvgIcon, { iconList } from "../../share/components/iconSVG";
import { IoCloseCircle, IoAdd, IoRemove, IoCheckmark } from "react-icons/io5";
import { TbLoader2 } from "react-icons/tb";
import { BiQuestionMark } from "react-icons/bi";
import { useTranslation } from "react-i18next";
import { GoCheckCircleFill } from "react-icons/go";
import { currencyFormat } from "@/ui/utils";
import { FaCircleCheck } from "react-icons/fa6";
import { useNavigate } from "react-router-dom";
import InputSimple from "@/Components/TerrawindVoucherDetail/FormDataPassenger/InputSimple/InputSimple";
import { CircularProgress } from "@material-ui/core";
import toast, { Toaster } from "react-hot-toast";
import Loading from "@/ui/views/share/components/loading";

const TourismPackagePay = () => {
  const {t, i18n } = useTranslation("global");

  const [authUser, setAuthUser] = useState(null);
  const [payStatus, setPayStatus] = useState(null);
  const [packageUrl, setPackageUrl] = useState("");
  const [packageInfo, setPackageInfo] = useState([]);
  const [validate, setValidate] = useState("");
  const [isLoadingAuthInfo, setIsLoadingAuthInfo] = useState(true);
  const [userPayments, setUserPayments] = useState([]);
  const [isLoadingReserve, setIsLoadingReserve] = useState(false);

  const navigate = useNavigate();

  const [dollarFlight, setDollarFlight] = useState(
    localStorage.getItem("dollarFlight") === null
      ? "COP"
      : localStorage.getItem("dollarFlight")
  );
  
  const typeOfCoin = {
    name: dollarFlight,
    abbreviation: dollarFlight,
    simbol: "$",
  };

  const notify = (title, icon) =>
    toast(title, {
        icon: icon,
    }
  );

  useEffect(() => {
    setIsLoadingAuthInfo(true);
    // Verify if there is an authenticated user
    const authUserSession = JSON.parse(localStorage.getItem("@authUserSession") || "{}");
    if (!authUserSession?.user?.id) {
      navigate("/login");
    }


    setAuthUser(authUserSession?.user);
    setIsLoadingAuthInfo(false);

    // Verify if in the store Storage there is a request-ID-Pay-Tourism-Package to verify the payment status
    const requestId = localStorage.getItem("request-id-pay-tourism-package");
    if (requestId) {
      checkReservationPaymentStatus(authUserSession?.user);
    } else {
      navigate("/packages");
    }
  }, []);

  const formatDate = (dateString) => {
    const date = new Date(dateString);

    // obtain the individual parts of the date
    const day = date.getDate().toString().padStart(2, '0');
    const month = (date.getMonth() + 1).toString().padStart(2, '0'); // The months begin at 0
    const year = date.getFullYear();

    // Obtain the parts of the hour
    const hours = date.getHours().toString().padStart(2, '0');
    const minutes = date.getMinutes().toString().padStart(2, '0');

    // Final format
    return `${day}-${month}-${year} ${hours}:${minutes}`;
  };

  const getPackageInfo = async (packageId) => {
    try {
      const url = `${process.env.REACT_APP_URL_API_AIOP}api/packs/tourism/${packageId}`;
      const response = await axios.get(url);
      setPackageInfo(response.data.pack);
      return response.data.pack;
    } catch (error) {
      console.error("Error al obtener los datos del paquete:", error);
    }
  }

  const handleGoToMyMovements = () => {
    localStorage.removeItem("request-id-pay-tourism-package");
    localStorage.removeItem("data-payment-tourism-package-status");
    localStorage.removeItem("data-pay-tourism-package");
    localStorage.removeItem("has-been-paid-tourism-package");
    localStorage.removeItem("data-type-pay-tourism-package");


    navigate("/profile?option=3");
  }

  const checkReservationPaymentStatus = async (authUserData) => {
    try {
      setIsLoadingReserve(true)
      const requestId = localStorage.getItem("request-id-pay-tourism-package");
      const paymentType = localStorage.getItem("data-type-pay-tourism-package");

      const url = process.env.REACT_APP_URL_STATE_PAY;
      const paymentStatus = await axios.post(`${url}pack`, { request_id: Number(requestId) });
      
      localStorage.setItem("data-payment-tourism-package-status", JSON.stringify(paymentStatus.data));

      if (paymentStatus.data?.pay?.status === "APPROVED") {
        const paymentsArray = []
        const paymentInformation = paymentStatus.data?.pay;

        const paymentDataToStore = {
          user_id: authUserData.id,
          email: authUserData.email,
          localizator: paymentInformation.localizator,
          payment_date: paymentInformation.date,
          payment_created_at: paymentInformation.created_at,
          payment_updated_at: paymentInformation.updated_at,
          price: paymentInformation.price.toString(),
          reference: paymentInformation.reference,
          reason: paymentInformation.reason,
          status: paymentInformation.status,
          request_id: paymentInformation.request_id,
          type: paymentInformation.type
        }

        const checkIfHasBeenPaid = localStorage.getItem('has-been-paid-tourism-package')

        if (!checkIfHasBeenPaid) {
          const requestData = localStorage.getItem("data-pay-tourism-package");

          const url = `${process.env.REACT_APP_URL_API_AIOP}api/user-payments`;
          const userPayment = await axios.post(url, paymentDataToStore);
          
          paymentsArray.push(userPayment.data?.payment)
          setUserPayments(paymentsArray);
            
          localStorage.setItem('has-been-paid-tourism-package', 'true');
          const message = t("packs.tourism.success.approved_payment")
          notify(message, "✅");

          if (requestData) {
            try {
              const data = JSON.parse(requestData);
              const packageId = data.packageId
              setPackageUrl(`/packages/${packageId}`);

              const packageInformation = await getPackageInfo(packageId);

              const dataToReserve = {
                user_id: authUserData.id,
                name: authUserData.name,
                email: authUserData.email,
                tel: authUserData.prex_phone_public + authUserData.phone_public,
                city: packageInformation?.destination,
                observations: "No",
                payment_type: paymentType,
                terms: true,
                passengers: 1,
                pack_id: packageInformation?.id,
                total_value: packageInformation?.price,
                payments: JSON.stringify(paymentsArray),
              }; 
              
              await axios.post(`${process.env.REACT_APP_URL_API_AIOP}api/pack/form`, dataToReserve);
    
              const messageReserve = t("packs.tourism.success.completed_reserve")
              notify(messageReserve, "✅");
            } catch (error) {
              console.error("Error al obtener los datos del paquete:", error);
            }
          }
        } else {
          const message = t("packs.tourism.success.already_paid")
          notify(message, "✅");
        }
      }
      else if (paymentStatus.data?.pay?.status === "REJECTED") {
        const message = t("packs.tourism.errors.rejected_payment")
        notify(`Error: ${message}`, "❌");
      } else {
        const message = t("packs.tourism.errors.pending_payment")
        notify(`Error: ${message}`, "❌");
      }


      setPayStatus(paymentStatus.data?.pay);
    } catch (error) {
      console.error(error);
    } finally {
      setIsLoadingReserve(false);
    }
  };

  return (
    <div>
      {
        (isLoadingReserve) &&
        <Loading />
      }

      <Toaster position="top-center" reverseOrder={false} /> 
      <CarouselSevices current={t("carousel_services.packages")} />
      <ReturnPage nav={packageUrl} reload={false} />

      <div className='methodPayContainer' >
        <div className='methodPayHeader'>
          <h3>{t("packs.tourism.payment_detail.title")}</h3>
          <p>{t("packs.tourism.payment_detail.description")}</p>
        </div>
        <div className='methodPayContain' >
          <div className='methodPayContainQuestions'>
            <div className='methodPayContainerTermsContain'>
              <div className='detailPayFlight'>
                <div className="detailPayFlightHeader">
                  <div className="detailPayFlightHeaderImage">
                    <img src={`${process.env.REACT_APP_URL_API_AIOP}storage/pack/${packageInfo?.image}`} alt="imagePackage" />
                  </div>
                  <div className="detailPayFlightInfo">
                    <h2>
                      {packageInfo?.name}
                    </h2>
                  </div>
                </div>

                <h3>{t("packs.tourism.payment_detail.package_information")}</h3>
                <div>
                  <p>
                    <strong>{t("packs.tourism.payment_detail.destination")}:</strong> {packageInfo?.destination}
                  </p>
                  <p>
                    <strong>{t("packs.tourism.payment_detail.start_date")}:</strong> {packageInfo?.start_date}
                  </p>
                  <p>
                    <strong>{t("packs.tourism.payment_detail.end_date")}:</strong> {packageInfo?.due_date}
                  </p>

                  {payStatus?.status === "APPROVED" && (
                    <p className="mt-14">
                      <span>
                        <strong>
                          {t("packs.tourism.payment_detail.stored_payment")}
                          <button onClick={handleGoToMyMovements} className="ml-2 underline cursor-pointer text-green-700 hover:text-green-900">{t("packs.tourism.payment_detail.stored_payment_module")}</button>
                        </strong>
                      </span>
                    </p>
                  )}
                </div>

                <div className="itemDetailsFlights">
                  <div className="itemDetailsFlights1">
                    <div className="itemDetailsFlightsColumn1">
                      <p className="typeFlight">
                        <strong>{t("packs.tourism.payment_detail.payment_date")}</strong> {formatDate(payStatus?.created_at)}
                      </p>
                    </div>
                  </div>
                  <div className="itemDetailsFlights2">
                    <div className="itemDetailsFlightsColumn1">
                      <p className="typeFlight">
                        <strong>{t("packs.tourism.payment_detail.payment_reference")}</strong> {payStatus?.reference}
                      </p>
                    </div>
                  </div>
                </div>
              </div>


            </div>
          </div>

          <div className="methodPayContainInfo">
            <div className="methodPayImageContain">
              <div className="methodPayContainInfoContain">
                <div
                  className={`contentStatePay ${payStatus?.status === "APPROVED"
                    ? "APPROVED"
                    : payStatus?.status === "PENDING"
                      ? "PENDING"
                      : payStatus?.status === "REJECTED"
                        ? "REJECTED"
                        : ""
                    }`}
                >
                  {payStatus?.status === "APPROVED" ? (
                    <GoCheckCircleFill size={40} />
                  ) : payStatus?.status === "PENDING" ? (
                    <TbLoader2 size={40} />
                  ) : payStatus?.status === "REJECTED" ? (
                    <IoCloseCircle size={40} />
                  ) : (
                    <BiQuestionMark size={40} />
                  )}
                  <div>
                    <div className="contentStatePayMessage !gap-[8px]">
                      <h3>{t("packs.tourism.payment_detail.status.text")}:</h3>
                      <p>
                        {payStatus?.status === "APPROVED"
                          ? t("packs.tourism.payment_detail.status.approved")
                          : payStatus?.status === "PENDING"
                            ? t("packs.tourism.payment_detail.status.pending")
                            : payStatus?.status === "REJECTED"
                              ? t("packs.tourism.payment_detail.status.rejected")
                              : t("packs.tourism.payment_detail.status.loading")}
                      </p>
                    </div>
                    <p>
                      {payStatus?.status === "APPROVED"
                        ? t("packs.tourism.payment_detail.status.success_payment")
                        : payStatus?.status === "PENDING"
                          ? t("packs.tourism.payment_detail.status.pending_payment")
                          : payStatus?.status === "REJECTED"
                            ? t("packs.tourism.payment_detail.status.rejected_payment")
                            : t("packs.tourism.payment_detail.status.loading")}
                    </p>
                  </div>
                </div>
                <div className="methodPayContainInfoContainPadding">
                  <div>
                    <h3>{t("packs.tourism.payment_detail.finished_purchase")}</h3>
                    <p>{t("packs.tourism.payment_detail.total_purchase")}</p>
                  </div>
                  <div className="methodValuesContaint">
                    <div className="methodValuesItem">
                      <h4>{t("fligth.form_passenger.step3.sub_total")}:</h4>
                      <span>$ {currencyFormat(payStatus?.price, typeOfCoin).total}</span>
                    </div>
                    <div className="methodValuesItemTotal">
                      <h4>{t("fligth.form_passenger.step3.total")}:</h4>
                      <span>$ {currencyFormat(payStatus?.price, typeOfCoin).total} </span>
                    </div>
                  </div>
                </div>
              </div>
              <div className="securityPay">
                <SvgIcon
                  color={"var(--bluePrimary)"}
                  name={iconList.IconKey}
                  size={40}
                />
                <div>
                  <TextComponent
                    children={t("packs.tourism.payment_detail.secure_payment")}
                    size={"md"}
                    fontWeight={"bold"}
                    tag="h4"
                  />
                  <TextComponent
                    children={t("packs.tourism.payment_detail.secure_payment_description")}
                    size={"md2"}
                    fontWeight={"light"}
                    tag="span"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
        
        <div className="text-center">
          {payStatus?.status === "APPROVED" && (
            <p>
              {t("packs.tourism.payment_detail.footer_text", {status: t("packs.tourism.payment_detail.status.approved")})}
            </p>
          )}

          {payStatus?.status === "PENDING" && (
            <p>
              {t("packs.tourism.payment_detail.footer_text", {status: t("packs.tourism.payment_detail.status.pending")})}
            </p>
          )}

          {payStatus?.status === "REJECTED" && (
            <p>
              {t("packs.tourism.payment_detail.footer_text", {status: t("packs.tourism.payment_detail.status.rejected")})}
            </p>
          )}
        </div>

      </div>
    </div>
  );
};

export default TourismPackagePay;