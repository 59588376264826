import React from "react";
import { useLocation } from "react-router-dom";
import WellezyAiop from "../../../assets/presentation-logo.svg"; // Logo
import HotelesSVG from "../../../assets/hotels-info.svg"; // Texto "Hoteles"
import TravelSVG from "../../../assets/travel-info.svg"; // Texto "Travel"
import ClusterSVG from "../../../assets/cluster-info.svg"; // Texto "Cluster"

const Patients = () => {
  const location = useLocation();

  let titleSVG, backgroundStyle, blurFilter;
  if (location.pathname.includes("patients")) {
    titleSVG = ClusterSVG; // Puedes cambiar esto si hay otro SVG para pacientes
    backgroundStyle =
      "radial-gradient(50% 50% at 50% 50%, rgba(1, 174, 232, 0.6) 0%, rgba(1, 174, 232, 0) 100%)";
    blurFilter = "blur(58.8px)";
  }

  return (
    <div className="relative w-full h-screen flex flex-col items-center justify-center overflow-hidden px-6 md:px-24">
      {/* Degradado */}
      <div
        className="absolute hidden md:block"
        style={{
          background: backgroundStyle,
          filter: blurFilter,
          width: "1627px",
          height: "1627px",
          left: "416px",
          top: "-98px",
          zIndex: "-1",
        }}
      ></div>

      {/* Logo */}
      <img src={WellezyAiop} alt="Logo Wellezy AIOP" className="w-40 md:w-56 mb-6" />

      {/* Título */}
      <h1 className="font-bold text-3xl md:text-5xl text-center text-gray-800 md:text-gray-100 drop-shadow-lg mb-6">
        ¿Qué es Wellezy?
      </h1>

      {/* Video de YouTube */}
      <div className="w-full max-w-2xl aspect-video">
        <iframe
          className="w-full h-full rounded-lg shadow-lg"
          src="https://www.youtube.com/embed/aZC1jjgqR2Y"
          title="YouTube video"
          frameBorder="0"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
          allowFullScreen
        ></iframe>
      </div>
    </div>
  );
};

export default Patients;
