import React, { useEffect, useState } from "react";
import "./Assets/styles.css";
import BenefitValue from "../BenefitValue/BenefitValue";
import { useNavigate } from "react-router-dom";

function CardPlan({name, price, passengers, dateOrigin, dateDestination, destination, origin, productId}){

    const [isModalOpenTerrawind , setIsModalOpenTerrawind] = useState(false)

    const data = {
        plan: name,
        price: price,
        passengers: passengers,
        dateOrigin: dateOrigin,
        dateDestination: dateDestination,
        destination: destination,
        origin: origin,
        productId: productId
    }

    const navigate = useNavigate();

    const priceWithoutCommas = price.replace(/,/g, '');
    const priceFormat = parseFloat(priceWithoutCommas);
    const pricePassengers = priceFormat / passengers;
    const pricePassengersFormat = pricePassengers.toLocaleString();

    const closeModal = () => {
        setIsModalOpenTerrawind(false)
    }

    function redirectToViewVoucher(){
        const dataPlan = encodeURIComponent(JSON.stringify(data));
        navigate(`/travelSecure/terrawind/voucher/${dataPlan}`);
        window.scrollTo(0, 0);
    }

    return(
        <div>
            <div className="container-plan">
                <div className="plan-name">
                    <p>Plan { name }</p>
                </div>
                <div>
                    <div className="price-passengers">
                        <p className="p-price-passengers">COP { pricePassengersFormat }</p>
                        <p>por persona</p>
                    </div>
                    <div className="price-total">
                        <p>Total: COP { price }</p>
                    </div>
                    <div className="btn-buy-benefit">
                        <button className="btn-benefit" onClick={() => setIsModalOpenTerrawind(true)}>Ver todos los beneficios</button>
                        <button className="btn-buy" onClick={redirectToViewVoucher}>Comprar</button>
                    </div>
                </div>
            </div>
            <BenefitValue isModalOpen={isModalOpenTerrawind} id={ productId } closeModal={closeModal}/>
        </div>
    )
}

export default CardPlan;