import React, { useEffect, useState } from "react";
import Find from "../../../share/components/Find/Find";
import CarouselSevices, {
  servicesTravel,
} from "../../../share/components/CarouselServices/CarouselSevices";
import UsePayHotel from "../../../../viewModels/hotels/UsePayHotel";
import PaymentCards from "../../../profileUser/components/paymentCards";
import DetailPayHotel from "./detailPayHotel/DetailPayHotel";
import connectMiles from "../../../../assets/logos/connectmiles.png";
import "./styles.css";
import SvgIcon, { iconList } from "../../../share/components/iconSVG";
import ModalContainer from "../../../share/components/modalContainer";
import Loading from "../../../share/components/loading";
import SuccessPay from "./successPay/SuccessPay";
import PendingPay from "./pendingPay/PendingPay";
import RejectPay from "./rejectPay/RejectPay";
import ReturnPage from "../../../share/components/return/ReturnPage";
import { MdCameraAlt } from "react-icons/md";
import GalleryImg from "../../DetailHotel/GalleryImg/GalleryImg";
import ModalCardDetail from "../../HotelResult/allResultsCards/components/ModalCardDetail";
import { formatToCOP } from "@/ui/utils";
import { GoCheckCircleFill } from "react-icons/go";
import { IoCloseCircle } from "react-icons/io5";
import { BiQuestionMark } from "react-icons/bi";
import { TbLoader2 } from "react-icons/tb";
import { useTranslation } from "react-i18next";
import UsePayHotelAffiliate from "@/ui/viewModels/hotels/UsePayHotelAffiliate";

const PayHotelAffiliate = () => {
  const {
    loadinPay,
    statusTransaction,
    stateValue
  } = UsePayHotelAffiliate();

  const { t } = useTranslation("global");

  useEffect(() => {
    const interval = setInterval(() => {
      if (
        statusTransaction?.pay?.status === "PENDING" ||
        statusTransaction?.pay?.status === "REJECTED"
      ) {
        window.location.reload();
      }
    }, 5000);

    // Cleanup interval on component unmount
    return () => clearInterval(interval);
  }, []);

  const [hotelUrl, setHotelUrl] = useState("/hotel");


  const [loadingImage, setLoadingImage] = useState(true);
  const [slideShow, setSlideShow] = useState(false);
  const [modalCardDetail, setModalDetail] = useState(false);

  useEffect(() => {
    setTimeout(() => {
      setLoadingImage(false);
    }, 3000);
  }, []);

  function Image({ url }) {
    const onLoad = () => {
      setLoadingImage(false);
    };
    const onError = () => {
      setLoadingImage(true);
    };
    return (
      <img
        className="image-hotel-detail"
        src={url}
        onLoad={onLoad}
        onError={onError}
        alt="Hotel"
      />
    );
  }

  function formatearCantidad(cantidad) {
    const cantidadFormateada = cantidad?.toLocaleString("es", {
      style: "currency",
      currency: "COP",
      minimumFractionDigits: 0,
      maximumFractionDigits: 0,
    });

    return cantidadFormateada;
  }

  console.log(stateValue);

  return (
    <div>
      <CarouselSevices current={servicesTravel[1].title} />
      <ReturnPage nav={hotelUrl} reload={true} />
      <div className="contain-hotel-pay">
        {loadinPay && <Loading size={90} />}
        <div className="layout-hotel-pay">
          <section className="main-hotel-pay">
            <div className="main-hotel-title-pay">
              <h2>{t("hotels.payment_detail.title")}</h2>
            </div>
            <div className="service-details bg-white shadow-lg rounded-xl p-6 border border-gray-200">
              <h3 className="text-lg font-semibold text-gray-700 mb-4">Detalles del Servicio</h3>
              <div className="grid grid-cols-2 gap-4 text-sm text-gray-600">
                <p><strong className="text-gray-800">Hotel ID:</strong> {stateValue?.hotel_id}</p>
                <p><strong className="text-gray-800">Titular:</strong> {stateValue?.nombre_titular} {stateValue?.apellido_titular}</p>
                <p><strong className="text-gray-800">Documento:</strong> {stateValue?.documento_titular}</p>
                <p><strong className="text-gray-800">Teléfono:</strong> {stateValue?.telefono}</p>
                <p><strong className="text-gray-800">Correo:</strong> {stateValue?.correo}</p>
                <p><strong className="text-gray-800">Fecha de Llegada:</strong> {new Date(stateValue?.fecha_llegada).toLocaleDateString()}</p>
                <p><strong className="text-gray-800">Fecha de Salida:</strong> {new Date(stateValue?.fecha_salida).toLocaleDateString()}</p>
                <p><strong className="text-gray-800">Monto:</strong>
                  <span className="text-green-600 font-medium">
                    {stateValue?.monto?.toLocaleString('es-CO', { style: 'currency', currency: 'COP' })}
                  </span>
                </p>
                <p><strong className="text-gray-800">Habitaciones:</strong> {JSON.parse(stateValue?.habitaciones).join(", ")}</p>
              </div>

              {/* Sección de huéspedes */}
              <h3 className="text-lg font-semibold text-gray-700 mt-6">Huéspedes</h3>
              <div className="mt-2 space-y-2">
                {JSON.parse(stateValue?.huespedes)?.map((huesped, index) => (
                  <div key={index} className="bg-gray-100 p-3 rounded-lg">
                    <p><strong>Nombre:</strong> {huesped.name} {huesped.lastname}</p>
                    <p><strong>Edad:</strong> {huesped.age}</p>
                    <p><strong>Email:</strong> {huesped.email}</p>
                    <p><strong>Teléfono:</strong> {huesped.tel}</p>
                    <p><strong>Documento:</strong> {huesped.doc} ({huesped.typeDoc})</p>
                  </div>
                ))}
              </div>
            </div>


          </section>

          <div className="content-state-pay-state">
            <div
              className={`content-state-pay ${statusTransaction?.pay?.status === "APPROVED"
                ? "APPROVED"
                : statusTransaction?.pay?.status === "PENDING"
                  ? "PENDING"
                  : statusTransaction?.pay?.status === "REJECTED" || statusTransaction?.pay?.status === "FAILED"
                    ? "REJECTED"
                    : ""
                }`}
            >
              {statusTransaction?.pay?.status === "APPROVED" ? (
                <GoCheckCircleFill size={40} />
              ) : statusTransaction?.pay?.status === "PENDING" ? (
                <TbLoader2 size={40} />
              ) : statusTransaction?.pay?.status === "REJECTED" || statusTransaction?.pay?.status === "FAILED" ? (
                <IoCloseCircle size={40} />
              ) : (
                <BiQuestionMark size={40} />
              )}
              <div>
                <div className="content-state-pay-message">
                  <h3>{t("hotels.payment_detail.status.text")}:</h3>
                  <p>
                    {statusTransaction?.pay?.status === "APPROVED"
                      ? t("hotels.payment_detail.status.approved")
                      : statusTransaction?.pay?.status === "PENDING"
                        ? t("hotels.payment_detail.status.pending")
                        : statusTransaction?.pay?.status === "REJECTED"
                          ? t("hotels.payment_detail.status.rejected")
                          : statusTransaction?.pay?.status === "FAILED"
                            ? t("hotels.payment_detail.status.rejected")
                            : t("hotels.payment_detail.status.loading")}
                  </p>
                </div>
                <p>
                  {statusTransaction?.pay?.status === "APPROVED"
                    ? t("hotels.payment_detail.status.success_payment")
                    : statusTransaction?.pay?.status === "PENDING"
                      ? t("hotels.payment_detail.status.pending_payment")
                      : statusTransaction?.pay?.status === "REJECTED" || statusTransaction?.pay?.status === "FAILED"
                        ? t("hotels.payment_detail.status.rejected_payment")
                        : t("hotels.payment_detail.status.loading")}
                </p>
              </div>
            </div>

            <section className="aside-hotel-pay">

            <div className="payment-info ">
              <h3 className="text-lg font-semibold text-gray-700 mb-4">Detalles del Pago</h3>
              <div className="grid grid-cols-2 gap-4 text-sm text-gray-600">
                <p><strong className="text-gray-800">ID:</strong> {statusTransaction?.pay?.id}</p>
                <p><strong className="text-gray-800">Localizador:</strong> {statusTransaction?.pay?.localizator}</p>
                <p><strong className="text-gray-800">Precio:</strong>
                  <span className="text-green-600 font-medium"> {statusTransaction?.pay?.price?.toLocaleString('es-CO', { style: 'currency', currency: 'COP' })}</span>
                </p>
                <p><strong className="text-gray-800">Referencia:</strong> {statusTransaction?.pay?.reference}</p>
                <p><strong className="text-gray-800">Estado:</strong>
                  <span className={`px-2 py-1 text-xs font-semibold rounded ${statusTransaction?.pay?.status === "FAILED" ? "bg-red-100 text-red-600" : "bg-green-100 text-green-600"}`}>
                    {statusTransaction?.pay?.status}
                  </span>
                </p>
                <p><strong className="text-gray-800">Tipo:</strong> {statusTransaction?.pay?.type}</p>
                <p><strong className="text-gray-800">Fecha de Creación:</strong> {new Date(statusTransaction?.pay?.created_at)?.toLocaleString()}</p>
              </div>
            </div>


              <div className="pay-secure-hotel">
                <SvgIcon
                  color={"var(--bluePrimary)"}
                  name={iconList.IconKey}
                  size={40}
                />
                <div>
                  <p>{t("hotels.payment_detail.secure_payment")}</p>
                  <span>{t("hotels.payment_detail.secure_payment_description")}</span>
                </div>
              </div>
            </section>
          </div>
        </div>
        <div className="connect-miles">
          <img src={connectMiles} alt="connectMiles" />
          <p>
            {t("hotels.payment_detail.connect_miles")}{" "}
          </p>
        </div>
      </div>
    </div>
  );
};

export default PayHotelAffiliate;
