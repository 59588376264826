import React, { useCallback, useEffect, useState } from "react";
import SvgIcon, { iconList } from "../../../../share/components/iconSVG";
import axios from "axios";

import "./styles.css";

import { useTranslation } from "react-i18next";
import { Autocomplete, TextField } from "@mui/material";

const TellYourself = ({
  setCity,
  city,
  setAges,
  ages,
  setDeparment,
  deparment,
}) => {
  const [modalDepartment, setModalDepartment] = useState(false);

  const [modalCity, setModalCity] = useState(false);

  const [modalAges, setModalAges] = useState(false);
  const [loadingCities, setLoadingCities] = useState(false);

  const { t } = useTranslation("global");

  const [departments, setDepartments] = useState([]);
  const [inputValue, setInputValue] = useState("");
  const [loading, setLoading] = useState(false);
  const [timer, setTimer] = useState(null);
  const [idCity, setIdCity] = useState("");
    const [cities, setCities] = useState([]);

  // Función para obtener los países
  const fetchCountries = async (name) => {
    if (!name) return;
    setLoading(true);
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_URL_API_AIOP}api/countries/search?name=${name}`
      );

      // Asegurar que `setDepartments` reciba un array
      const data = Array.isArray(response.data) ? response.data : [response.data];
      setDepartments(data);
    } catch (error) {
      console.error("Error fetching countries:", error);
      setDepartments([]);
    } finally {
      setLoading(false);
    }
  };

  // Función para obtener las ciudades por ID de país
  const fetchCities = async (countryId) => {
    if (!countryId) return;
    setLoadingCities(true);
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_URL_API_AIOP}api/city/search?search=${countryId}`
      );
      setCities(response.data); // Guardamos las ciudades en el estado
    } catch (error) {
      console.error("Error fetching cities:", error);
      setCities([]);
    } finally {
      setLoadingCities(false);
    }
  };
  // Manejar cambios en el input con debounce manual
  const handleInputChange = (event, newInputValue) => {
    setInputValue(newInputValue);
    setDeparment(newInputValue);
    if (timer) clearTimeout(timer);

    const newTimer = setTimeout(() => {
      fetchCountries(newInputValue);
    }, 500);

    setTimer(newTimer);
  };


  const agesA = [
    t("packs.health.detailed_pack.quote_section.ages.first"),
    t("packs.health.detailed_pack.quote_section.ages.second"),
    t("packs.health.detailed_pack.quote_section.ages.third"),
    t("packs.health.detailed_pack.quote_section.ages.fourth"),
    t("packs.health.detailed_pack.quote_section.ages.fifth"),
  ];



  return (
    <div className="content-tell-yourself">
      <h2>{t("specialist.booking_flow.talked_you")}</h2>
      <section className="contain-camp-select-procedures">
        <label htmlFor="">{t("specialist.booking_flow.deparment")}</label>
        <div className="select-country-package">
          <Autocomplete
            id="combo-box-demo"
            options={departments}
            loading={loading}
            onChange={(event, newValue) => {
              fetchCities(newValue?.id);
            }}
            onInputChange={handleInputChange}
            getOptionLabel={(option) => option.name || ""}
            renderOption={(props, option) => (
              <li {...props} className="list-origin" style={{ fontFamily: "Montserrat", fontSize:"14px" }}>
                {option.emoji} {option.name}
              </li>
            )}
            renderInput={(params) => (
              <TextField
                {...params}
                variant="outlined"
                placeholder="Escribe un país..."
                InputProps={{
                  ...params.InputProps,
                  endAdornment: loading ? <span>Cargando...</span> : null,
                  style: { borderRadius: 8 },
                }}
              />
            )}
          />
        </div>
      </section>

      {/* SELECCIÓN DE CIUDAD */}
      <section className="contain-camp-select-procedures">
        <label htmlFor="">{t("specialist.booking_flow.city")}</label>
        <div className="select-country-package">
          <Autocomplete
            id="combo-box-city"
            options={cities}
            loading={loadingCities}
            onChange={(event, newValue) => {
              setCity(newValue?.name);
            }}
            getOptionLabel={(option) => option?.name || ""}
            renderOption={(props, option) => (
              <li {...props} key={option?.id} className="list-origin" style={{ fontFamily: "Montserrat", fontSize:"14px" }}>
                {option?.name}
              </li>
            )}
            renderInput={(params) => (
              <TextField
                {...params}
                variant="outlined"
                placeholder="Escribe una ciudad..."
                InputProps={{
                  ...params.InputProps,
                  endAdornment: loadingCities ? <span>Cargando...</span> : null,
                  style: { borderRadius: 8 },
                }}
              />
            )}
          />
        </div>
      </section>
      <section className="contain-camp-select-procedures">
        <label htmlFor="">{t("specialist.booking_flow.what_age")}</label>
        <div
          className="camp-select-procedures"
          onClick={() => {
            setModalAges(!modalAges);
          }}
        >
          <span>
            {ages === "" ? t("specialist.booking_flow.select_age") : ages}
          </span>
          <SvgIcon name={iconList.IconArrowDown2} size={20} color={"#004274"} />
          {modalAges && (
            <div className="modal-procedures-select">
              <ul>
                {agesA.map((index, key) => {
                  return (
                    <li
                      key={key}
                      onClick={() => {
                        setAges(index);
                      }}
                    >
                      {index}
                    </li>
                  );
                })}
              </ul>
            </div>
          )}
        </div>
      </section>
    </div>
  );
};

export default TellYourself;
