import React, { useEffect, useState } from "react";
import "./styles.css";
import { useTranslation } from "react-i18next";
import ModalContainer from "../../../../share/components/modalContainer";
import { FaCircleCheck, FaPerson } from "react-icons/fa6";
import { HiUsers } from "react-icons/hi2";
import { MdRoomService } from "react-icons/md";
import TextComponent from "../../../../share/components/fonts/TextComponent";
import { FaRegCircleDot, FaRegCircle } from "react-icons/fa6";
import { FaRestroom } from "react-icons/fa";
import { MdBedroomParent } from "react-icons/md";
import ModalChange from "../../../components/ModalChangeModi/ModalChange";
import { PiFireFill } from "react-icons/pi";
import Tooltip from "@/Components/Tooltip/Tooltip";

const 
HabDetail = ({
  Hab,
  typeAcomodation,
  buttonBooking,
  joinReserve,
  deleteReserve,
  persons,
  rooms,
  people,
  selectedRoom,
  id,
  setSelectedRoom,
  arrival,
  departure,
  bestOffer
}) => {
  const [selectHab, setSetselectHab] = useState({});
  const [alertList, setAlertList] = useState(false);
  const [habOption, setHabOption] = useState(null);
  const [habPersonOption, setPersonHabOption] = useState(null);
  const { t } = useTranslation("global");

  const [errChangeServices, setErrChangeServices] = useState(false);

  const [modalCardDetail, setModalDetail] = useState(false);
  const [firstValueFromLin, setFirstValueFromLin] = useState("");
  const [selectedItem, setSelectedItem] = useState(null);

  const [show, setShow] = useState(false);
  const [activeChangeService, setActiveChangeService] = useState(false);

  const arrivalDate = new Date(arrival);
  const departureDate = new Date(departure);

  const differenceInTime = departureDate - arrivalDate;

  const differenceInDays = differenceInTime / (1000 * 3600 * 24);

  const nights = differenceInDays - 1;

  const selectedHabFunction = (item) => {
    setSelectedItem(item);
    setSelectedRoom(item)
    setSetselectHab(item);
    setHabOption(item);

  };

  useEffect(() => {
    if (selectedRoom === id) {
      setSelectedItem(null);
      setSetselectHab({});
      setSelectedRoom("");
    }
  }, [selectedRoom]);

  function formatearCantidad(cantidad) {
    const cantidadFormateada = cantidad.toLocaleString("es", {
      style: "currency",
      currency: "COP",
      minimumFractionDigits: 0,
      maximumFractionDigits: 0,
    });

    return cantidadFormateada;
  }

  const optionNr = (tipoReembolso, include) => {
    const alojamientoEncontrado = selectHab?.reg?.find((hab) => {
      return (
        hab["@attributes"].cod === include &&
        hab["@attributes"].nr === tipoReembolso
      );
    });

    if (alojamientoEncontrado === undefined) {
      setErrChangeServices(true);
      setTimeout(() => {
        setErrChangeServices(false);
      }, 2000);
    } else {
      alojamientoEncontrado["acomodation"] = typeAcomodation;
      alojamientoEncontrado["name"] = selectHab["@attributes"]?.desc;
      setHabOption(alojamientoEncontrado);
    }
  };

  const selectedItemDeleteSelected = (item) => {
    setActiveChangeService(false);
  };

  const handleScroll = () => {
    window.scrollTo({
      top: document.body.scrollHeight - window.innerHeight,
      behavior: "smooth",
    });
  };

  const getAccommodationInfo = (accommodation) => {
    if (!accommodation) return { adults: 0, children: 0 };

    const [adults, children] = accommodation.split("-").map(Number);
    return {
      adults: isNaN(adults) ? 0 : adults,
      children: isNaN(children) ? 0 : children,
    };
  };

  const { adults, children } = getAccommodationInfo(people["@attributes"]?.cod);

  const peopleItem = people["@attributes"]?.cod;

  const getFirstValueAfterHash = (data) => {
    const parts = data.split("#");
    return parts[1] || ""; // Obtén el primer valor después del primer '#'
  };

  // useEffect(() => {
  //   const firstValues = people?.hab
  //     ?.flatMap((hab) =>
  //       hab?.reg?.flatMap((reg) => {
  //         const data = reg?.lin[0]; // Accede a la primera posición de lin
  //         if (data) {
  //           return getFirstValueAfterHash(data);
  //         }
  //         return null;
  //       })
  //     )
  //     .filter(Boolean); // Filtra valores nulos

  //   setFirstValueFromLin(firstValues[0] || ""); // Guarda solo el primer valor
  // }, [people]);

  const [bestOfferHab, setBestOfferHab] = useState(false);




  return (
    <>
      <div className="contain-hab-detaill">
        <div className="contain-hab-detail-item">
          <section className="contain-all-habb">
            <div
              className="card-hab-detail"
              onClick={() => {
                joinReserve(Hab);
                handleScroll();
                selectedHabFunction(Hab);
              }}
            >
              <div
                className={`content-info-hab ${selectedItem === Hab ? "active" : ""
                  }`}
              >
                <div className="content-info-hab-icon">
                  {selectedItem === Hab ? (
                    <FaRegCircleDot color={"var(--bluePrimary)"} />
                  ) : (
                    <FaRegCircle color={"var(--bluePrimary)"} />
                  )}
                </div>

                <div className={`content-info-hab-content`}>
                  <h2>{Hab?.name_room?.name}</h2>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      gap: 10,
                    }}
                  >
                    <TextComponent size={"md2"}>
                      <MdBedroomParent size={18} color={"var(--bluePrimary)"} />
                      ({Hab?.room_size + " m2"}) {t("hotels.rooms.size")}
                    </TextComponent>
                    <TextComponent size={"md2"}>
                      <FaPerson size={18} color={"var(--bluePrimary)"} />
                      {"Max personas"}:{" "}
                      {Hab?.max}
                    </TextComponent>
                    <TextComponent size={"md2"}>
                      <FaPerson size={18} color={"var(--bluePrimary)"} />
                      {"Min personas"}:{" "}
                      {Hab?.min}
                    </TextComponent>
                    {
                      Hab?.private_bathroom === 1 &&
                      <TextComponent size={"md2"}>
                        <MdBedroomParent size={18} color={"var(--bluePrimary)"} />
                        {t("hotels.rooms.private")}
                      </TextComponent>
                    }

                    <TextComponent size={"md2"}>
                      <FaCircleCheck size={18} color={"var(--bluePrimary)"} />
                      {t("hotel.days_advance")}:{" "}
                      {Hab?.stayDays}
                    </TextComponent>

                    <TextComponent size={"md2"}>
                      <FaCircleCheck size={18} color={"var(--bluePrimary)"} />
                      {t("hotel.nights_spending")}:{" "}
                      {Hab?.stayDays - 1}
                    </TextComponent>

                    <div>
                      <h4 className="text-[#1e5b98] text-[18px]">Camas Incluye:</h4>
                      <ul>
                        {
                          Hab?.type_beds?.map((bed, index) => (
                            <li key={index}>
                              <TextComponent size={"md2"} >
                                <FaCircleCheck size={18} color={"var(--bluePrimary)"} />
                                {
                                  bed?.name
                                }
                              </TextComponent>
                            </li>

                          ))
                        }
                      </ul>
                    </div>

                  </div>

                  <h2 style={{ fontWeight: "600", marginTop: 5 }}>
                    $
                    {formatearCantidad(
                      Number(Hab?.priceWithDays)
                    )}
                  </h2>
                  <div
                  style={{ display: "flex", flexDirection: "column", gap: 10 }}
                >
                  <span className="what-include" onClick={() => setShow(true)}>
                    {t("hotel.what_include_price")}
                  </span>
                </div>
                </div>
                <ModalChange
                  selectHab={selectHab}
                  show={show}
                  setShow={setShow}
                />

                

              </div>
            </div>

          </section>
        </div>
      </div>

      <ModalContainer
        show={activeChangeService}
        hiddsetActiveChangeServiceen={setActiveChangeService}
      >
        <div className="content-services-modal-hab">
          <TextComponent tag="h3" fontWeight="bold" size={"md"}>
            {t("hotels.rooms.select_comfort")}
          </TextComponent>
          <ul className="list-change-services-hab">
            {selectHab?.reg?.map((tarifa, index) => {
              const isSelected =
                tarifa["@attributes"]?.cod === habOption["@attributes"]?.cod;
              return (
                <li
                  onClick={() => {
                    optionNr(
                      habOption["@attributes"]?.nr,
                      tarifa["@attributes"]?.cod
                    );
                    selectedItemDeleteSelected(tarifa);
                  }}
                  key={index}
                  style={{
                    backgroundColor: isSelected ? "#d3e2ff" : "transparent",
                    fontWeight: isSelected ? "bold" : "normal",
                  }}
                >
                  <FaCircleCheck
                    size={18}
                    color={isSelected ? "#004274" : "#ccc"}
                  />
                  {
                    tarifa["@attributes"]?.cod === "Solo alojamiento" ? t("hotels.results.includes.only_accommodation") : tarifa["@attributes"]?.cod === "Alojamiento y desayuno" ? t("hotels.results.includes.with_breakfast") :
                      tarifa["@attributes"]?.cod
                  }
                </li>
              );
            })}
          </ul>
        </div>
      </ModalContainer>
    </>
  );
};

export default HabDetail;
