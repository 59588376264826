import React from "react";
import Modal from "@/common/components/Modal"; // Importa el modal reutilizable
import { formatMoney } from "../../../utils";
import { LuLoader2 } from "react-icons/lu";
import { useTranslation } from "react-i18next";

const ModalReservePackage = ({ showModal, onClose, packaged, handleReservation, loadingMin, loadingFull }) => {
  const { t } = useTranslation("global");

  return (
    <Modal showModal={showModal} hasCloseButton={true} onClose={onClose}>
      <h2 className="text-xl font-semibold text-center mb-4">{t("payment_hub.select_payment_method")}</h2>

      <div className="space-y-4">
        {/* Botón para reservar con el monto mínimo */}
        {
          packaged?.pack?.min_price_to_pay > 0 && (
            <button
              className="w-full px-4 py-2 text-white bg-customBlue rounded-lg hover:bg-customHoverBlue"
              onClick={() => handleReservation(packaged?.pack?.min_price_to_pay, "min")}
            >
              {loadingMin ? (
                <div className="flex justify-center">
                  <LuLoader2 size={20} className="flex justify-center" />
                </div>
              ) : (
                <div>
                  {t("payment_hub.min_reserve")} ({formatMoney(packaged?.pack?.min_price_to_pay)} {packaged?.pack?.currency})
                </div>
              )}
            </button>
          )
        }
        {/* Botón para reservar con el monto total */}
        <button
          className="w-full px-4 py-2 text-white bg-customBlue rounded-lg hover:bg-customHoverBlue"
          onClick={() => handleReservation(packaged?.pack?.price, "full")}
        >
          {loadingFull ? (
            <div className="flex justify-center">
              <LuLoader2 size={20} className="flex justify-center" />
            </div>
          ) : (
            <div>
              {t("payment_hub.total_reserve")} ({formatMoney(packaged?.pack?.price)} {packaged?.pack?.currency})
            </div>
          )}

        </button>
      </div>
    </Modal>
  );
};

export default ModalReservePackage;
